/* eslint-disable */

import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { FieldProps, getIn } from 'formik';
import IMask from 'imask';

export interface TextFieldProps extends FieldProps, Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {
  mask?: string;
}

export function fieldToTextField({
  disabled,
  field: { onChange: fieldOnChange, onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  onChange,
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  return {
    variant: props.variant,
    disabled: disabled ?? isSubmitting,
    error: !!(getIn(touched, field.name) && getIn(errors, field.name)),
    onChange:
      onChange ??
      function (e) {
        if (props.mask) {
          const maskOptions = {
            mask: props.mask,
          };
          const mask = IMask(e.target, maskOptions);
          e.target.value = mask.value;
        }

        fieldOnChange(e ?? field.name);
      },
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}

export function FormikMaterialTextField({ children, ...props }: TextFieldProps) {
  return <MuiTextField {...fieldToTextField(props)}>{children}</MuiTextField>;
}

FormikMaterialTextField.displayName = 'FormikMaterialUITextField';
