import { colors, Text, RadioVariants } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import { AddressType } from '../../../../redux/slices/quote';
import AutoCompleteAddress from '../../AutoCompleteAddress/AutoCompleteAddress';
import { RadioContainer } from '../styles';
import QuestionHeader from '../../../atoms/QuestionHeader/QuestionHeader';
import FormikRadioBlock from '../../../molecules/FormikRadioBlock/FormikRadioBlock';

type BillingAddressPreferenceProps = {
  isPolicyHolder: boolean;
  showError: boolean | undefined;
  overlayDisplayed: boolean;
  setFieldValue: (field: string, value: boolean | AddressType | string | null, shouldValidate?: boolean) => void;
  initialValues: AddressType;
  addressFieldsVisible: boolean;
};

export default function BillingAddressPreference({
  isPolicyHolder,
  showError,
  overlayDisplayed,
  setFieldValue,
  initialValues,
  addressFieldsVisible,
}: BillingAddressPreferenceProps): React.JSX.Element {
  return (
    <>
      <QuestionHeader title="Is the address for the account the same as the address you gave earlier?" testId="same-address-question" />
      <RadioContainer aria-label="radio-group-answers" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Box onClick={() => setFieldValue('manualPolicyHolderBilling', false)}>
          <FormikRadioBlock
            dataTestId="radioButton-sameAddress-yes"
            variant={RadioVariants.dot}
            name="sameAddress"
            isErrored={showError && !overlayDisplayed}
            value={0}
            checkedColor={colors.oceanBlue}
            tabIndex={overlayDisplayed ? -1 : 0}
            ariaLabel="Yes"
          >
            <Text>Yes</Text>
          </FormikRadioBlock>
        </Box>
        <Box onClick={() => setFieldValue('manualPolicyHolderBilling', true)}>
          <FormikRadioBlock
            dataTestId="radioButton-sameAddress-no"
            variant={RadioVariants.dot}
            name="sameAddress"
            isErrored={showError && !overlayDisplayed}
            value={1}
            checkedColor={colors.oceanBlue}
            tabIndex={overlayDisplayed ? -1 : 0}
            ariaLabel="No"
          >
            <Text>No</Text>
          </FormikRadioBlock>
        </Box>
      </RadioContainer>

      {isPolicyHolder && (
        <AutoCompleteAddress
          name="autocomplete"
          setFieldValue={setFieldValue}
          values={initialValues}
          addressFieldsVisible={addressFieldsVisible}
        />
      )}
    </>
  );
}
