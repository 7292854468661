import styled from 'styled-components';
import { Box, RadioGroup } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const RadioBlockContainer = styled(RadioGroup)<{
  $addMarginBottom?: boolean;
}>`
  margin-bottom: ${({ $addMarginBottom }) => ($addMarginBottom ? '16px' : '0')};
  display: block;

  ${theme.breakpoints.up('md')} {
    text-align: left;
    margin-top: -8px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: ${({ $addMarginBottom }) => ($addMarginBottom ? '24px' : '-8px')};
  }
`;

export const RadioBlockWrapper = styled(Box)<{
  $widthMd?: string;
}>`
  ${theme.breakpoints.down('md')} {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  ${theme.breakpoints.up('md')} {
    display: inline-block;
    width: ${({ $widthMd }) => $widthMd || '25%'};
    padding: 8px 15px;
  }
`;

export const ExplanationTextWrapper = styled(Box)<{
  $addMarginBottom?: boolean;
}>`
  padding: 16px;
  background: #edf1f9;
  border: 1px solid ${colors.mercury};
  border-radius: 6px;
  margin-bottom: ${({ $addMarginBottom }) => ($addMarginBottom ? '16px' : '')};
  text-align: left;

  ${theme.breakpoints.up('md')} {
    margin-bottom: ${({ $addMarginBottom }) => ($addMarginBottom ? '32px' : '')};
  }
`;

export const NextButtonWrapper = styled(Box)`
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${theme.breakpoints.up('md')} {
    align-self: flex-end;
  }
`;
