import { MouseEventHandler } from 'react';
import { Box } from '@mui/material';
import { H3, colors } from '@digitalportal-ui/core';
import OvalButton from '../OvalButton/OvalButton';
import { ItemWrapper, TitleButtonWrapper } from './styles';
import MemberWarning from './MemberWarning/MemberWarning';
import MemberNotice from './MemberNotice/MemberNotice';
import { EntitlementErrorMessages } from '../../../redux/slices/helpers/helpers.types';
import ProductRulesMhqExclusionTypes from '../../../enums/productMhqExclusionTypes';

export type CardListItemProps = {
  title: string;
  clickHandler?: MouseEventHandler<HTMLButtonElement>;
  selected: boolean;
  loading: boolean;
  disabled: boolean;
  entitlementErrorMessages?: EntitlementErrorMessages[];
  productCode: string;
};

function CardListItem({
  title,
  clickHandler,
  selected,
  loading,
  disabled,
  entitlementErrorMessages,
  productCode,
}: CardListItemProps): React.JSX.Element {
  return (
    <ItemWrapper data-testid="card-list-item" $isSelected={selected}>
      <TitleButtonWrapper>
        <H3 className="axa-mask" sx={{ wordBreak: 'break-all' }} bold color={colors.darkGrey}>
          {title}
        </H3>
        {(!entitlementErrorMessages?.some(
          (x) => x.mhqExclusionType === ProductRulesMhqExclusionTypes.noCover && x.productCode === productCode,
        ) ||
          selected) && (
          <OvalButton
            disabled={disabled}
            clickHandler={clickHandler}
            checked={selected}
            loading={loading}
            dataTestId="member-select-entitlement"
          >
            {selected ? 'Remove' : 'Add'}
          </OvalButton>
        )}
      </TitleButtonWrapper>
      {entitlementErrorMessages
        ?.filter(
          (x) => x.mhqExclusionType === ProductRulesMhqExclusionTypes.limitedCover && x.productCode === productCode,
        )
        .map((rule: EntitlementErrorMessages) => (
          <Box mt="16px" key={rule.memberIndex}>
            <MemberNotice dataTestId={rule.memberIndex.toString()} message={rule.message} />
          </Box>
        ))}

      {entitlementErrorMessages
        ?.filter(
          (x) =>
            (x.error || x.mhqExclusionType === ProductRulesMhqExclusionTypes.noCover) && x.productCode === productCode,
        )
        .map((rule: EntitlementErrorMessages) => (
          <Box mt="16px" key={rule.memberIndex}>
            <MemberWarning dataTestId={rule.memberIndex.toString()} message={rule.message} />
          </Box>
        ))}
    </ItemWrapper>
  );
}

export default CardListItem;
