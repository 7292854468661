import axios, { Canceler } from 'axios';
import configData from '../../../config/config.json';
import {
  EntitlementPriceType,
  HospitalOption,
  MajorHealthQuestions,
  Member,
} from '../../../redux/slices/quote/quote.types';

const headers = {
  'Content-Type': 'application/json',
};

export type PriceResponse = {
  monthlyPremium: number;
  annualPremium: number;
  price: number;
  monthlyTax: number;
  annualTax: number;
  totalContractPrice: number;
  members: Member[];
  hospitalNetworkOptions: HospitalOption[];
};

let getPlansCancel: undefined | Canceler;
export async function getPlans(
  data: {
    members: {
      title: string;
      firstname: string;
      lastname: string;
      dateOfBirth: string;
      excess: string;
      entitlements: EntitlementPriceType[];
      postcode: string;
      consultation: string;
      policyHolder: boolean;
      majorHealthQuestions: MajorHealthQuestions;
      email: string;
      phone: string;
      ncdProtection?: boolean;
      preferredName?: string;
    }[];
    coverStartDate: string;
    hospitalNetwork: string;
    leadId: string;
    quoteKey: string;
    quoteNumber: string;
    paymentFrequency: string;
    opportunityId: string;
    draftQuoteLastUpdated: string;
  },
  cancellable?: boolean,
): Promise<PriceResponse> {
  if (cancellable && getPlansCancel) {
    getPlansCancel();
  }
  const response = await axios.post(configData.POSTPRICE_URL, data, {
    headers,
    cancelToken: new axios.CancelToken((cancelTokenExecutor) => {
      getPlansCancel = cancelTokenExecutor;
    }),
  });
  return response.data;
}
