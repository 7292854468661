import ProductRulesRestrictionTypes from '../../../enums/productRulesRestrictionTypes';
import { ProductRules } from '../../../redux/slices/helpers/helpers.types';
import { Member, EntitlementPriceType } from '../../../redux/slices/quote/quote.types';

export const consultationRulesMet = (productRules: ProductRules, member: Member): boolean => {
  const selectedEntitlements = member.entitlements.filter(
    (entitlement: EntitlementPriceType) => entitlement.selected === true,
  );

  if (productRules?.consultations?.restrictions?.type === ProductRulesRestrictionTypes.doesNotContain) {
    return productRules.consultations.restrictions.selectedCoverages.some((productCode) =>
      selectedEntitlements.some((entitlement) => entitlement.productCode === productCode),
    );
  }

  return true;
};
