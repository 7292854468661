import styled from 'styled-components';
import { Box } from '@mui/material';
import { colors } from '@digitalportal-ui/core';

export const MemberNoticeWrapper = styled(Box)`
  background: ${colors.pacificOpaque};
  padding: 16px;
  border: 1px solid ${colors.pacific};
  border-radius: 6px;
`;
