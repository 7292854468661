import { Box } from '@mui/material';
import { colors, theme } from '@digitalportal-ui/core';
import styled from 'styled-components';

export const OptionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;
  margin-top: -16px;
  gap: 16px;

  ${theme.breakpoints.up('lg')} {
    flex-direction: row;
  }

  ${theme.breakpoints.up('md')} {
    padding: 0 32px 32px 32px;
    margin-top: -24px;
    gap: 32px;
  }
`;

export const FlexOption = styled(Box)`
  padding: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center;
  background-color: ${colors.pacificOpaque};
  border-radius: 6px;

  ${theme.breakpoints.up('md')} {
    padding: 32px;
    gap: 16px;
  }

  ${theme.breakpoints.up('lg')} {
    flex: 1 0 0;
    align-items: flex-start;
  }
`;
