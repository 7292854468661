import { setNestedObjectValues, useFormikContext, FormikErrors } from 'formik';
import { useCustomEventListener } from './eventHandler';

const getFieldErrorNames = (formikErrors: FormikErrors<unknown>) => {
  const transformObjectToDotNotation = (obj: any, prefix = '', result: Array<string> = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      const nextKey: string = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

type ErrorFocusOnNextProps = {
  setToTouched: boolean;
};

export const ErrorFocusOnNext = ({ setToTouched }: ErrorFocusOnNextProps) => {
  const { isValid, errors, setTouched, values } = useFormikContext();

  useCustomEventListener('pageNavigationNext', () => {
    if (setToTouched) {
      setTouched(setNestedObjectValues(values, true));
    }
    if (!isValid) {
      const timer = setInterval(() => {
        const fieldErrorNames = getFieldErrorNames(errors);
        if (fieldErrorNames.length > 0) {
          const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          if (element !== null) {
            clearInterval(timer);
          }
        }
      }, 50);
    }
  });

  return null;
};
