import styled from 'styled-components';
import { colors, theme, Button } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export const LeftIcon = styled(ChevronLeftIcon)`
  color: ${colors.oceanBlueDark};
  margin-right: 5px;
`;

export const ContactCardWrapper = styled(Box)`
  margin-bottom: 16px;
  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
  }
`;

export const BackButtonStyled = styled(Button)`
  width: 100%;

  ${theme.breakpoints.up('md')} {
    width: 125px;
  }
  color: ${colors.oceanBlueDark};
`;
