import styled from 'styled-components';
import { Card, Box, CardContent } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const Wrapper = styled(Card)<{ $isincluded: boolean }>`
  margin: 16px auto;
  width: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 0;
  border: 2px solid;
  border-color: transparent;
  background: ${({ $isincluded }) => ($isincluded ? colors.pacificOpaque : colors.white)};
  border-color: ${({ $isincluded }) => $isincluded && colors.oceanBlue300};

  ${theme.breakpoints.up('md')} {
    margin: 0px auto 34px auto;
  }
`;

export const StyledCardContent = styled(CardContent)`
  text-align: left;
  padding: 0 !important;
`;

export const CardHeaderBodyWrapper = styled(Box)`
  padding: 16px;

  ${theme.breakpoints.up('md')} {
    padding: 32px;
  }

  ${theme.breakpoints.up('lg')} {
    padding: 32px;
  }
`;
