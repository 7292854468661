import { EntitlementProductType, MajorHealthQuestions } from '../../redux/slices/helpers/helpers.types';
import { Member, Quote } from '../../redux/slices/quote';

function checkMemberUpdated(member: Member, snapshotMember: Member, quoteUpdated: boolean): boolean {
  let updated = quoteUpdated;
  if (
    member.title !== snapshotMember.title ||
    member.firstname !== snapshotMember.firstname ||
    member.lastname !== snapshotMember.lastname ||
    member.preferredName !== snapshotMember.preferredName ||
    member.dob.day !== snapshotMember.dob.day ||
    member.dob.month !== snapshotMember.dob.month ||
    member.dob.year !== snapshotMember.dob.year ||
    member.addressLine1 !== snapshotMember.addressLine1 ||
    member.addressLine2 !== snapshotMember.addressLine2 ||
    member.city !== snapshotMember.city ||
    member.postcode !== snapshotMember.postcode ||
    member.email !== snapshotMember.email ||
    member.phone !== snapshotMember.phone ||
    member.excess !== snapshotMember.excess ||
    member.consultation !== snapshotMember.consultation ||
    member.ncdProtection !== snapshotMember.ncdProtection
  ) {
    updated = true;
  }
  return updated;
}

function checkMajorHealthQuestionsUpdated(
  majorHealthQuestions: MajorHealthQuestions,
  member: Member,
  snapshotMember: Member,
  quoteUpdated: boolean,
) : boolean {
  let updated = quoteUpdated;

  majorHealthQuestions.questions.forEach((question) => {
    const foundQuestion = member.majorHealthQuestions[question.code];
    const foundQuestionSnapshot = snapshotMember.majorHealthQuestions[question.code];

    if (!foundQuestion || !foundQuestionSnapshot || foundQuestion !== foundQuestionSnapshot) {
      updated = true;
    }
  });

  return updated;
}

function checkEntitlementsUpdated(
  entitlements: EntitlementProductType[],
  member: Member,
  snapshotMember: Member,
  quoteUpdated: boolean,
) : boolean {
  let updated = quoteUpdated;

  entitlements.forEach((entitlement) => {
    const foundEntitlement = member.entitlements.find(
      (entitlementToFind) => entitlementToFind.productCode === entitlement.productCode,
    );
    const foundEntitlementSnapshot = snapshotMember.entitlements.find(
      (entitlementToFind) => entitlementToFind.productCode === entitlement.productCode,
    );

    if (
      !foundEntitlement ||
      !foundEntitlementSnapshot ||
      foundEntitlement.selected !== foundEntitlementSnapshot.selected
    ) {
      updated = true;
    }
  });

  return updated;
}

export default function isQuoteUpdated(quote: Quote, majorHealthQuestions: MajorHealthQuestions, entitlements: EntitlementProductType[], quoteSnapshot?: Quote) : boolean {
  let quoteUpdated = false;
  if (
      quote.coverStartDate !== quoteSnapshot?.coverStartDate ||
      quote.hospitalNetwork !== quoteSnapshot?.hospitalNetwork ||
      quote.members.length !== quoteSnapshot?.members?.length
  ) {
    quoteUpdated = true;
  }
  if (quote.members.length === quoteSnapshot?.members?.length) {
    quote.members.forEach((member, memberIndex) => {
      const snapshotMember = quoteSnapshot.members[memberIndex];
      quoteUpdated = checkMemberUpdated(member, snapshotMember, quoteUpdated);
      quoteUpdated = checkMajorHealthQuestionsUpdated(majorHealthQuestions, member, snapshotMember, quoteUpdated);
      quoteUpdated = checkEntitlementsUpdated(entitlements, member, snapshotMember, quoteUpdated);
    });
  }
  return quoteUpdated;
}
