import * as Yup from 'yup';
import AddressValidation from '../../../modules/validation/addressValidation.json';

const postCodeValidationConfig = AddressValidation.validation.postCode;

export const PostcodeSchema = Yup.object().shape({
  postcode: Yup.string()
    .min(postCodeValidationConfig.minLength.value, postCodeValidationConfig.minLength.customMessage)
    .max(postCodeValidationConfig.maxLength.value, postCodeValidationConfig.maxLength.customMessage)
    .matches(new RegExp(postCodeValidationConfig.pattern.value), {
      message: postCodeValidationConfig.pattern.customMessage,
    }),
});
