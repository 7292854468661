import { Formik, Form, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDOBSchema } from '../../../validationSchema';
import routes from '../../../enums/routes';
import { Member, updateMember } from '../../../redux/slices/quote';
import { TStore } from '../../../redux/store';
import DateFormField from '../../organisms/DateFormField/DateFormField';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { FormWrapper, DOBWrapper, PageNavigationWrapper } from './styles';
import Header from '../../templates/Header/Header';
import { Main } from '../../templates/styles';
import { emitCustomEvent, useCustomEventListener } from '../../../lib/utils/eventHandler';

export default function DateOfBirth(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const coverStartDate = useSelector((state: TStore) => state.quoteState.quote.coverStartDate);
  const initialValues = useSelector((state: TStore) => state.quoteState.quote.members.find((member) => member.policyHolder)) ?? {} as Member;
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);

  function Submitter() {
    const { submitForm } = useFormikContext<unknown>();
    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }
  useCustomEventListener('pageNavigationBack', () => {
    history(routes.yourName, navigateOptions);
  });

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <Formik
          validateOnChange
          initialValues={{ dob: initialValues?.dob }}
          validateOnMount
          enableReinitialize
          onSubmit={(values) => {
            history(routes.address, navigateOptions);
            dispatch(updateMember({ userEditIndex: 0, values }));
          }}
          validationSchema={getDOBSchema(coverStartDate)}
        >
          <Form>
            <FormWrapper display="flex" flexDirection="column" alignItems="center">
              <PageIntroduction
                title={`Hello, ${
                  initialValues?.preferredName ? initialValues?.preferredName : initialValues?.firstname
                }. When were you born?`}
                titleTestId="title"
                subtitle="You need to be between 18 and 75 to take out a plan online, and we base our price on age too."
                subtitleTestId="subtitle"
                applyDecibelClass
              />
              <DOBWrapper
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    emitCustomEvent('pageNavigationNext');
                  }
                }}
              >
                <DateFormField inputFieldName="dob" testId="dob" />
                <PageNavigationWrapper>
                  <PageNavigation wrapperMarginDisabled />
                </PageNavigationWrapper>
              </DOBWrapper>
              <Submitter />
            </FormWrapper>
          </Form>
        </Formik>
      </Main>
    </>
  );
}
