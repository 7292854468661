import styled from 'styled-components';
import { Box, ListItem, ListItemIcon } from '@mui/material';
import MuiCheckIcon from '@mui/icons-material/Check';
import MuiCloseIcon from '@mui/icons-material/Close';
import { theme, colors } from '@digitalportal-ui/core';

export const SortCodeContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${theme.breakpoints.up('md')} {
    width: 333px;
  }

  > {
    &:nth-child(2) {
      margin: 0 8px 0 8px;
    }
  }
`;

export const HeadingContainer = styled(Box)`
  margin-bottom: 24px;
  margin-top: 16px;
  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
    margin-top: 32px;
  }
`;

export const SmallInput = styled(Box)`
  width: 100%;

  ${theme.breakpoints.up('md')} {
    width: 333px;
  }
`;

export const StyledListItem = styled(ListItem)`
  padding: 0;
  margin-top: 16px;

  ${theme.breakpoints.up('md')} {
    margin-top: 32px;
  }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 0;
  margin-right: 7px;
`;

export const CheckIcon = styled(MuiCheckIcon)`
  color: ${colors.oceanBlue};
`;

export const CloseIcon = styled(MuiCloseIcon)`
  color: ${colors.mediumGrey};
`;
