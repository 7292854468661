import styled from 'styled-components';
import { Box } from '@mui/material';
import { colors } from '@digitalportal-ui/core';

export const HeadingContainer = styled(Box)`
  justify-content: space-between;
  display: flex;
`;

export const GrayOutBox = styled(Box)`
  z-index: 2;
  overflow: hidden;
  pointer-events: painted;
  background-color: ${colors.whiteOpaque};
  width: 100%;
  height: 100%;
  position: absolute;
`;
