import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import { useFormikContext } from 'formik';
import { ContactCard, colors } from '@digitalportal-ui/core';
import axios from 'axios';
import { AddressType } from '../../../redux/slices/quote';
import TextInputField, { Label } from '../../molecules/TextInputField/TextInputField';
import Phone from '../../../assets/svg/phone.svg?react';
import {
  Wrapper,
  CloseIcon,
  AutoCompleteTextField,
  Option,
  EnterAddressManuallyLinkWrapper,
  StyledTextAsLink,
  AddressList,
} from './styles';
import { updateApiError } from '../../../redux/slices/helpers/helpers.slice';
import { getAddress } from '../../../lib/utils/services/leadService';
import { TStore } from '../../../redux/store';
import { makePhoneCall } from '../../../lib/utils/phoneCall';
import Pop from './Pop';

export type AutoCompleteAddressProps = {
  setFieldValue: (name: string, value: AddressType | string | null) => void;
  name: string;
  values: AddressType;
  manualMode?: boolean;
  addressFieldsVisible?: boolean;
  label?: string;
};

const PaperComponentHandler = (
  children: React.ReactNode,
  setShowAddressFields: React.Dispatch<React.SetStateAction<boolean>>,
) => (
  <AddressList onClick={() => setShowAddressFields(true)} data-testid="addressList">
    {children}
  </AddressList>
);

export default function AutoCompleteAddress({
  setFieldValue,
  name,
  values,
  manualMode,
  addressFieldsVisible,
  label,
}: AutoCompleteAddressProps): React.JSX.Element {
  const minInput = 5;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<AddressType[]>([]);
  const [loading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [input, setInput] = useState('');
  const [showAddressFields, setShowAddressFields] = useState(false);
  const { validateForm, setStatus, status } = useFormikContext<Partial<AddressType>>();
  const { availability } = useSelector((state: TStore) => state.helpersState.helpers);
  const excludedPostcodes = /^(GY|JE|IM)/i;

  const validatePostcode = (value: string) => {
    if (excludedPostcodes.test(value)) {
      setStatus('invalid');
    } else {
      setStatus('valid');
    }
  };

  const addressLookup = async (inputData: string) => {
    const trimmedInput = inputData.trim();
    if (trimmedInput.length >= minInput && !isSelected) {
      setLoading(true);

      try {
        const addressList = await getAddress(trimmedInput.replace(/ /g, '+'));

        if (Array.isArray(addressList)) {
          setOptions(addressList);
        }

        setLoading(false);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          setLoading(false);
          setOptions([]);
          if (error?.response?.status !== 404 || error?.response?.data?.statusCode === 404) {
            dispatch(
              updateApiError({
                apiError: {
                  hasError: true,
                  message: '',
                },
              }),
            );
          }
        }
      }
    }
  };

  const debounceInput = useDebouncedCallback((value) => {
    addressLookup(value);
  }, 1000);

  useEffect(() => {
    if (
      values.addressLine1 !== '' ||
      values.addressLine2 !== '' ||
      values.city !== '' ||
      values.postcode !== '' ||
      manualMode
    ) {
      setShowAddressFields(true);
    }
  }, [values.addressLine1, values.addressLine2, values.city, values.postcode, manualMode]);

  useEffect(() => {
    if (addressFieldsVisible) {
      setShowAddressFields(true);
    }
  }, [addressFieldsVisible]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <Wrapper>
      {!manualMode && (
        <>
          <Label label={label || 'Address'} name={name} data-testid="textInputField-label" />
          <Autocomplete
            data-testid={`${name}-textfield`}
            open={open}
            inputValue={input}
            loading={loading}
            options={options}
            forcePopupIcon={false}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => option.fullAddress === value.fullAddress}
            getOptionLabel={(option) => option.fullAddress}
            loadingText={input.length >= minInput ? 'Loading...' : `Please enter at least ${minInput} characters`}
            noOptionsText={input.length >= minInput ? 'No options' : `Please enter at least ${minInput} characters`}
            onOpen={() => {
              setOpen(true);
            }}
            onChange={(e, value) => {
              if (e.nativeEvent.type === 'keydown') {
                setIsSelected(true);
                setInput('');
                setFieldValue('fullAddress', value?.fullAddress ?? '');
                setFieldValue('addressLine1', value?.addressLine1 ?? '');
                setFieldValue('addressLine2', value?.addressLine2 ?? '');
                setFieldValue('city', value?.city ?? '');
                setFieldValue('postcode', value?.postcode ?? '');
                setShowAddressFields(true);
                setOpen(false);
                setOptions([]);
                validatePostcode(value?.postcode ?? '');
              }
            }}
            onClose={() => {
              setOpen(false);
              setOptions([]);
            }}
            onInputChange={(e, value: string) => {
              if (e?.nativeEvent?.type === 'input' || e?.nativeEvent?.type === 'keyboard') {
                setInput(value);
                debounceInput(value);
                setFieldValue(name, value);
                setIsSelected(false);
              }
            }}
            PopperComponent={(props) => Pop({ ...props, idName: name })}
            PaperComponent={({ children }) => PaperComponentHandler(children, setShowAddressFields)}
            renderOption={(props: object, option: AddressType) => (
              <Option
                {...props}
                key={option.fullAddress}
                aria-controls={`${name}-dropdown`}
                onClick={() => {
                  setIsSelected(true);
                  setInput('');
                  setFieldValue('fullAddress', option.fullAddress);
                  setFieldValue('addressLine1', option.addressLine1);
                  setFieldValue('addressLine2', option.addressLine2);
                  setFieldValue('city', option.city);
                  setFieldValue('postcode', option.postcode);
                  setOpen(false);
                  setOptions([]);
                  validatePostcode(option.postcode);
                }}
              >
                {option.fullAddress}
              </Option>
            )}
            renderInput={(params) => (
              <AutoCompleteTextField
                {...params}
                value={input}
                placeholder="Start typing your address or postcode"
                name={name}
                data-testid={`textInputField-${name}`}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      <CloseIcon
                        $visibility={input.length >= minInput}
                        fontSize="small"
                        onMouseDown={() => {
                          setIsSelected(false);
                          setInput('');
                        }}
                      />
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <EnterAddressManuallyLinkWrapper $showAddressFields={showAddressFields}>
            <StyledTextAsLink
              semibold
              $disabled={showAddressFields}
              onClick={() => setShowAddressFields(!showAddressFields)}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Enter' && !showAddressFields) {
                  setShowAddressFields(true);
                }
              }}
              data-testid="enterAddressManually-link"
              tabIndex={showAddressFields ? -1 : 0}
            >
              Enter address manually
            </StyledTextAsLink>
          </EnterAddressManuallyLinkWrapper>
        </>
      )}
      {showAddressFields && (
        <>
          <TextInputField testId="textInputField-address-line-1" label="Address line 1" name="addressLine1" />
          <TextInputField
            testId="textInputField-address-line-2"
            label="Address line 2 (optional)"
            name="addressLine2"
          />
          <TextInputField testId="textInputField-city" label="Town or city" name="city" />
          <TextInputField
            InputProps={{ onChange: (e: React.ChangeEvent<HTMLInputElement>) => validatePostcode(e.target.value) }}
            testId="textInputField-postcode"
            label="Postcode"
            name="postcode"
          />
        </>
      )}
      {status === 'error' && (
        <ContactCard
          icon={<Phone />}
          header="Sorry, we can’t cover this address"
          subHeader="We do have other options for you. Please call us and we’ll talk them through"
          buttonText="Call 01892 508 800"
          title="Call us"
          availabilityStatus={availability.workTimeText}
          statusTextColor={availability.workTimeColor}
          avatarBgColor={availability.availabilityStatus ? colors.pacificBlue10 : colors.mercury}
          buttonWidthFitContent
          isButtonDisabled={!availability.availabilityStatus}
          onButtonClick={() => makePhoneCall('01892508800')}
          testId="AddressContactCard"
        />
      )}
    </Wrapper>
  );
}
