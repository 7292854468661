import ProductRulesRestrictionTypes from '../../../enums/productRulesRestrictionTypes';
import { ProductRules } from '../../../redux/slices/helpers/helpers.types';
import { Member, EntitlementPriceType, FieldOption } from '../../../redux/slices/quote/quote.types';

export const availableExcesses = (productRules: ProductRules, member: Member): FieldOption[] => {
  const selectedEntitlements = member.entitlements.filter(
    (entitlement: EntitlementPriceType) => entitlement.selected === true,
  );
  let hasRestrictions = false;
  let excessLimits = member.excessConfig?.options;
  const restrictedOptions = productRules?.excess?.options;

  if (productRules?.excess?.restrictions) {
    const doesNotContain = productRules.excess.restrictions.find(
      (x) => x.type === ProductRulesRestrictionTypes.doesNotContain,
    );
    const containsOne = productRules.excess.restrictions.find(
      (x) => x.type === ProductRulesRestrictionTypes.containsOne,
    );

    if (doesNotContain) {
      hasRestrictions = !doesNotContain.selectedCoverages.some((productCode) =>
        selectedEntitlements.some((entitlement) => entitlement.productCode === productCode),
      );
    }

    if (containsOne && !hasRestrictions) {
      hasRestrictions =
        containsOne.selectedCoverages.some((productCode) =>
          selectedEntitlements.some((entitlement) => entitlement.productCode === productCode),
        ) && selectedEntitlements.length === 1;
    }
  }

  if (hasRestrictions && restrictedOptions) {
    excessLimits = excessLimits.filter(
      (option) =>
        parseInt(option.value, 10) >= restrictedOptions.minimum &&
        parseInt(option.value, 10) <= restrictedOptions.maximum,
    );
  }

  return excessLimits;
};
