import React from 'react';
import { Modal, ContactCard, colors } from '@digitalportal-ui/core';
import { useSelector } from 'react-redux';
import { TStore } from '../../../../../redux/store';
import { makePhoneCall } from '../../../../../lib/utils/phoneCall';
import Phone from '../../../../../assets/svg/phone.svg?react';
import { ErrorModalProps } from '../types';

export default function PostalErrorModal({ open, modalTitle, onClose, quoteNumber, phoneNumber }: ErrorModalProps) {
  const { availability } = useSelector((state: TStore) => state.helpersState.helpers);

  return (
    <Modal variant="info" title={modalTitle} open={open} onClose={onClose} dataTestid="error-modal">
      <ContactCard
        icon={<Phone />}
        header="Something went wrong, it looks like we’re having problems with this service right now."
        subHeader={`If you would like to receive a postal copy of your quote, please call our support team using your quote no. ${quoteNumber} as a reference.`}
        buttonText={`Call ${phoneNumber}`}
        title="Call us"
        bgColor={colors.lightBlue}
        availabilityStatus={availability.workTimeText}
        avatarBgColor={availability.availabilityStatus ? colors.white : colors.mercury}
        buttonWidthFitContent
        isButtonDisabled={!availability.availabilityStatus}
        onButtonClick={() => makePhoneCall(phoneNumber)}
        testId="PostalQuoteContactCard"
      />
    </Modal>
  );
}
