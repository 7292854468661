import * as Yup from 'yup';
import { AddressSchema } from '../../../validationSchema';
import PaymentDetailsValidationConfig from '../../../modules/validation/paymentDetailsValidation.json';

const validationConfig = PaymentDetailsValidationConfig.validation;

export const BillingDetails = Yup.object().shape({
  paymentMethod: Yup.number().when({
    is: () => validationConfig.paymentMethod.required.value,
    then: Yup.number().required(PaymentDetailsValidationConfig.defaultMessages.required),
  }),
  storingDetailsConsent: Yup.boolean().when('paymentMethod', {
    is: (paymentMethod: number) => paymentMethod === 0,
    then: Yup.boolean().not([validationConfig.storingDetailsConsent.fieldValue.value],
      validationConfig.storingDetailsConsent.fieldValue.customMessage),
  }),
  paymentFrequency: Yup.number().when({
    is: () => validationConfig.paymentFrequency.required.value,
    then: Yup.number().required(PaymentDetailsValidationConfig.defaultMessages.required),
  }),
  yourCard: Yup.number().is([validationConfig.yourCard.fieldValue.value ? 1 : 0],
    validationConfig.yourCard.fieldValue.customMessage).when({
    is: () => validationConfig.yourCard.required.value,
    then: Yup.number().required(PaymentDetailsValidationConfig.defaultMessages.required),
  }),
  sameAddress: Yup.number().when('yourCard', {
    is: (yourCard: number) => yourCard === undefined || yourCard === 0,
    then: Yup.number().when({
      is: () => validationConfig.sameAddress.required.value,
      then: Yup.number().required(PaymentDetailsValidationConfig.defaultMessages.required),
    }),
  }),
  fullAddress: AddressSchema.fields.fullAddress,
  addressLine1: Yup.string().when(['manualPolicyHolderBilling'], {
    is: (policyHolderManualBilling: boolean) => policyHolderManualBilling,
    then: AddressSchema.fields.addressLine1,
  }),
  addressLine2: AddressSchema.fields.addressLine2,
  city: Yup.string().when(['manualPolicyHolderBilling'], {
    is: (policyHolderManualBilling: boolean) => policyHolderManualBilling,
    then: AddressSchema.fields.city,
  }),
  postcode: Yup.string().when(['manualPolicyHolderBilling'], {
    is: (policyHolderManualBilling: boolean) => policyHolderManualBilling,
    then: AddressSchema.fields.postcode,
  }),
  agreementOfTerms: Yup.bool().not([validationConfig.agreementOfTerms.fieldValue.value],
    validationConfig.agreementOfTerms.fieldValue.customMessage),
});
