import axios from 'axios';
import configData from '../../../config/config.json';
import { GetMemberIpidType, ValidateBankDetailsType } from '../../../redux/slices/helpers/helpers.types';
import {
  EntitlementPriceType,
  Member,
  Quote,
  QuoteStatus,
  MajorHealthQuestions,
} from '../../../redux/slices/quote/quote.types';
import { UpdateConsentRequest } from './types';

const headers = {
  'Content-Type': 'application/json',
};

export async function getQuote(data: { PostCode: string; DateOfBirth: string; QuoteKey: string }): Promise<Quote> {
  const response = await axios.post(configData.GETQUOTE_URL, data, { headers });
  return response.data;
}

export async function createQuote(data: {
  members: {
    title: string;
    firstname: string;
    lastname: string;
    dateOfBirth: string;
    excess: string;
    entitlements: EntitlementPriceType[];
    postcode: string;
    consultation: string;
    policyHolder: boolean;
    majorHealthQuestions: MajorHealthQuestions;
    ncdProtection?: boolean;
    preferredName?: string;
  }[];
  coverStartDate: string;
  hospitalNetwork: string;
  leadId: string;
  quoteKey: string;
  quoteNumber: string;
  paymentFrequency: string;
  opportunityId: string;
  triggerQuoteEmail: boolean;
}): Promise<{
  quoteKey: string;
  quoteNumber: string;
  coverStartDate: string;
  expirationDate: string;
  opportunityId: string;
  quoteStatus: QuoteStatus;
  members: Partial<Member>[];
}> {
  const response = await axios.post(configData.CREATEQUOTE_URL, data, {
    headers,
  });
  return response.data;
}

export async function validateBankDetails(
  data: ValidateBankDetailsType,
): Promise<{ base64Hash: string; isValid: boolean }> {
  const response = await axios.post(configData.VALIDATEBANKDETAILS_URL, data, {
    headers,
  });
  return response.data;
}

export async function getMemberIpid(
  data: GetMemberIpidType,
): Promise<BlobPart> {
  const response = await axios.post(configData.GETMEMBERIPID_URL, data, {
    responseType: 'blob',
    headers,
  });
  return response.data;
}

export async function updateConsent(
  data: UpdateConsentRequest,
) {
  await axios.post(configData.UPDATECONSENT_URL, data, {
    headers,
  });
}
