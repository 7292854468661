import { Button, theme } from '@digitalportal-ui/core';
import styled from 'styled-components';
import HeroImage from '../../../assets/svg/homeoffice_woman_daughter.svg?react';

export const Hero = styled(HeroImage)`
  height: 164px;
  width: 235px;
  margin-bottom: 16px;

  ${theme.breakpoints.up('md')} {
    height: 320px;
    width: 459px;
    margin-bottom: 128px;
  }
`;

export const StyledButton = styled(Button)`
  ${theme.breakpoints.up('md')} {
    width: 'fit-content';
  }
`;
