import styled from 'styled-components';
import { Box } from '@mui/material';
import MuiCloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { colors } from '@digitalportal-ui/core';

export const ErrorBox = styled(Box)`
  background: ${colors.shyTomato90};
  padding: 8px 16px;
  border: 2px solid ${colors.shyTomato};
  border-radius: 6px;
  width: 100%;
`;

export const CloseIcon = styled(MuiCloseIcon)`
  color: ${colors.darkGrey};
  cursor: pointer;
`;

export const WarningIcon = styled(WarningAmberIcon)`
  color: ${colors.darkGrey};
`;
