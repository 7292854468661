import { Wrapper, BackButtonStyled, ButtonStyled, LeftIcon, RightIcon } from './styles';
import { emitCustomEvent } from '../../../lib/utils/eventHandler';

type PageNavigationProps = {
  isBackButtonDisabled?: boolean;
  isNextButtonDisabled?: boolean;
  isNextButtonHidden?: boolean;
  backButtonClickHandler?: () => void;
  nextButtonClickHandler?: () => void;
  wrapperMarginDisabled?: boolean;
  loading?: boolean;
};

const PageNavigation = ({
  isBackButtonDisabled,
  isNextButtonDisabled,
  isNextButtonHidden,
  backButtonClickHandler,
  nextButtonClickHandler,
  wrapperMarginDisabled,
  loading,
}: PageNavigationProps): React.JSX.Element => (
  <Wrapper $wrapperMarginDisabled={wrapperMarginDisabled} data-testid="page-navigation">
    <BackButtonStyled
      disabled={isBackButtonDisabled || loading}
      onClick={() => {
        if (backButtonClickHandler) {
          backButtonClickHandler();
        } else {
          emitCustomEvent('pageNavigationBack');
        }
      }}
      variant="outlined"
      type="button"
      size="large"
      data-testid="footer-back-button"
    >
      <LeftIcon />
      Back
    </BackButtonStyled>
    {!isNextButtonHidden && (
      <ButtonStyled
        disabled={isNextButtonDisabled || loading}
        color="secondary"
        loading={loading}
        onClick={() => {
          if (nextButtonClickHandler) {
            nextButtonClickHandler();
          } else {
            emitCustomEvent('pageNavigationNext');
          }
        }}
        type="button"
        size="large"
        data-testid="footer-next-button"
      >
        Next
        <RightIcon $disabled={isNextButtonDisabled} />
      </ButtonStyled>
    )}
  </Wrapper>
);

export default PageNavigation;
