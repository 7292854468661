import { Box, Divider } from '@mui/material';
import { Formik, Form, useFormikContext, setNestedObjectValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import GrayOutOverlay from './GrayOutOverlay';
import { PaymentDetailsProps } from './types';
import BillingAddressPreference from './Questions/BillingAddressPreference';
import { ContentSection } from './styles';
import { BillingDetails } from './validationSchema';
import { ErrorFocusOnNext } from '../../../lib/utils/errorFocusOnNext';
import ErrorMessageBox from '../../molecules/ErrorMessageBox/errorMessageBox';
import AgreementsOfTerms from './Questions/AgreementsOfTerms';
import PaymentMethodQuestion from './Questions/PaymentMethodQuestion';
import PaymentFrequencyQuestion, { payOptions } from './Questions/PaymentFrequencyQuestion';
import YourCardQuestion from './Questions/YourCardQuestion';
import PaymentDayQuestion from './Questions/PaymentDayQuestion';
import QuestionHeader from '../../atoms/QuestionHeader/QuestionHeader';
import { TStore } from '../../../redux/store';

const initialFormValues = {
  paymentMethod: undefined,
  storingDetailsConsent: false,
  paymentFrequency: undefined,
  preferredDay: '1st of every month',
  yourCard: undefined,
  sameAddress: undefined,
  emailAddress: '',
  manualPolicyHolderBilling: false,
  agreementOfTerms: false,

  fullAddress: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postcode: '',

  firstname: '',
  lastname: '',
  phone: '',
};

export default function PaymentDetailsForm({
  initialValues,
  quoteNumber,
  address,
  onDateChange,
  onPaymentFrequencyChange,
  onSubmit,
  formCompleted,
}: PaymentDetailsProps): React.JSX.Element {
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [addressFieldsVisible, setAddressFieldsVisible] = useState(false);
  const [paymentMethodErrorClose, setPaymentMethodErrorClose] = useState(false);
  const [paymentFrequencyErrorClose, setPaymentFrequencyErrorClose] = useState(false);
  const [yourCardErrorClose, setYourCardErrorClose] = useState(false);
  const [commPreffErrorClose, setCommPreffErrorClose] = useState(false);
  const phoneNumber = useSelector((state: TStore) => state.helpersState.helpers.salesPhoneNumber);
  const coverStartDate = useSelector((state: TStore) => state.quoteState.quote.coverStartDate);
  const coverStartDateDay = dayjs(coverStartDate).date().toString();

  function Submitter() {
    const { submitForm, isValid, values } = useFormikContext<{
      preferredDay: string;
      paymentFrequency: number;
      yourCard: number;
      sameAddress: number;
      storingDetailsConsent: boolean;
    }>();

    useEffect(() => {
      onDateChange(values.paymentFrequency === 1 ? values.preferredDay : coverStartDateDay);
      formCompleted(isValid);
    }, [values.preferredDay, isValid]);

    useCustomEventListener('pageNavigationNext', () => {
      setPaymentMethodErrorClose(false);
      setPaymentFrequencyErrorClose(false);
      setYourCardErrorClose(false);
      setCommPreffErrorClose(false);
      if (!isValid && (values.yourCard === 1 || values.sameAddress === 1)) {
        setAddressFieldsVisible(true);
      } else {
        submitForm();
      }
    });

    return null;
  }

  function SetOverlay() {
    const { values, setTouched } = useFormikContext<{
      yourCard: number;
      paymentMethod: number;
      paymentFrequency: number;
      storingDetailsConsent: boolean;
    }>();

    useEffect(() => {
      if (values.yourCard === 1) {
        setDisplayOverlay(true);
        setTouched(setNestedObjectValues(values, false));
      } else {
        setDisplayOverlay(false);
      }
    }, [values, setTouched]);

    return null;
  }

  return (
    <Box>
      <Formik
        initialValues={initialFormValues}
        validationSchema={BillingDetails}
        validateOnBlur
        validateOnChange
        validateOnMount
        onSubmit={async (values) => {
          const preferredDay = values.preferredDay.match(/(\d+)/);
          onSubmit({
            billingAddress:
              values.addressLine1 === ''
                ? address
                : {
                    addressLine1: values.addressLine1,
                    addressLine2: values.addressLine2,
                    city: values.city,
                    country: values.city,
                    postcode: values.postcode,
                  },
            paymentFrequency: payOptions[values.paymentFrequency ?? 0].value,
            regularPaymentDay:
              payOptions[values.paymentFrequency ?? 0].value === 'Monthly' && preferredDay
                ? preferredDay[0]
                : coverStartDateDay,
            storingCardConsent:
              values.paymentMethod === 0
                ? {
                    isChecked: values.storingDetailsConsent,
                  }
                : null,
            thirdPartyPayer: values.yourCard === 1,
          });
        }}
      >
        {({ values, touched, errors, setFieldValue }) => (
          <Form>
            <SetOverlay />
            <PaymentMethodQuestion
              key="payment-method-question"
              touched={touched.paymentMethod}
              value={values.paymentMethod}
              error={errors.paymentMethod}
              errorClose={paymentMethodErrorClose}
              setErrorClose={setPaymentMethodErrorClose}
            />
            <PaymentFrequencyQuestion
              key="payment-frequency-question"
              touched={touched.paymentFrequency}
              value={values.paymentFrequency}
              errorClose={paymentFrequencyErrorClose}
              setErrorClose={setPaymentFrequencyErrorClose}
              annualPremium={initialValues?.annualPremium}
              monthlyPremium={initialValues?.monthlyPremium}
              onPaymentFrequencyChange={onPaymentFrequencyChange}
            />
            <Divider />
            <YourCardQuestion
              key="your-card-question"
              touched={touched.yourCard}
              value={values.yourCard}
              errorClose={yourCardErrorClose}
              setErrorClose={setYourCardErrorClose}
              paymentMethod={values.paymentMethod}
              quoteNumber={quoteNumber}
              setFieldValue={setFieldValue}
              phoneNumber={phoneNumber}
            />
            <Divider />
            {GrayOutOverlay(displayOverlay)}
            <ContentSection>
              <BillingAddressPreference
                isPolicyHolder={values.sameAddress === 1}
                showError={errors?.sameAddress !== undefined && touched?.sameAddress}
                overlayDisplayed={displayOverlay}
                setFieldValue={setFieldValue}
                initialValues={{
                  fullAddress: values.fullAddress,
                  addressLine1: values.addressLine1,
                  addressLine2: values.addressLine2,
                  city: values.city,
                  postcode: values.city,
                }}
                addressFieldsVisible={addressFieldsVisible}
              />
              {errors?.sameAddress !== undefined && touched?.sameAddress && !commPreffErrorClose && (
                <Box sx={{ marginBottom: { md: '16px' } }}>
                  <ErrorMessageBox closeError={setCommPreffErrorClose} />
                </Box>
              )}
            </ContentSection>
            {(values.paymentFrequency === 1 || values.paymentFrequency === undefined) && (
              <>
                <Divider />
                <PaymentDayQuestion displayOverlay={displayOverlay} phoneNumber={phoneNumber} />
              </>
            )}
            <Divider />
            <ContentSection>
              <QuestionHeader title="Please read and agree to the following:" testId="agreement-of-terms" />
              <AgreementsOfTerms showError={errors?.agreementOfTerms !== undefined && touched?.agreementOfTerms} />
            </ContentSection>
            <Submitter />
            <ErrorFocusOnNext setToTouched={!displayOverlay} />
          </Form>
        )}
      </Formik>
    </Box>
  );
}
