import styled from 'styled-components';
import { Box, Button, RadioGroup } from '@mui/material';
import { theme, Text } from '@digitalportal-ui/core';

export const GrayOutBox = styled(Box)`
  z-index: 2;
  pointer-events: painted;
  background-color: #ffffff90;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const ContentSection = styled(Box)`
  padding-top: 16px;
  ${theme.breakpoints.up('md')} {
    padding-top: 32px;
    padding-bottom: 16px;
    &:last-child {
      padding-bottom: 0;
    }
  }
`;

export const ConsentMessage = styled(Text)`
  ${theme.breakpoints.up('xs')} {
    margin-bottom: 8px;
  }
  ${theme.breakpoints.up('md')} {
    margin-bottom: 14px;
  }
`;

export const CheckBoxLabel = styled(Text)`
  padding: 9px 0;
`;

export const RadioContainer = styled(RadioGroup)`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

export const InputWrapper = styled(Box)`
  ${theme.breakpoints.up('md')} {
    width: 298px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 258px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 318px;
  }
`;

export const InnerRadioContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DropdownHolder = styled(Box)`
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  ${theme.breakpoints.up('md')} {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 298px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 258px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 318px;
  }
`;

export const ConsentBox = styled(Box)`
  background-color: #ecf1f8;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.33);
`;

export const LinkButton = styled(Button)`
  color: #4976ba;
`;

export const ExclusionTextListItem = styled(Text)`
  padding-left: 16px;
  &:before {
    content: '•';
    width: 18px;
    height: 18px;
    margin-left: -16px;
    display: inline-flex;
    justify-content: center;
  }
  ${theme.breakpoints.up('md')} {
    padding-left: 20px;
    &:before {
      width: 20px;
      height: 20px;
      margin-left: -20px;
    }
  }
`;
