import NetworkCodes from '../../enums/networks';
import { Providers } from '../../redux/slices/helpers/helpers.types';

export function defaultNetworkSelection(providers: Providers) : NetworkCodes.guidedOption | NetworkCodes.foundationOption {
  const guidedNetwork = providers.networks.find(network => network.network === NetworkCodes.guidedOption);
  const foundationNetwork = providers.networks.find(network => network.network === NetworkCodes.foundationOption);
  if (guidedNetwork && foundationNetwork) {
    if (guidedNetwork.hospitalTotalWithinDistance > foundationNetwork.hospitalTotalWithinDistance) {
      return NetworkCodes.guidedOption;
    }
    if (guidedNetwork.hospitalTotalWithinDistance === foundationNetwork.hospitalTotalWithinDistance) {
      const closestGuided = parseFloat(guidedNetwork.hospitals[0].drivingDistance.replace('miles', ''));
      const closestFoundation = parseFloat(foundationNetwork.hospitals[0].drivingDistance.replace('miles', ''));
      return closestGuided < closestFoundation ? NetworkCodes.guidedOption : NetworkCodes.foundationOption;
    }
  }
  return NetworkCodes.foundationOption;
}
