import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Member, updateQuoteMonthlyPremiums, updateMember, updateQuote } from '../redux/slices/quote';
import { getPlans } from '../lib/utils/services/pricingService';
import { getUpdatedEntitlementsWithPrices } from '../components/pages/entitlements/entitlements';
import { updatePastDateWarningModalInfo } from '../redux/slices/helpers';
import { TStore } from '../redux/store';
import { checkCoverStartDate } from '../lib/utils/checkCoverStartDate';
import { appInsights } from '../lib/utils/services/applicationInsightsService';
import HandleError from '../lib/utils/handleError';

export type GetPriceType = {
  partialMember?: Partial<Member>;
  newMemberValues?: Member[];
  selectedHospitalNetwork?: string;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export function useGetPrice() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  dayjs.extend(utc);

  const {
    members: initialMembers,
    coverStartDate,
    opportunityId,
    hospitalNetwork: initialHospitalNetwork,
    quoteKey,
    quoteNumber,
  } = useSelector((state: TStore) => state.quoteState.quote);
  const helpers = useSelector((state: TStore) => state.helpersState.helpers);
  const { initialEntitlements } = helpers;

  const getMemberBody = (initialMember: Member, partialMember?: Partial<Member>) => {
    let member = initialMember;
    if (partialMember) {
      const updateThisMember = partialMember?.id ? initialMember.id === partialMember.id : true;

      if (updateThisMember) {
        member = {
          ...initialMember,
          ...partialMember,
        };
      }
    }

    return {
      id: initialMember.id,
      title: member.title,
      firstname: member.firstname,
      lastname: member.lastname,
      dateOfBirth: `${member.dob.year}-${member.dob.month}-${member.dob.day}`,
      excess: member.excess,
      entitlements: member.entitlements,
      postcode: member.postcode,
      city: member.city,
      addressLine1: member.addressLine1,
      addressLine2: member.addressLine2,
      policyHolder: member.policyHolder,
      majorHealthQuestions: member.majorHealthQuestions,
      consultation: member.consultation,
      ncdProtection: member.ncdProtection === 'Yes',
      preferredName: member.preferredName,
      email: member.email,
      phone: member.phone,
    };
  };

  const getPrice = (payload: Partial<GetPriceType>) => {
    dispatch(updateQuote({ paymentFrequency: 'Monthly' }));

    const { partialMember, newMemberValues, selectedHospitalNetwork, successCallback, errorCallback } = payload;
    setLoading(true);

    let membersBody;

    if (newMemberValues) {
      membersBody = initialMembers.map((initialMember) =>
        getMemberBody(
          initialMember,
          newMemberValues.find((member) => member.id === initialMember.id),
        ),
      );
    } else {
      membersBody = initialMembers.map((initialMember) => getMemberBody(initialMember, partialMember));
    }

    const coverstartDatecheck = checkCoverStartDate(coverStartDate, dispatch);

    getPlans(
      {
        members: membersBody,
        coverStartDate: coverstartDatecheck.coverStartDate,
        hospitalNetwork: selectedHospitalNetwork || initialHospitalNetwork,
        leadId: initialMembers[0].leadId,
        paymentFrequency: 'Monthly',
        opportunityId,
        quoteKey,
        quoteNumber,
        draftQuoteLastUpdated: dayjs.utc().format('YYYY-MM-DD HH:mm:ss'),
      },
      true,
    )
      .then((result) => {
        setLoading(false);
        dispatch(
          updateQuoteMonthlyPremiums({
            price: result.monthlyPremium,
            tax: result.monthlyTax,
            totalContractPrice: result.totalContractPrice,
          }),
        );

        result.members.forEach((member: Member, index: number) => {
          const validEntitlements = getUpdatedEntitlementsWithPrices(
            initialEntitlements,
            member,
            initialMembers[index].entitlements,
          );

          dispatch(
            updateMember({
              userEditIndex: index,
              values: {
                monthlyPremium: member.monthlyPremium,
                annualPremium: member.annualPremium,
                entitlements: validEntitlements,
              },
            }),
          );
        });

        if (coverstartDatecheck.updated) {
          dispatch(updatePastDateWarningModalInfo(true));
        }

        if (successCallback) {
          successCallback();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          HandleError(
            error,
            dispatch,
            'Something went wrong. Please check your postcode and date of birth are correct and try again.',
          );
          setLoading(false);
        } else {
          appInsights.trackTrace({ message: 'pricing request cancelled', severityLevel: SeverityLevel.Information });
        }
        if (errorCallback) {
          errorCallback();
        }
      });
  };

  return { getPrice, loading };
}
