import React from 'react';
import { Box, MenuItem } from '@mui/material';
import { ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import { Label as Sublabel } from '@digitalportal-ui/core';
import { FormikMaterialTextField } from './FormikMaterialTextField';
import { TextInputFieldProps } from './types';
import { Wrapper, InputFieldLabel, StyledField, SelectStyled, ErrorMessageText, InputFieldWrapper } from './styles';
import { updateModalInfo } from '../../../redux/slices/helpers';

export function Label({
  name,
  label,
  sublabel,
}: {
  name?: string;
  label?: React.ReactNode;
  sublabel?: React.ReactNode;
}): React.JSX.Element {
  return (
    <Box marginBottom="8px">
      <InputFieldLabel htmlFor={name}>{label}</InputFieldLabel>
      {sublabel && <Sublabel align="left">{sublabel}</Sublabel>}
    </Box>
  );
}

const TextInputField = React.forwardRef(
  (
    {
      label,
      name,
      placeholder,
      maxLength = 255,
      autoFocus,
      onFocus,
      list,
      type,
      disabled,
      InputProps,
      wrapperMarginDisabled,
      mask,
      testId,
      swapTabIndex,
      sublabel,
      inputMode,
      children,
    }: TextInputFieldProps,
    ref,
  ) => {
    const dispatch = useDispatch();
    return (
      <Wrapper $wrapperMarginDisabled={wrapperMarginDisabled} data-testid={testId}>
        <InputFieldWrapper>
          <Box width="100%" flexDirection="column">
            {label && <Label name={name} label={label} sublabel={sublabel} />}
            <StyledField
              disabled={disabled}
              autoFocus={autoFocus}
              onFocus={onFocus}
              inputRef={ref}
              component={list ? SelectStyled : FormikMaterialTextField}
              onClose={swapTabIndex && (() => dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' })))}
              name={name}
              id={name}
              type={type || 'text'}
              inputProps={{
                ...InputProps,
                maxLength,
                title: ' ',
                inputMode,
                'aria-label': name,
              }}
              placeholder={placeholder}
              mask={mask}
              onMouseDown={swapTabIndex && (() => dispatch(updateModalInfo({ isModalOpen: true, modalTitle: '' })))}
            >
              {placeholder && list && (
                <MenuItem value=" " disabled>
                  {placeholder}
                </MenuItem>
              )}
              {list?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item === '' ? '(none)' : item}
                </MenuItem>
              ))}
            </StyledField>
          </Box>
          {children &&
            <Box alignSelf="flex-end">
              {children}
            </Box>}
        </InputFieldWrapper>
        <ErrorMessage
          name={name}
          render={(message) => (
            <ErrorMessageText mt={1} textAlign="left" data-testid={`error-message-${testId}`}>
              {message}
            </ErrorMessageText>
          )}
        />
      </Wrapper>
    );
  },
);

export default TextInputField;
