import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const CardHeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: centre;
  margin-bottom: 16px;

  ${theme.breakpoints.down('md')} {
    align-items: left;
  }
  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
  }
`;
