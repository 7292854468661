import ProductRulesMhqExclusionTypes from '../../enums/productMhqExclusionTypes';
import ProductRulesRestrictionTypes from '../../enums/productRulesRestrictionTypes';
import { EntitlementErrorMessages, ProductRules, ProductRulesCoverages, ProductRulesCoveragesMhqExclusions } from '../../redux/slices/helpers/helpers.types';
import { Member, EntitlementPriceType, MajorHealthQuestions } from '../../redux/slices/quote/quote.types';

type AddRule = {
  entitlement: EntitlementPriceType,
  rules: EntitlementErrorMessages[],
  memberIndex: number,
};

export type GetEntitlementRulesMet = {
  productRules: ProductRules,
  initialMembers: Member[],
};

export interface AddRestrictionRule extends AddRule {
  coverage: ProductRulesCoverages,
}

export interface HandleRestrictionRule extends AddRule {
  coverage: ProductRulesCoverages,
  entitlements: EntitlementPriceType[],
}

export interface AddMhqExclusioRule extends AddRule {
  exclusion: ProductRulesCoveragesMhqExclusions,
  memberMhqs: MajorHealthQuestions,
}

export const coverageFilterCallback = (productCode: string, entitlements: EntitlementPriceType[]): boolean => (
  entitlements.filter(
    (entitlement: EntitlementPriceType) =>
    entitlement.productCode === productCode && entitlement.selected,
  ).length === 1
);

export function addRestrictionRule({ coverage, entitlement, rules, memberIndex }: AddRestrictionRule): void {
  rules.push({
    memberIndex,
    productCode: coverage?.restrictions?.requiredCoverage,
    message: coverage?.restrictions?.message ?? '',
    error: entitlement.selected,
    mhqExclusionType: '',
  });
}

export function addMhqExclusionRule({ exclusion, memberMhqs, entitlement, rules, memberIndex }: AddMhqExclusioRule): void {
  if (memberMhqs[exclusion.code] === exclusion.answer) {
    rules.push({
      memberIndex,
      productCode: entitlement.productCode,
      message: entitlement.selected ? exclusion?.selectedMessage : exclusion?.defaultMessage,
      error: entitlement.selected && exclusion.type === ProductRulesMhqExclusionTypes.noCover,
      mhqExclusionType: exclusion.type,
    });
  }
}

export function handleRestrictionRule({ coverage, entitlement, entitlements, rules, memberIndex }: HandleRestrictionRule): void {
  if (coverage?.restrictions?.type === ProductRulesRestrictionTypes.contains) {
    const condition = coverage?.restrictions?.selectedCoverages.some((productCode) => (
      coverageFilterCallback(productCode, entitlements)
    ));
    if (condition && coverage.restrictions?.requiredCoverage) {
      if (!coverageFilterCallback(coverage?.restrictions?.requiredCoverage, entitlements)) {
        addRestrictionRule({ coverage, entitlement, rules, memberIndex });
      }
    }
  }

  if (coverage?.restrictions?.type === ProductRulesRestrictionTypes.containsOnly &&
    entitlements.filter((x) => x.selected).length === 1) {
    addRestrictionRule({ coverage, entitlement, rules, memberIndex });
  }
}

export function getEntitlementRulesMet({ productRules, initialMembers }: GetEntitlementRulesMet): EntitlementErrorMessages[] {
  const rules = [] as EntitlementErrorMessages[];

  initialMembers.forEach((member, memberIndex) => {
    const { entitlements } = member;

    entitlements.forEach((entitlement: EntitlementPriceType) => {
      const coverageFound = productRules?.coverages?.find((coverage) => coverage.code === entitlement.productCode);

      if (entitlement.selected && coverageFound) {
        handleRestrictionRule({ coverage: coverageFound, entitlement, entitlements, rules, memberIndex });
      }

      coverageFound?.mhqExclusions.forEach((exclusion) => {
        addMhqExclusionRule({ exclusion, memberMhqs: member.majorHealthQuestions, entitlement, rules, memberIndex });
      });
    });
  });

  return rules;
}
