import axios from 'axios';
import { ProductDefinition, Campaign, Providers } from '../../../redux/slices/helpers/helpers.types';
import configData from '../../../config/config.json';
import { Member } from '../../../redux/slices/quote/quote.types';

const headers = {
  'Content-Type': 'application/json',
};

export type CampaignRequest = {
  marketingRef: string;
  marketingSource: string;
};

interface CampaignResponse extends Campaign {
  phoneNumber: string;
}

export async function getProductDefinition(
  coverStartDate: string,
  productMembers: Member[],
): Promise<ProductDefinition> {
  const productRequest = {
    productMembers: productMembers.map((member) => ({
      id: member.id,
      firstname: member.firstname,
      lastname: member.lastname,
      dateOfBirth: `${member.dob.year}-${member.dob.month}-${member.dob.day}`,
      postcode: member.postcode,
      policyHolder: member.policyHolder,
    })),
    coverStartDate,
  };

  const response = await axios.post(configData.GETENTITLEMENTS_URL, productRequest, {
    headers,
  });
  return response.data;
}

export async function getHospitals(postcode: string): Promise<Providers> {
  const response = await axios.get(configData.GETHOSPITALS_URL.concat('/', postcode), {
    headers,
  });
  return response.data;
}

export async function getCampaign(
  campaignRequest: CampaignRequest,
): Promise<CampaignResponse> {
  const response = await axios.post(configData.GETCAMPAIGN_URL, campaignRequest, {
    headers,
  });
  return response.data;
}
