import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme, colors, Button } from '@digitalportal-ui/core';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const Wrapper = styled(Box)<{ $wrapperMarginDisabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${({ $wrapperMarginDisabled }) => ($wrapperMarginDisabled ? '' : '16px')};

  ${theme.breakpoints.up('md')} {
    margin-top: ${({ $wrapperMarginDisabled }) => ($wrapperMarginDisabled ? '' : '32px')};
  }
`;

export const ButtonStyled = styled(Button)`
  width: fit-content;
  padding: 15px 26px;
  ${theme.breakpoints.up('md')} {
    padding: 15px 30px;
  }
`;

export const BackButtonStyled = styled(ButtonStyled)`
  color: ${colors.oceanBlueDark};
`;

export const LeftIcon = styled(ChevronLeftIcon)`
  color: ${colors.oceanBlueDark};
  margin-right: 5px;
`;

export const RightIcon = styled(ChevronRightIcon)<{ $disabled?: boolean }>`
  color: ${({ $disabled }) => ($disabled ? colors.grey : colors.white)};
  margin-left: 5px;
`;
