import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { theme, colors } from '@digitalportal-ui/core';

export const FieldWrapper = styled(Box)<{ $day?: boolean; $month?: boolean }>`
  width: 100%;
  margin-right: ${({ $day, $month }) => ($day || $month ? '8px' : '0')};
  .MuiInputBase-input {
    text-align: center;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.darkGrey};
      opacity: 1; /* Firefox */
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${colors.darkGrey};
    }
  }

  ${theme.breakpoints.up('md')} {
    margin-right: ${({ $day, $month }) => ($day || $month ? '16px' : '0')};
  }

  ${theme.breakpoints.up('lg')} {
    margin-right: ${({ $day, $month }) => ($day || $month ? '30px' : '0')};
  }
`;

export const DOBTitle = styled(Typography)`
  font-weight: 600;
  margin-bottom: 8px;
`;
