import { Text } from '@digitalportal-ui/core';
import { Checkbox } from '../../../atoms';
import { CheckBoxLabel, ConsentBox, ExclusionTextListItem } from '../styles';
import { AgreementsOfTermsProps } from '../types';

export default function AgreementsOfTerms({ showError }: AgreementsOfTermsProps): React.JSX.Element {
  return (
    <ConsentBox>
      <ExclusionTextListItem>
        I declare that the information I’ve given in this online application for an AXA Health Plan is full, true and correct, to the best of my knowledge.
      </ExclusionTextListItem>
      <ExclusionTextListItem>
        I understand that the AXA Health Plan is arranged by AXA Health Limited and underwritten by AXA PPP Healthcare Limited.
      </ExclusionTextListItem>
      <ExclusionTextListItem>
        I agree that I will follow the membership rules. I’ll read these online, or when I receive them with my membership documents.
      </ExclusionTextListItem>
      <ExclusionTextListItem>
        I’ve read the information about how AXA Health covers existing conditions, and I’ve chosen my plan based on the available information.
      </ExclusionTextListItem>
      <ExclusionTextListItem mb={1}>
        I agree to show this information to anyone else on the membership.
      </ExclusionTextListItem>
      <Text mb={1}>
        I understand that if I don’t take reasonable care and give AXA Health information that is inaccurate, AXA Health may do any or all of the following:
      </Text>
      <ExclusionTextListItem>Cancel my plan.</ExclusionTextListItem>
      <ExclusionTextListItem>
        Declare that my membership is not valid, and treat it as if it had never existed.
      </ExclusionTextListItem>
      <ExclusionTextListItem>Change the terms of my membership.</ExclusionTextListItem>
      <ExclusionTextListItem mb={3}>
        Refuse to deal with any or all of my claim, and reduce the amount of any payment for the claim.
      </ExclusionTextListItem>
      <Checkbox name="agreementOfTerms" testId="agreementOfTerms" isErrored={showError}>
        <CheckBoxLabel semibold>
          I have read and agree to this declaration
        </CheckBoxLabel>
      </Checkbox>
    </ConsentBox>
  );
}
