import { H4, Text } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import { MessageBoxStyled } from '../../templates/styles';

type OnlineOrPostBlockProps = {
  communicationPreference: number;
  email?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  postcode?: string;
}

function OnlineOrPostBlock({
  communicationPreference,
  email,
  addressLine1,
  addressLine2,
  city,
  postcode,
}: OnlineOrPostBlockProps): React.JSX.Element {
  if (communicationPreference === 1) {
    return (
      <>
        <Box sx={{ mb: '16px' }}>
          <H4 bold data-testid="your-email-policy-title">
            We’ll send it to this email and postal address
          </H4>
          <Box my={2}>
            <Text className="axa-mask" data-testid="your-email-policy-email">{email}</Text>
          </Box>
          <Text className="axa-mask" data-testid="your-email-policy-addressLine1">{addressLine1}</Text>
          <Text className="axa-mask" data-testid="your-email-policy-addressLine2">{addressLine2}</Text>
          <Text className="axa-mask" data-testid="your-email-policy-city">{city}</Text>
          <Text className="axa-mask" data-testid="your-email-policy-postcode">{postcode}</Text>
        </Box>
        <MessageBoxStyled>
          <Text semibold display="inline" data-testid="message-box-posted-copy">
            Do you need a posted copy?{' '}
          </Text>
          <Text display="inline">
            We’re keen to cut down how much paper we send, and you don’t need paper copies to access
            private care. You’ll still receive emails about your membership.
          </Text>
        </MessageBoxStyled>
      </>
    );
  }
  return (
    <Box>
      <Box sx={{ mb: { xs: '8px', md: '16px' } }}>
        <H4 bold data-testid="your-email-title">
          We’ll send it to this email address
        </H4>
      </Box>
      <Text className="axa-mask" data-testid="your-email-email">{email}</Text>
    </Box>
  );
}

export default OnlineOrPostBlock;
