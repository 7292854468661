import { Text } from '@digitalportal-ui/core';
import CheckboxVariants from '../../../enums/checkboxVariants';
import { Checkbox } from '../../atoms';
import { CheckboxWrapper } from './styles';

type CheckoutCheckboxProps = {
  name: string;
  displayName: string;
  testId: string;
  value: boolean;
};

function CheckoutCheckbox({ name, displayName, testId, value }: CheckoutCheckboxProps): React.JSX.Element {
  return (
    <CheckboxWrapper key={testId}>
      <Checkbox
        variant={CheckboxVariants.brick}
        name={name}
        testId={testId}
        isChecked={value}
      >
        <Text semibold>
          {displayName}
        </Text>
      </Checkbox>
    </CheckboxWrapper>
  );
}

export default CheckoutCheckbox;
