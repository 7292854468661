import { theme } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import styled from 'styled-components';

export const ConfirmationModalContent = styled(Box)`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  ${theme.breakpoints.down('md')} {
    > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;
