import { H2 } from '@digitalportal-ui/core';
import StyledAccordion from '../../molecules/StyledAccordion/StyledAccordion';
import { Heading, Wrapper, QuestionIcon, StyledText } from './styles';
import FaqContent from '../../../content/faqContent.json';

export default function FrequentlyAskedQuestions(): React.JSX.Element {
  return (
    <Wrapper>
      <Heading>
        <QuestionIcon />
        <H2 align="left" margin-bottom="29px" data-testid="faq-title">
          Frequently asked questions
        </H2>
      </Heading>
      {FaqContent.map((content) => (
        <StyledAccordion key={content.question} title={content.question} expanded={false}>
          {content.answer.map((answer) => (
            <StyledText
              key={answer}
              mb={answer.includes('\n') ? 1 : 0}
              semibold={answer.includes('\b')}
            >
              {answer.replace('\b', '')}
            </StyledText>
          ))}
        </StyledAccordion>
      ))}
    </Wrapper>
  );
}
