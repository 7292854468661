import React from 'react';
import {
  CheckIcon,
  CloseIcon,
  ListItemIconStyled,
  StyledList,
  StyledListItem,
  StyledListItemText,
  ReactMarkdownStyled,
} from './styles';

export type ListWithchecksProps = {
  points: string[];
  pointsIconColor?: string;
  chunkSize?: number;
  useCloseIcon?: boolean;
};

const makeChunks = (array: string[], size: number): string[][] => {
  if (array.length <= size || size === -1) {
    return [array];
  }
  return [array.slice(0, size), ...makeChunks(array.slice(size), size)];
};

export default function ListWithChecks({
  points,
  pointsIconColor = '',
  chunkSize = 4,
  useCloseIcon,
}: ListWithchecksProps): React.JSX.Element {
  return (
    <>
      {makeChunks(points, chunkSize).map((row: string[]) => (
        <StyledList key={row[0]} dense>
          {row.map((value: string) => (
            <StyledListItem key={value} aria-label="Benefit">
              <ListItemIconStyled>
                {useCloseIcon ? (
                  <CloseIcon data-testid={`close-icon-${value}`} fontSize="medium" />
                ) : (
                  <CheckIcon data-testid={`check-icon-${value}`} $color={pointsIconColor} fontSize="medium" />
                )}
              </ListItemIconStyled>
              <StyledListItemText
                primary={
                  <ReactMarkdownStyled
                    components={{
                      p: React.Fragment,
                    }}
                  >
                    {value}
                  </ReactMarkdownStyled>
                }
              />
            </StyledListItem>
          ))}
        </StyledList>
      ))}
    </>
  );
}
