import EveryDayHealthCareModalContent from '../../../content/everyDayHealthCareModalContent.json';
import { GenericEntitlement as HelperGenEntitlement } from '../../../redux/slices/helpers/helpers.types';
import PriceCard from '../../molecules/PriceCard/PriceCard';

const coreCardValues: HelperGenEntitlement = EveryDayHealthCareModalContent;

export default function EverydayHealthcareBenefits(): React.JSX.Element {
  return (
    <PriceCard
      cardData={{
        productCode: 'EveryDayHealthCare',
        name: coreCardValues.title,
        description: coreCardValues.contentText,
        included: coreCardValues.included,
        excluded: [],
        selected: false,
        isButtonHidden: true,
        interactable: false,
        showChildren: true,
        ShowTitles: false,
        BubbleTitle: 'Included for every member',
        chipText: 'Available',
        subHeaderText: coreCardValues.description,
      }}
      loading={false}
      key="EDH1"
      clickHandler={undefined}
      dataTestId="EveryDayHealthCare"
    />
  );
}
