import { Box } from '@mui/material';
import { H4 } from '@digitalportal-ui/core';

type QuestionHeaderProps = {
  title: string;
  testId: string;
}

function QuestionHeader({ title, testId }: QuestionHeaderProps): React.JSX.Element {
  return (
    <Box sx={{ mb: { xs: '8px', md: '16px' } }}>
      <H4 data-testid={testId} bold>
        {title}
      </H4>
    </Box>
  );
}

export default QuestionHeader;
