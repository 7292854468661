import { useNavigate } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import TextInputFields from '../../molecules/TextInputField/TextInputField';
import { FullnameSchema } from '../../../validationSchema';
import { Wrapper, DropdownInputHolder } from './styles';
import { Member, updateMember } from '../../../redux/slices/quote';
import { TStore } from '../../../redux/store';
import routes from '../../../enums/routes';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { TitlesList } from '../../../enums/nameTitles';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import Header from '../../templates/Header/Header';
import { Main } from '../../templates/styles';
import { emitCustomEvent, useCustomEventListener } from '../../../lib/utils/eventHandler';

export default function YourName(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const initialValues = useSelector((state: TStore) => state.quoteState.quote.members.find((member) => member.policyHolder)) ?? {} as Member;
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);

  function Submitter() {
    const { submitForm } = useFormikContext<unknown>();
    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }
  useCustomEventListener('pageNavigationBack', () => {
    history(routes.coverStartDate, navigateOptions);
  });
  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <Wrapper>
          <Formik
            validateOnBlur
            validateOnMount
            initialValues={initialValues}
            onSubmit={(values) => {
              dispatch(
                updateMember({
                  userEditIndex: 0,
                  values: {
                    ...values,
                    firstname: values.firstname.trim(),
                    preferredName: values.preferredName.trim(),
                  },
                }),
              );
              history(routes.dateOfBirth, navigateOptions);
            }}
            validationSchema={FullnameSchema}
          >
            <Form>
              <PageIntroduction title="Thank you. What’s your name?" titleTestId="title" />
              <DropdownInputHolder>
                <TextInputFields
                  placeholder="Select"
                  name="title"
                  label="Title (optional)"
                  list={TitlesList}
                  testId="dropdown-titles"
                />
              </DropdownInputHolder>
              <Box
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    emitCustomEvent('pageNavigationNext');
                  }
                }}
              >
                <TextInputFields label="First name" name="firstname" testId="textInputField-firstname" />
                <TextInputFields label="Last name" name="lastname" testId="textInputField-lastname" />
                <TextInputFields
                  label="What would you like us to call you? (optional)"
                  sublabel="For example, if you’re Elizabeth but want us to call you Liz, write Liz here"
                  name="preferredName"
                  testId="textInputField-preferredName"
                />
              </Box>
              <Submitter />
              <PageNavigation />
            </Form>
          </Formik>
        </Wrapper>
      </Main>
    </>
  );
}
