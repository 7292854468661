import styled from 'styled-components';
import { Card, Box, Grid } from '@mui/material';
import { theme } from '@digitalportal-ui/core';
import { ColorsType } from '../../../redux/slices/helpers/helpers.types';

const cardBgColor = (bgColor: ColorsType) => `background-color: ${bgColor};`;

export const Wrapper = styled(Card)<{ $backgroundColor?: ColorsType}>`
  width: 1;
  padding: 16px;
  width: 100%;
  box-shadow: 0px 2px 2px 0px #33333326;
  border-radius: 3px;
  border-width: 2px;
  border: none;
  ${({ $backgroundColor }) => $backgroundColor && cardBgColor($backgroundColor)}

  ${theme.breakpoints.up('md')} {
    padding: 32px;
  }
`;

export const ImageGrid = styled(Grid)`
  ${theme.breakpoints.down('lg')} {
    display: none;
  }

  svg {
    max-width: 250px;
    max-height: 250px;
  }
`;

export const CardHeading = styled(Box)`
  width: 1;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 15px;
    flex-direction: column-reverse;
    text-align: left;
  }
`;

export const CardBody = styled(Box)<{ $cardBodyMarginBottom?: string; $cardBodyMobileMarginBottom?: string }>`
  width: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => props.$cardBodyMarginBottom || '16px'};

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    margin-bottom: ${(props) => props.$cardBodyMobileMarginBottom || '16px'};
  }
  ${theme.breakpoints.down('lg')} {
    justify-content: flex-start;
  }
`;

export const ModalContent = styled(Box)`
  display: flex;
  gap: 16px;
  flex-direction: column;

  ${theme.breakpoints.up('md')} {
    gap: 32px;
  }
`;
