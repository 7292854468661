import styled from 'styled-components';
import { Box, RadioGroup } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';
import { Link } from 'react-router-dom';

const borderStyle = `2px solid ${colors.shyTomato}`;

export const PageWrapper = styled(Box)`
  margin-bottom: 50px;

  ${theme.breakpoints.up('md')} {
    width: 690px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 930px;
    margin-bottom: 90px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 1110px;
    margin-bottom: 150px;
  }
`;

export const PageIntroductionWrapper = styled(Box)`
  ${theme.breakpoints.up('md')} {
    width: 570px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const RFQBox = styled(Box)<{ $error?: boolean }>`
  position: relative;
  width: 100%;
  padding: ${({ $error }) => ($error ? '14px' : '16px')};
  border-radius: 6px;
  background: ${colors.white};
  box-shadow: 0 2px 2px 0 ${colors.silver};
  border: ${({ $error }) => ($error ? borderStyle : '')};
  scroll-margin-top: 72px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  ${theme.breakpoints.up('md')} {
    padding: ${({ $error }) => ($error ? '30px' : '32px')};
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;

export const RFQBoxQuestionWrapper = styled(Box)`
  margin-bottom: 8px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 16px;
  }
`;

export const RFQBoxInfoTextWrapper = styled(RFQBoxQuestionWrapper)``;

export const RadioBlockContainer = styled(RadioGroup)`
  flex-direction: column;
  justify-content: space-between;
  ${theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

export const RadioBlockWrapper = styled(Box)`
  ${theme.breakpoints.down('md')} {
    width: 100%;
    &:first-child {
      margin-bottom: 16px;
    }
  }

  ${theme.breakpoints.up('md')} {
    display: inline-block;
    width: calc(50% - 15px);
  }
`;

export const BlueBoxContainer = styled(Box)`
  padding: 16px;
  background: ${colors.lightBlue};
  border: 1px solid ${colors.mercury};
  border-radius: 6px;
  text-align: left;
  margin-top: 8px;

  ${theme.breakpoints.up('md')} {
    margin-top: 16px;
  }
`;

export const ExclusionTextWrapper = styled(BlueBoxContainer)`
  margin-top: 16px;

  ${theme.breakpoints.up('md')} {
    margin-top: 32px;
  }
`;

export const CheckboxContainer = styled(Box)`
  ${theme.breakpoints.up('md')} {
    text-align: left;
    margin: 0 -15px -16px;
  }
`;

export const CheckboxContainerQuestionWrapper = styled(Box)`
  border-top: 1px solid ${colors.mercury};
  ${theme.breakpoints.down('md')} {
    margin: 16px -16px 8px;
    padding: 16px 16px 0;
  }
  ${theme.breakpoints.up('md')} {
    margin: 32px -17px 0 -17px;
    padding: 32px 32px 0 32px;
  }
`;

export const CheckboxWrapper = styled(Box)<{ $dynamicWidth?: boolean }>`
  ${theme.breakpoints.down('md')} {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  ${theme.breakpoints.up('md')} {
    display: inline-block;
    width: 50%;
    padding: 15px 16px;
  }

  ${theme.breakpoints.up('lg')} {
    width: ${({ $dynamicWidth }) => ($dynamicWidth ? 'calc(100% / 3)' : '50%')};
  }
`;

export const ModalLink = styled(Link)`
  color: ${colors.oceanBlue300};
`;
