import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const PageIntroductionWrapper = styled(Box)`

  ${theme.breakpoints.up('md')} {
    width: 570px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const DatePickerWrapper = styled(Box)`
  margin: auto;

  ${theme.breakpoints.up('md')} {
    width: 320px;
  }
`;
