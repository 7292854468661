import dayjs from 'dayjs';
import { Dispatch } from 'react';
import { updateQuote } from '../../redux/slices/quote';

type CoverStartDateCheck = {
  updated: boolean,
  coverStartDate: string
}

export function checkCoverStartDate(coverStartDate: string, dispatch: Dispatch<any>) : CoverStartDateCheck {
    if (dayjs(coverStartDate).isBefore(dayjs(), 'day')) {
      const newCoverStartDate = dayjs().format('YYYY-MM-DD');
      dispatch(updateQuote({ coverStartDate: newCoverStartDate }));

      return { updated: true, coverStartDate: newCoverStartDate };
    }

    return { updated: false, coverStartDate };
}
