import styled from 'styled-components';
import { theme, colors, Text } from '@digitalportal-ui/core';
import { Box, InputLabel } from '@mui/material';
import { Field } from 'formik';
import { Select } from './FormikMaterialSelect';

export const Wrapper = styled(Box)<{ $wrapperMarginDisabled?: boolean }>`
  margin-bottom: ${({ $wrapperMarginDisabled }) => ($wrapperMarginDisabled ? '' : '12px')};

  ${theme.breakpoints.up('md')} {
    margin-bottom: ${({ $wrapperMarginDisabled }) => ($wrapperMarginDisabled ? '' : '20px')};
  }
`;

export const InputFieldLabel = styled(InputLabel)`
  display: flex;
  align-items: center;
  white-space: normal;
  text-align: left;
  color: ${colors.darkGrey};
  font-weight: 600;
  ${theme.breakpoints.up('xs')} {
    font-size: 14px;
  }
  ${theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`;

export const StyledField = styled(Field)`
  width: 100%;
  border: none;
  > * {
    flex-direction: row-reverse;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.grey};
  }

  div.MuiInputBase-root {
    border-radius: 6px;
    background-color: ${colors.white};
    input.MuiInputBase-input {
      height: 44px;
      padding: 0 8px;
      line-height: 18px;
      font-size: 14px;
      ${theme.breakpoints.up('md')} {
        font-size: 16px;
        height: 50px;
        padding: 0 16px;
        line-height: 20px;
      }
    }
  }
`;

export const SelectStyled = styled(Select)`
  border-radius: 6px;
  text-align: left;
  background-color: #fff;
  height: 44px;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  ${theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 20px;
    height: 50px;
  }
`;

export const ErrorMessageText = styled(Text)`
  text-align: left;
  color: ${colors.shyTomato};
`;

export const InputFieldWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
