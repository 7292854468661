import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const ItemWrapper = styled(Box)<{ $isSelected: boolean }>`
  padding: 16px;
  border-top: 1px solid #e5e5e5;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: ${({ $isSelected }) => ($isSelected ? colors.pacificOpaque : 'white')};

  ${theme.breakpoints.up('md')} {
    padding: 32px;
  }
`;

export const TitleButtonWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
