import { Text } from '@digitalportal-ui/core';
import { ExclusionTextWrapper } from './styles';
import mhqPageContent from '../../../content/mhqPageContent.json';
import { Member } from '../../../redux/slices/quote/quote.types';

type ExclusionTextProps = {
  questionCode: string;
  members: Member[];
  isFavourable: boolean;
};

export const getFoundExclusionText = ({ questionCode, members, isFavourable }: ExclusionTextProps) =>
  mhqPageContent
    .find((mhqContent) => mhqContent.code === questionCode)
    ?.exclusionText.filter((item) => !!(item.plural === members.length > 1 && item.isFavourable === isFavourable)) ||
  [];
export default function TextExclusionWrapper({
  questionCode,
  members,
  isFavourable,
}: ExclusionTextProps): React.JSX.Element {
  const foundExclusionText = getFoundExclusionText({ questionCode, members, isFavourable });
  return (
    <ExclusionTextWrapper data-testid={`text-exclusion-${questionCode}`}>
      {foundExclusionText[0].text.map((exclusionText) => (
        <Text
          key={exclusionText.replace(' ', '-')}
          style={{
            marginBottom: exclusionText.includes('\n') ? '8px' : '0',
            display: exclusionText.includes('\t') ? 'list-item' : '',
            marginLeft: exclusionText.includes('\t') ? '32px' : '',
          }}
        >
          {exclusionText}
        </Text>
      ))}
    </ExclusionTextWrapper>
  );
}
