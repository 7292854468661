import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const Wrapper = styled(Box)`
  width: 1110px;
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.down('xl')} {
    width: 930px;
  }

  ${theme.breakpoints.down('lg')} {
    width: 690px;
  }

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const DocumentWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;

  ${theme.breakpoints.up('md')} {
    gap: 32px;
    margin-bottom: 32px;
  }
`;
