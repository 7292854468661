import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const InfoModalContent = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > *:not(:last-child) {
    margin-bottom: 32px;
  }

  ${theme.breakpoints.down('md')} {
    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;
