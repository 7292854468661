import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { H3, Text } from '@digitalportal-ui/core';
import { useEffect, useState } from 'react';
import AddMemberCardDetailsCard from '../../atoms/AddMemberDetailsCard/AddMemberDetailsCard';
import routes from '../../../enums/routes';
import { TStore } from '../../../redux/store';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import Footer from '../../templates/Footer/Footer';
import { PageWrapper, PageIntroductionWrapper, AddMemberCardDetailsCardWrapper } from './styles';
import { updateMemberExcess, Member } from '../../../redux/slices/quote';
import { updateHelperIsProgressShown } from '../../../redux/slices/helpers';
import AddMemberDetailsCardExpandForm from '../../atoms/AddMemberDetailsCardExpandForm/AddMemberDetailsCardExpandForm';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { availableExcesses } from './productRules';
import { useGetPrice } from '../../../hooks/useGetPrice';
import { AddProductAddonToDataLayer } from '../../../lib/tagManager/commonFunctions';
import configData from '../../../config/config.json';

const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default function Excess(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { getPrice, loading } = useGetPrice();

  const [memberCardsExpanded, setMemberCardsExpanded] = useState([true]);

  const { members } = useSelector((state: TStore) => state.quoteState.quote);
  const helpers = useSelector((state: TStore) => state.helpersState.helpers);

  const excessValuesEqualForAllMembers =
    members.filter((member) => member.excess === members.find((mem) => mem.policyHolder)?.excess).length ===
    members.length;
  const noConsultationOptionsForEveryMember = members.every(
    (member) => member.consultationConfig?.options?.length === 0,
  );
 useCustomEventListener('pageNavigationBack', () => {
   if (String(configData.FEATURES.RHL) === 'true') {
     history(routes.hospitals, helpers.navigateOptions);
   } else if (noConsultationOptionsForEveryMember) {
     history(routes.entitlements, helpers.navigateOptions);
   } else {
     history(routes.consultations, helpers.navigateOptions);
   }
 });

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: true }));
  }, [dispatch]);

  useEffect(() => {
    setMemberCardsExpanded(Array(members.length).fill(true));
  }, [members?.length]);

   useCustomEventListener('pageNavigationNext', () => {
     if (String(configData.FEATURES.RHL) === 'true') {
       history(routes.ncd, helpers.navigateOptions);
     } else {
       history(routes.hospitals, helpers.navigateOptions);
     }
   });
  const toggleExpandedHandler = (expanded: boolean, index: number) => {
    const newMemberCardsExpanded = [...memberCardsExpanded];
    newMemberCardsExpanded[index] = !expanded;
    setMemberCardsExpanded(newMemberCardsExpanded);
  };

  const formSubmitHandler = (excess: string) => {
    setMemberCardsExpanded(Array(members.length).fill(true));
    let membersUpdatedIndex = '';
    members.forEach((member: Partial<Member>, index: number) => {
      dispatch(
        updateMemberExcess({
          userEditIndex: index,
          excess,
        }),
      );
      membersUpdatedIndex += index;
      if (index !== members.length - 1) {
        membersUpdatedIndex += ',';
      }
    });
    AddProductAddonToDataLayer(`Excess ${excess}`, membersUpdatedIndex);
    getPrice({ partialMember: { excess } });
  };

  const radioClickHandler = (member: Partial<Member>, index: number) => {
    dispatch(
      updateMemberExcess({
        userEditIndex: index,
        excess: member.excess ?? '',
      }),
    );
    if (member.excess) {
      AddProductAddonToDataLayer(`Excess ${member.excess}`, index.toString());
    }
    getPrice({ partialMember: member });
  };

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <PageWrapper display="flex" flexDirection="column" alignItems="center">
          <PageIntroductionWrapper>
            <PageIntroduction
              title="What excess do you want?"
              titleTestId="title"
              subtitle={
                <>
                  <H3 marginBottom="8px">
                    You can choose to pay towards the cost of any claims – this is your excess.
                  </H3>
                  <H3 marginBottom="8px">The higher your excess, the less you pay for your cover.</H3>
                  <H3>You can set an excess for each member separately.</H3>
                </>
              }
              subtitleTestId="subtitle"
              includeWrapper={false}
              modalTitle="When do I pay my excess?"
            >
              <Text marginBottom="8px">
                You can choose to pay towards the cost of any claims – this is your excess. An excess is a good way to
                reduce the cost of your health insurance.
              </Text>
              <Text marginBottom="8px">
                Here’s how it works: if your excess is £100, and your treatment costs £250, we’ll pay £150 and ask you
                to pay £100. If your treatment costs less than £100, we’ll ask you to pay for it yourself.
              </Text>
              <Text marginBottom="8px">
                Once you’ve paid all of your excess, we won’t ask you to pay more until a year from your first treatment
                or test. That means you don’t need to worry about when you renew: even if you renew in the middle of the
                treatment, we’ll still only take one excess for the year, starting from when you first had treatment.
              </Text>
              <Text>
                The higher your excess, the less you pay for your cover – but you’ll need to pay more if you claim.
              </Text>
            </PageIntroduction>
          </PageIntroductionWrapper>

          {members?.map((member, index) => (
            <AddMemberCardDetailsCardWrapper
              key={`${member.firstname + index}`}
              className="add-member-card-details-wrapper"
            >
              <AddMemberCardDetailsCard
                policyHolder={member.policyHolder}
                memberDetailsProps={member}
                expanded={memberCardsExpanded[index]}
                toggleExpanded={(expanded) => {
                  toggleExpandedHandler(expanded, index);
                }}
                statusText={`${formatter.format(+member.excess)} excess`}
                cardContentChildren={
                  <AddMemberDetailsCardExpandForm
                    isMemberPolicyHolder={member.policyHolder}
                    isApplyToAllButtonAvailable={members.length > 1}
                    selectedFieldValue={member.excess}
                    fieldOptions={availableExcesses(helpers.productRules, member)?.map((option) => ({
                      ...option,
                      displayName: formatter.format(+option.value),
                    }))}
                    selectedFieldValuesEqualForAllMembers={excessValuesEqualForAllMembers}
                    formSubmitHandler={formSubmitHandler}
                    radioClickHandler={(excess) => {
                      radioClickHandler({ id: member.id, excess }, index);
                    }}
                    explanationText={
                      member.excess !== '0' ? (
                        <>
                          <Text bold display="inline">
                            {`${formatter.format(+member.excess)} excess`}
                          </Text>
                          <Text>
                            {` We’ll ask you to pay the first ${formatter.format(
                              +member.excess,
                            )} of any claims, and we’ll pay the rest, so long as your claim is fully covered. Once you’ve paid ${formatter.format(
                              +member.excess,
                            )}, 
                             you won’t need to pay more until one year from when you started treatment, even if you renew during that time.`}
                          </Text>
                          <Text marginTop="8px">You pay an excess for each member separately.</Text>
                        </>
                      ) : (
                        <>
                          <Text bold display="inline">
                            No excess
                          </Text>
                          <Text>
                            You won&apos;t need to pay towards the cost of your treatment or tests, so long as your plan
                            covers these in full.
                          </Text>
                        </>
                      )
                    }
                  />
                }
              />
            </AddMemberCardDetailsCardWrapper>
          ))}
          <PageNavigation wrapperMarginDisabled isNextButtonDisabled={loading} />
        </PageWrapper>
        <Footer data-testid="footer" isPrice priceLoading={loading} />
      </Main>
    </>
  );
}
