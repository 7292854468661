import { Text, colors } from '@digitalportal-ui/core';
import { Dispatch, SetStateAction } from 'react';
import { ErrorBox, WarningIcon, CloseIcon } from './styles';

export type ErrorMessageBoxProps = {
    closeError: Dispatch<SetStateAction<boolean>>;
    message?: string;
};

export default function ErrorMessageBox({ closeError, message }: ErrorMessageBoxProps): React.JSX.Element {
    return (
      <ErrorBox display="flex" className="error-box">
        <WarningIcon fontSize="medium" />
        <Text
          paddingLeft="10px"
          paddingRight="10px"
          textAlign="left"
          color={colors.darkGrey}
          data-testid="page-warning-text"
        >
          {message ?? 'Please select one option to continue'}
        </Text>
        <CloseIcon style={{ marginLeft: 'auto' }} fontSize="medium" onClick={() => closeError(true)} />
      </ErrorBox>
    );
}
