import { useNavigate } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Box } from '@mui/material';
import AutoCompleteAddress from '../../organisms/AutoCompleteAddress/AutoCompleteAddress';
import { AddressSchema } from '../../../validationSchema';
import { Member, updateAddress } from '../../../redux/slices/quote';
import { TStore } from '../../../redux/store';
import routes from '../../../enums/routes';
import { FormHolder } from './styles';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import { emitCustomEvent, useCustomEventListener } from '../../../lib/utils/eventHandler';

export default function Address(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const policyHolder = useSelector((state: TStore) => state.quoteState.quote.members.find((member) => member.policyHolder)) ?? {} as Member;
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const initialValues = {
    fullAddress: policyHolder?.fullAddress,
    addressLine1: policyHolder?.addressLine1,
    addressLine2: policyHolder?.addressLine2,
    city: policyHolder?.city,
    postcode: policyHolder?.postcode,
  };
  const [addressFieldsVisible, setAddressFieldsVisible] = useState(false);

  function Submitter() {
    const { submitForm, isValid, status, setStatus } = useFormikContext<unknown>();
    useCustomEventListener('pageNavigationNext', () => {
      if (status === 'valid' || status === undefined) {
        submitForm();
      } else {
        setStatus('error');
      }

      if (!isValid) {
        setAddressFieldsVisible(true);
      }
    });

    return null;
  }
  useCustomEventListener('pageNavigationBack', () => {
    history(routes.dateOfBirth, navigateOptions);
  });

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <Box display="flex" flexDirection="column" alignItems="center">
          <PageIntroduction
            title="Where do you live?"
            titleTestId="title"
            subtitle="We can only cover you if you live in the UK and you are registered with an NHS GP"
            subtitleTestId="subtitle"
          />
          <Formik
            validateOnBlur
            initialValues={initialValues}
            validateOnMount
            validateOnChange
            enableReinitialize
            onSubmit={(values) => {
              dispatch(
                updateAddress({
                  fullAddress: `${values.addressLine1}, ${values.city}, ${values.postcode}`,
                  addressLine1: values.addressLine1,
                  addressLine2: values.addressLine2,
                  city: values.city,
                  postcode: values.postcode,
                }),
              );
              history(routes.contactDetails, navigateOptions);
            }}
            validationSchema={AddressSchema}
          >
            {({ setFieldValue }) => (
              <Form
                style={{ display: 'contents' }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setTimeout(() => {
                      emitCustomEvent('pageNavigationNext');
                    }, 10);
                  }
                }}
              >
                <FormHolder data-testid="formHolder">
                  <AutoCompleteAddress
                    name="autocomplete"
                    setFieldValue={setFieldValue}
                    values={initialValues}
                    addressFieldsVisible={addressFieldsVisible}
                  />
                  <PageNavigation />
                </FormHolder>
                <Submitter />
              </Form>
            )}
          </Formik>
        </Box>
      </Main>
    </>
  );
}
