/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import dayjs from 'dayjs';
import AddressValidation from './modules/validation/addressValidation.json';
import MemberValidation from './modules/validation/memberValidation.json';
import ContactValidation from './modules/validation/contactValidation.json';

const addressLine1ValidationConfig = AddressValidation.validation.addressLine1;
const addressLine2ValidationConfig = AddressValidation.validation.addressLine2;
const cityValidationConfig = AddressValidation.validation.city;
const postCodeValidationConfig = AddressValidation.validation.postCode;

const firstNameValidationConfig = MemberValidation.validation.firstName;
const lastNameValidationConfig = MemberValidation.validation.lastName;
const preferredNameValidationConfig = MemberValidation.validation.preferredName;
const dobDayValidationConfig = MemberValidation.validation.dobDay;
const dobMonthValidationConfig = MemberValidation.validation.dobMonth;
const dobYearValidationConfig = MemberValidation.validation.dobYear;
const dobValidationConfig = MemberValidation.validation.dob;
const coverStartDateValidationConfig = MemberValidation.validation.coverStartDate;

const emailValidationConfig = ContactValidation.validation.email;
const phoneValidationConfig = ContactValidation.validation.phone;

type NameValidation = {
  minLength: {
    value: number;
    customMessage: string;
  };
  maxLength: {
    value: number;
    customMessage: string;
  };
  pattern: {
    value: string;
    customMessage: string;
  }[];
  required: {
    value: boolean;
  };
};

export const emailValidation = Yup.string()
  .email(emailValidationConfig.pattern.customMessage)
  .min(emailValidationConfig.minLength.value, emailValidationConfig.minLength.customMessage)
  .max(emailValidationConfig.maxLength.value, emailValidationConfig.maxLength.customMessage)
  .matches(new RegExp(emailValidationConfig.pattern.value), {
    message: emailValidationConfig.pattern.customMessage,
  })
  .test({
    name: 'Generic email',
    test: (emailValue) => {
      const trimmedEmail = emailValue?.substring(0, emailValue.indexOf('@'));
      const schema = Yup.string().notOneOf(emailValidationConfig.invalidEmails.value);
      return schema.isValidSync(trimmedEmail);
    },
    message: emailValidationConfig.invalidEmails.customMessage,
    exclusive: true,
  });

export const phoneNumberValidation = Yup.string()
  .max(phoneValidationConfig.maxLength.value, phoneValidationConfig.maxLength.customMessage)
  .matches(new RegExp(phoneValidationConfig.pattern.value), {
    message: phoneValidationConfig.pattern.customMessage,
    excludeEmptyString: true,
  })
  .min(phoneValidationConfig.minLength.value, phoneValidationConfig.minLength.customMessage);

export const getNameYupValidation = (nameValidationConfig: NameValidation) => {
  let yupValidation = Yup.string()
    .min(nameValidationConfig.minLength.value, nameValidationConfig.minLength.customMessage)
    .max(nameValidationConfig.maxLength.value, nameValidationConfig.maxLength.customMessage)
    .when({
      is: () => nameValidationConfig.required.value,
      then: Yup.string().required(MemberValidation.defaultMessages.required),
    });

  nameValidationConfig.pattern.forEach((pattern) => {
    yupValidation = yupValidation.matches(new RegExp(pattern.value), {
      message: pattern.customMessage,
    });
  });

  return yupValidation;
};

export const city = Yup.string()
  .min(cityValidationConfig.minLength.value, cityValidationConfig.minLength.customMessage)
  .max(cityValidationConfig.maxLength.value, cityValidationConfig.maxLength.customMessage)
  .matches(new RegExp(cityValidationConfig.pattern.value), {
    message: cityValidationConfig.pattern.customMessage,
  })
  .when({
    is: () => cityValidationConfig.required.value,
    then: Yup.string().required(MemberValidation.defaultMessages.required),
  });

export const postcode = Yup.string()
  .min(postCodeValidationConfig.minLength.value, postCodeValidationConfig.minLength.customMessage)
  .max(postCodeValidationConfig.maxLength.value, postCodeValidationConfig.maxLength.customMessage)
  .matches(new RegExp(postCodeValidationConfig.pattern.value), {
    message: postCodeValidationConfig.pattern.customMessage,
  })
  .when({
    is: () => postCodeValidationConfig.required.value,
    then: Yup.string().required(MemberValidation.defaultMessages.required),
  })
  .test('Should not allow British forces postcodes',
    'Sorry we do not cover British Forces locations',
    (value) => !/^BF1/i.test(value || ''),
  );

const validationRules = {
  dob: Yup.object({
    day: Yup.string()
      .matches(new RegExp(dobDayValidationConfig.pattern.value), {
        message: dobDayValidationConfig.pattern.customMessage,
      })
      .when({
        is: () => dobDayValidationConfig.required.value,
        then: Yup.string().required(MemberValidation.defaultMessages.required),
      }),
    month: Yup.string()
      .matches(new RegExp(dobMonthValidationConfig.pattern.value), {
        message: dobMonthValidationConfig.pattern.customMessage,
      })
      .when({
        is: () => dobMonthValidationConfig.required.value,
        then: Yup.string().required(MemberValidation.defaultMessages.required),
      }),
    year: Yup.string()
      .length(dobYearValidationConfig.length.value, dobYearValidationConfig.length.customMessage)
      .matches(new RegExp(dobYearValidationConfig.pattern.value), {
        message: dobYearValidationConfig.pattern.customMessage,
      })
      .when({
        is: () => dobYearValidationConfig.required.value,
        then: Yup.string().required(MemberValidation.defaultMessages.required),
      }),
  })
    .test('DOB Validity', 'Please enter a valid date of birth', (values) => {
      const day = parseInt(values.day || '0', 10);

      if (!day) {
        return false;
      }
      if (day > 32) {
        return false;
      }
      return day <= dayjs(`${values.year}-${values.month}-01`).daysInMonth();
    })
    .test({
      name: 'Min Age',
      test: (values) =>
        dayjs().diff(dayjs(`${values.year}-${values.month}-${values.day}`), 'years', true) >=
        dobValidationConfig.minAgePolicyHolder.value,
      message: dobValidationConfig.minAgePolicyHolder.customMessage,
      exclusive: true,
    })
    .test({
      name: 'Max Age',
      test: (values) =>
        dayjs().diff(dayjs(`${values.year}-${values.month}-${values.day}`), 'years', true) <=
        dobValidationConfig.maxAge.value,
      message: dobValidationConfig.maxAge.customMessage,
      exclusive: true,
    })
    .when({ is: () => dobValidationConfig.required.value, then: Yup.object().required() }),
  fullAddress: Yup.string().ensure().max(255, 'Full address must be less than 255 characters long'),
  addressLine1: Yup.string()
    .min(addressLine1ValidationConfig.minLength.value, addressLine1ValidationConfig.minLength.customMessage)
    .max(addressLine1ValidationConfig.maxLength.value, addressLine1ValidationConfig.maxLength.customMessage)
    .matches(new RegExp(addressLine1ValidationConfig.pattern.value), {
      message: addressLine1ValidationConfig.pattern.customMessage,
    })
    .when({
      is: () => addressLine1ValidationConfig.required.value,
      then: Yup.string().required(AddressValidation.defaultMessages.required),
    }),
  addressLine2: Yup.string()
    .min(addressLine2ValidationConfig.minLength.value, addressLine2ValidationConfig.minLength.customMessage)
    .max(addressLine2ValidationConfig.maxLength.value, addressLine2ValidationConfig.maxLength.customMessage)
    .matches(new RegExp(addressLine2ValidationConfig.pattern.value), {
      message: addressLine2ValidationConfig.pattern.customMessage,
    })
    .when({
      is: () => addressLine2ValidationConfig.required.value,
      then: Yup.string().required(AddressValidation.defaultMessages.required),
    }),
  email: emailValidation.required('You need to fill this in'),
  phone: phoneNumberValidation.required('You need to fill this in'),
};

export const FullnameSchema = Yup.object().shape({
  firstname: getNameYupValidation(firstNameValidationConfig),
  lastname: getNameYupValidation(lastNameValidationConfig),
  preferredName: getNameYupValidation(preferredNameValidationConfig),
});

export const getDOBSchema = (coverStartDate: string) =>
  Yup.object().shape({
    dob: validationRules.dob
      .test({
        name: 'Min Age',
        test: (values) =>
          dayjs(coverStartDate).diff(dayjs(`${values.year}-${values.month}-${values.day}`), 'years', true) >=
          dobValidationConfig.minAgePolicyHolder.value,
        message: dobValidationConfig.minAgePolicyHolder.customMessage,
      })
      .test({
        name: 'Max Age',
        test: (values) =>
          dayjs(coverStartDate).diff(dayjs(`${values.year}-${values.month}-${values.day}`), 'years', true) <=
          dobValidationConfig.maxAge.value,
        message: dobValidationConfig.maxAge.customMessage,
      }),
  });

export const AddressSchema = Yup.object().shape({
  fullAddress: validationRules.fullAddress,
  addressLine1: validationRules.addressLine1,

  addressLine2: validationRules.addressLine2,
  city,
  postcode,
});

export const ContactSchema = Yup.object().shape({
  email: validationRules.email,
  phone: validationRules.phone,
});

export const getEditMemberSchema = (coverStartDate: string) =>
  Yup.object().shape({
    firstname: getNameYupValidation(firstNameValidationConfig),
    lastname: getNameYupValidation(lastNameValidationConfig),
    preferredName: getNameYupValidation(preferredNameValidationConfig),
    dob: validationRules.dob
      .test({
        name: 'Min Age',
        test: (values) =>
          dayjs(coverStartDate).diff(dayjs(`${values.year}-${values.month}-${values.day}`), 'years', true) >=
          dobValidationConfig.minAgePolicyHolder.value,
        message: dobValidationConfig.minAgePolicyHolder.customMessage,
      })
      .test({
        name: 'Max Age',
        test: (values) =>
          dayjs(coverStartDate).diff(dayjs(`${values.year}-${values.month}-${values.day}`), 'years', true) <=
          dobValidationConfig.maxAge.value,
        message: dobValidationConfig.maxAge.customMessage,
      }),
    addressLine1: validationRules.addressLine1,
    addressLine2: validationRules.addressLine2,
    city,
    postcode,
    email: validationRules.email,
    phone: validationRules.phone,
  });

export const AddMemberSchema = Yup.object().shape({
  firstname: getNameYupValidation(firstNameValidationConfig),
  lastname: getNameYupValidation(lastNameValidationConfig),
  preferredName: getNameYupValidation(preferredNameValidationConfig),
  dob: validationRules.dob
    .test({
      name: 'Min Age',
      test: (values) =>
        dayjs().diff(dayjs(`${values.year}-${values.month}-${values.day}`), 'years', true) >=
        dobValidationConfig.minAgeDependant.value,
      message: dobValidationConfig.minAgeDependant.customMessage,
    })
    .test({
      name: 'Max Age',
      test: (values) =>
        dayjs().diff(dayjs(`${values.year}-${values.month}-${values.day}`), 'years', true) <=
        dobValidationConfig.maxAge.value,
      message: dobValidationConfig.maxAge.customMessage,
    }),
  addressLine1: validationRules.addressLine1,
  addressLine2: validationRules.addressLine2,
  city,
  postcode,
  email: emailValidation,
});

export const RetrieveQuoteSchema = Yup.object().shape({
  postcode,
  dob: validationRules.dob,
});

export const CoverStartDateSchema = Yup.object().shape({
  coverStartDate: Yup.string()
    .when({
      is: () => coverStartDateValidationConfig.required.value,
      then: Yup.string().required(MemberValidation.defaultMessages.required),
    })
    .test('Invalid Date', 'Invalid date', (value) =>
      dayjs(value, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD'], true).isValid(),
    )
    .test(
      'Min Date',
      coverStartDateValidationConfig.minDateDiff.customMessage,
      (value) =>
        Math.trunc(dayjs().diff(dayjs(value), 'days', true)) <= coverStartDateValidationConfig.minDateDiff.value,
    )
    .test(
      'Max Date',
      `${coverStartDateValidationConfig.maxDateDiff.customMessage} ${dayjs()
        .add(coverStartDateValidationConfig.maxDateDiff.value, 'day')
        .format('DD/MM/YYYY')}.`,
      (value) => dayjs().diff(dayjs(value), 'days', true) >= -coverStartDateValidationConfig.maxDateDiff.value,
    ),
});
