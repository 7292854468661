import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const BuyMembersWrapper = styled(Box)`
  ${theme.breakpoints.up('md')} {
    width: 690px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 930px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 1110px;
  }
`;

export const AddMemberDetailsCardWrapper = styled(Box)`
  width: 100%;
  margin-bottom: 16px;

  ${[theme.breakpoints.up('md')]} {
    margin-bottom: 32px;
  }
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${[theme.breakpoints.up('lg')]} {
    flex-direction: row;
  }
  ${[theme.breakpoints.up('md')]} {
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const FrictionModalContent = styled(Box)`
  width: 258px;

  p {
    margin-bottom: 8px;
  }

  h5:not(:last-child) {
    margin-bottom: 8px;
  }

  ${theme.breakpoints.up('md')} {
    width: 506px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 546px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 602px;
  }
`;

export const EditButtonBox = styled(Box)<{
  $policyHolder?: boolean;
}>`
  width: 100px;
  ${[theme.breakpoints.down('md')]} {
    margin-top: 16px;
    width: 100%;
  }
  ${[theme.breakpoints.down('lg')]} {
    margin-bottom: ${({ $policyHolder }) => ($policyHolder ? '' : '16px')};
  }

  ${[theme.breakpoints.up('lg')]} {
    margin-right: ${({ $policyHolder }) => ($policyHolder ? '' : '24px')};
  }
`;

export const RemoveButtonBox = styled(Box)`
  ${[theme.breakpoints.down('md')]} {
    width: 100%;
  }
  ${[theme.breakpoints.up('md')]} {
    width: 100px;
  }
`;
