import { theme, colors } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled(Box)<{ $isCreatingQuote: boolean }>`
  width: ${({ $isCreatingQuote }) => ($isCreatingQuote ? '100%' : 'initial')};
`;

export const PageIntroductionWrapper = styled(Box)`
  ${theme.breakpoints.up('md')} {
    width: 570px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const FormWrapper = styled(Box)`
  width: 100%;
  margin: 0 auto;
  ${theme.breakpoints.up('md')} {
    width: 570px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 540px;
  }
`;

export const PostcodeWrapper = styled(Box)`
  margin-bottom: 16px;

  .MuiInputBase-input {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.darkGrey};
      opacity: 1; /* Firefox */
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${colors.darkGrey};
    }
  }

  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
  }
`;

export const ButtonWrapper = styled(Box)`
  margin-bottom: 16px;
  margin-top: 4px;

  ${theme.breakpoints.up('md')} {
    margin-top: 12px;
    margin-bottom: 32px;
  }
`;

export const ContactCardWrapper = styled(Box)`
  width: 100%;
  margin: 0 auto;
  ${theme.breakpoints.up('md')} {
    width: 570px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 540px;
  }
`;
