import axios, { Canceler } from 'axios';
import configData from '../../../config/config.json';
import { AddressType } from '../../../redux/slices/quote/quote.types';
import { CreateLeadRequest } from './types';

const headers = {
  'Content-Type': 'application/json',
};

let getPlansCancel: undefined | Canceler;

export async function createLead(data: CreateLeadRequest): Promise<{ id: string }> {
  const response = await axios.post(configData.CREATELEAD_URL, data, { headers });
  return response.data;
}

export async function getAddress(data: string): Promise<undefined | AddressType[]> {
  if (getPlansCancel) {
    getPlansCancel();
  }
  const encodedData = encodeURIComponent(data);
  const response = await axios.get(`${configData.GETADDRESS_URL}/${encodedData}`, {
    headers,
    cancelToken: new axios.CancelToken((cancelTokenExecutor) => {
      getPlansCancel = cancelTokenExecutor;
    }),
  });

  return response?.data;
}
