import styled from 'styled-components';
import { Typography, AccordionSummary, AccordionDetails, Icon, Accordion } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const Wrapper = styled.div`
  ${theme.breakpoints.up('md')} {
    width: 690px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 930px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 1110px;
  }
`;

export const SummaryHeader = styled(AccordionSummary)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${colors.alabaster};
  padding-left: 32px;
  border-top: 1px solid ${colors.mercury};

  ${theme.breakpoints.down('md')} {
    padding-left: 16px;

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
`;

export const SubTitle = styled(Typography)`
  font-size: 16px;
  line-height: 20px;
  text-align: left;
`;

export const StyledAccordion = styled(Accordion)`
  padding: 0;
  border-bottom: 0;
  border-top: 0;
  border-radius: 0;
  border: none;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`;

export const ExpandMoreButton = styled(Icon)<{
  $expanded?: boolean;
}>`
  ${theme.breakpoints.up('xs')} {
    width: 18px;
  }
  ${theme.breakpoints.up('md')} {
    width: 42px;
  }
`;
