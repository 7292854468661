import { Box } from '@mui/material';
import { Text } from '@digitalportal-ui/core';
import { ReactNode } from 'react';

export type EntitlementBlockProps = {
  testId: string;
  child: ReactNode;
  text: string;
};

function EntitlementBlock({ testId, child, text }: EntitlementBlockProps): React.JSX.Element {
  return (
    <Box
      data-testid={testId}
      sx={{
        marginBottom: { xs: '16px', md: '24px' },
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {child}
      <Text semibold marginLeft="8px" alignSelf="center">{text}</Text>
    </Box>
  );
}

export default EntitlementBlock;
