import PriceCard from '../../molecules/PriceCard/PriceCard';
import FlexOptions from '../../molecules/FlexOptions/FlexOptions';

const flexOptions = [
  '1. Choose how many specialist consultations you want',
  '2. Try different excess levels',
  '3. Choose whether we source your specialist, or you choose your own',
];

export default function FlexYourOptions(): React.JSX.Element {
  return (
    <PriceCard
      cardData={{
        productCode: '',
        name: 'Flex your options',
        description: 'Have a play to match your needs, and see how the price changes.',
        included: [],
        excluded: [],
        selected: false,
        isButtonHidden: true,
        interactable: false,
        showChildren: true,
        ShowTitles: false,
      }}
      loading={false}
      key="FYO"
      clickHandler={undefined}
      dataTestId="flex-your-options"
      disableModalLink
    >
      <FlexOptions options={flexOptions} />
    </PriceCard>
  );
}
