import { FormikErrors, useFormikContext } from 'formik';
import { EntitlementErrorMessages } from '../../../redux/slices/helpers/helpers.types';
import { Member, Quote } from '../../../redux/slices/quote';
import CardListItem from '../../atoms/CardListItem/CardListItem';

type MultiMemberCardListItemsProps = {
  productCode: string;
  initialMembers: Member[];
  entitlementRulesMet: EntitlementErrorMessages[];
  cardClickHandler: (members: Member[], clickedMemberIndex: number, productCode: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<{
    initialMembers: Member[];
    loading: boolean;
    quote: Quote;
}>>) => void
};

export default function MultiMemberCardListItems({
  productCode,
  initialMembers,
  entitlementRulesMet,
  cardClickHandler,
}: MultiMemberCardListItemsProps) : React.JSX.Element {
  const { setFieldValue } = useFormikContext<{
    initialMembers: [];
    loading: boolean;
    quote: Quote;
  }>();
  return (
    <>
      {initialMembers.map((member, clickedMemberIndex) => {
      let isSelectedForMember = false;
      let isEntitlementLoadingForMember = false;

      if (member.entitlements.length > 0) {
        isEntitlementLoadingForMember = member.entitlements.some(
          (entitlement) =>
            entitlement.productCode === productCode && entitlement.loading,
        );

        isSelectedForMember = member.entitlements.some(
          (entitlement) =>
            entitlement.productCode === productCode && entitlement.selected,
        );
      }

      return (
        <CardListItem
          key={`${member.firstname}${member.lastname}${member.postcode}`}
          title={`${member.firstname} ${member.lastname}`}
          loading={isEntitlementLoadingForMember}
          selected={isSelectedForMember}
          disabled={false}
          entitlementErrorMessages={entitlementRulesMet.filter(
            (x) => x.memberIndex === clickedMemberIndex,
          )}
          productCode={productCode}
          clickHandler={() => cardClickHandler(initialMembers, clickedMemberIndex, productCode, setFieldValue)}
        />
      );
      })}
    </>
  );
}
