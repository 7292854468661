import styled from 'styled-components';
import { Box } from '@mui/material';
import { Button, colors, theme } from '@digitalportal-ui/core';
import { Link } from 'react-router-dom';

export const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${colors.teal};
  border-radius: 6px;

  ${theme.breakpoints.up('md')} {
    padding: 32px;
  }
`;

export const StyledButton = styled(Button)`
  width: fit-content;
  color: ${colors.white};
  background-color: ${colors.teal};
  border-color: ${colors.white};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.teal};
    border-color: ${colors.white};
  }
`;

export const LinkWrapper = styled(Link)`
  text-decoration: none;
`;
