import React from 'react';
import { Button } from '@digitalportal-ui/core';
import { useDispatch } from 'react-redux';
import { ButtonWrapper, EditButtonBox, RemoveButtonBox } from '../../pages/additionalMembers/styles';
import { updateModalInfo } from '../../../redux/slices/helpers';

export type AddMemberCardActionsProps = {
  memberIndex: number;
  policyHolder: boolean;
  setUserIndex: React.Dispatch<React.SetStateAction<number>>;
  setUserAction: React.Dispatch<React.SetStateAction<string>>;
  editMemberModalOpen: boolean;
  setEditMemberModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  removeMemberModalOpen: boolean;
  setRemoveMemberModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  frictionModalOpen: boolean;
  removeMemberModalTitle: string;
};

const AddMemberCardActions = (props: AddMemberCardActionsProps) => {
  const {
    memberIndex,
    policyHolder,
    setUserIndex,
    setUserAction,
    editMemberModalOpen,
    setEditMemberModalOpen,
    removeMemberModalOpen,
    setRemoveMemberModalOpen,
    frictionModalOpen,
    removeMemberModalTitle,
  } = props;

  const dispatch = useDispatch();

  return (
    <ButtonWrapper data-testid="buttonContainer">
      <EditButtonBox data-testid="buyaddMember-edit" $policyHolder={policyHolder}>
        <Button
          onClick={() => {
            setUserAction('edit');
            setEditMemberModalOpen(true);
            setUserIndex(memberIndex);
            dispatch(updateModalInfo({ isModalOpen: true, modalTitle: "Edit member's details" }));
          }}
          variant="outlined"
          size="small"
          fullWidth
          data-testid="editButton"
          tabIndex={removeMemberModalOpen || editMemberModalOpen || frictionModalOpen ? -1 : 0}
        >
          Edit
        </Button>
      </EditButtonBox>
      {!policyHolder && (
        <RemoveButtonBox data-testid="buyaddMember-remove">
          <Button
            onClick={() => {
              setRemoveMemberModalOpen(true);
              setUserIndex(memberIndex);
              dispatch(updateModalInfo({ isModalOpen: true, modalTitle: removeMemberModalTitle }));
            }}
            variant="outlined"
            size="small"
            fullWidth
            data-testid="removeButton"
            tabIndex={removeMemberModalOpen || editMemberModalOpen || frictionModalOpen ? -1 : 0}
          >
            Remove
          </Button>
        </RemoveButtonBox>
      )}
    </ButtonWrapper>
  );
};
export default AddMemberCardActions;
