import { Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { H3 } from '@digitalportal-ui/core';
import { Wrapper, SummaryHeader, SubTitle, ExpandMoreButton, StyledAccordionDetails, StyledAccordion } from './styles';

export type AccordionSummaryDetailsProps = {
  title: string;
  subtitle?: string;
  subtitleSelection?: string;
  detailsChildren?: React.ReactNode;
  includeWrapper?: boolean;
  expanded?: boolean;
  ariaControls?: string;
};

const AccordionSummaryDetails = ({
  title,
  subtitle,
  subtitleSelection,
  detailsChildren,
  includeWrapper = true,
  expanded,
  ariaControls,
}: AccordionSummaryDetailsProps): React.JSX.Element => {
  const component = (
    <StyledAccordion data-testid="accordionHeader">
      <SummaryHeader
        expandIcon={
          expanded !== undefined && (
            <ExpandMoreButton aria-label="expand-sub-accordion" aria-expanded={expanded} data-testid="button-expand">
              <ExpandMore />
            </ExpandMoreButton>
          )
        }
        aria-controls={ariaControls}
        id={`#${ariaControls}`}
      >
        <Box alignSelf="flex-start" margin="16px 0" textAlign="left">
          <H3 bold align="left">
            {title}
          </H3>
          <SubTitle data-testid="subtitle-text" display="inline-block">{subtitle}</SubTitle>
          {subtitleSelection && (
            <>
              &nbsp;
              <SubTitle data-testid="subtitle-selection-text" fontWeight="600" display="inline-block">
                {subtitleSelection}
              </SubTitle>
            </>
          )}
        </Box>
      </SummaryHeader>
      <StyledAccordionDetails>{detailsChildren}</StyledAccordionDetails>
    </StyledAccordion>
  );

  return includeWrapper ? <Wrapper>{component}</Wrapper> : component;
};

export default AccordionSummaryDetails;
