import { useState } from 'react';
import { Text, Button, Modal, colors } from '@digitalportal-ui/core';
import { useDispatch } from 'react-redux';
import { MemberWarningWrapper, TextWrapper } from './styles';
import { updateModalInfo } from '../../../../redux/slices/helpers';

export type MemberWarningProps = {
  explanationText?: string;
  enableButton?: boolean;
  dataTestId?: string;
  message: string;
};

const MemberWarning = ({
  explanationText = 'Explanation text Explanation text',
  enableButton,
  dataTestId = '',
  message,
}: MemberWarningProps) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const messageParts = message.split(':', 2);
  const modalTitle = 'Why isn’t this available';

  return (
    <>
      <MemberWarningWrapper className="error-box" display="flex" alignItems="center">
        <TextWrapper>
          {messageParts.length > 1 ? (
            <>
              <Text
                display="inline"
                bold
                color={colors.darkGrey}
                data-testid={`member-warning-bold-text-${dataTestId}`}
              >
                {messageParts[0]}:
              </Text>
              <Text display="inline" color={colors.darkGrey} data-testid={`member-warning-text-${dataTestId}`}>
                {messageParts[1]}
              </Text>
            </>
          ) : (
            <Text display="inline" color={colors.darkGrey} data-testid={`member-warning-text-${dataTestId}`}>
              {message}
            </Text>
          )}
        </TextWrapper>
        {enableButton && (
          <Button
            size="small"
            style={{ marginLeft: 'auto', minWidth: 'auto', whiteSpace: 'nowrap' }}
            type="button"
            variant="outlined"
            data-testid={`member-warning-button-${dataTestId}`}
            onClick={() => {
              setModalOpen(true);
              dispatch(updateModalInfo({ isModalOpen: true, modalTitle }));
            }}
          >
            Why isn’t this available
          </Button>
        )}
      </MemberWarningWrapper>
      {enableButton && modalOpen && (
        <Modal
          variant="info"
          title={modalTitle}
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
            dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
          }}
          dataTestid="modal-member-warning-confirmation"
        >
          <Text color={colors.darkGrey} data-testid={`member-warning-explanation-text-${dataTestId}`}>
            {explanationText}
          </Text>
        </Modal>
      )}
    </>
  );
};

export default MemberWarning;
