import * as Yup from 'yup';
import DirectDebitValidationConfig from '../../../modules/validation/directDebitValidation.json';

const validationConfig = DirectDebitValidationConfig.validation;

const sortCode = Yup.string()
  .length(validationConfig.partialSortCode.length.value, validationConfig.partialSortCode.length.customMessage)
  .when({
    is: () => validationConfig.partialSortCode.required.value,
    then: Yup.string().required(DirectDebitValidationConfig.defaultMessages.required),
  });

export const DirectDebitSchema = Yup.object().shape({
  ukbank: Yup.number()
    .is([validationConfig.ukBank.fieldValue.value ? 1 : 0], validationConfig.ukBank.fieldValue.customMessage)
    .when({
      is: () => validationConfig.ukBank.required.value,
      then: Yup.number().required(DirectDebitValidationConfig.defaultMessages.required),
    }),
  singleperson: Yup.number()
    .is([validationConfig.singlePerson.fieldValue.value ? 1 : 0], validationConfig.singlePerson.fieldValue.customMessage)
    .when({
      is: () => validationConfig.singlePerson.required.value,
      then: Yup.number().required(DirectDebitValidationConfig.defaultMessages.required),
    }),
  happyToProceed: Yup.boolean().not([validationConfig.happyToProceed.fieldValue.value],
    validationConfig.happyToProceed.fieldValue.customMessage),
  cardholdername: Yup.string()
    .matches(new RegExp(validationConfig.cardHolderName.pattern.value), {
      message: validationConfig.cardHolderName.pattern.customMessage,
    })
    .max(validationConfig.cardHolderName.maxLength.value, validationConfig.cardHolderName.maxLength.customMessage)
    .when({
      is: () => validationConfig.cardHolderName.required.value,
      then: Yup.string().required(DirectDebitValidationConfig.defaultMessages.required),
    }),
  sortcode1: sortCode,
  sortcode2: sortCode,
  sortcode3: sortCode,
  bankaccountnumber: Yup.string()
    .matches(new RegExp(validationConfig.accountNumber.pattern.value), {
      message: validationConfig.accountNumber.pattern.customMessage,
    })
    .length(validationConfig.accountNumber.length.value, validationConfig.accountNumber.length.customMessage)
    .when({
      is: () => validationConfig.accountNumber.required.value,
      then: Yup.string().required(DirectDebitValidationConfig.defaultMessages.required),
    }),
});
