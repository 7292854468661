import React, { useState } from 'react';
import { Text, H4, Modal } from '@digitalportal-ui/core';
import axios from 'axios';
import configData from '../../../../../config/config.json';
import { ConfirmationModalProps } from '../types';
import { ConfirmationModalContent } from './styles';

export const ConfirmationModal = ({
  open,
  modalTitle,
  onClose,
  onSave,
  onError,
  modalData: { title, firstname, lastname, addressLine1, addressLine2, city, postcode, quoteNumber, quoteKey },
}: ConfirmationModalProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      variant="confirmation"
      title={modalTitle}
      saveButtonEnabled={!loading}
      open={open}
      onClose={onClose}
      onSave={async () => {
        try {
          setLoading(true);
          const response = await axios.post(
            configData.SENDPOSTALQUOTE_URL,
            { quoteNumber, quoteKey },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );
          setLoading(false);
          onSave(response.data.success, response.data.taskAlreadyExists);
        } catch (error) {
          setLoading(false);
          onError();
        }
      }}
      dataTestid="postal-error-modal"
    >
      <ConfirmationModalContent>
        <Text data-testid="postal-modal-request" semibold>
          Please note postal quotes can take between 7 and 10 working days to arrive
        </Text>
        <H4 data-testid="postal-modal-info" bold>
          We will send a copy of your quote to your address, please confirm we have the correct details below.
        </H4>
        <Text data-testid="postal-modal-user-name">{`${title ?? ''} ${firstname} ${lastname}`}</Text>
        <Text data-testid="postal-modal-addressline1">{addressLine1}</Text>
        {addressLine2 && <Text data-testid="postal-modal-addressline2">{addressLine2}</Text>}
        <Text data-testid="postal-modal-city">{city}</Text>
        <Text data-testid="postal-modal-postcode">{postcode}</Text>
      </ConfirmationModalContent>
    </Modal>
  );
};
