import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { colors, H4, Modal } from '@digitalportal-ui/core';
import AccordionVariants from '../../../enums/accordionVariants';
import StyledAccordion from '../StyledAccordion/StyledAccordion';
import { updateModalInfo } from '../../../redux/slices/helpers';
import { CategoryTitle, AccordionWrapper, CustomText } from './styles';
import { EntitlementContent } from '../../../redux/slices/helpers/helpers.types';

export type EntitlementContentModalProps = {
  includedPoints: EntitlementContent[];
  excludedPoints: EntitlementContent[];
  title: string;
  openModal: boolean;
  displayHandBookText?: boolean;
  showTitles?: boolean;
  subHeaderText?: string;
  chipText?: string;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};

function EntitlementContentModal({
  includedPoints,
  excludedPoints,
  title,
  displayHandBookText,
  openModal,
  setOpenModal,
  showTitles = true,
  subHeaderText = '',
  chipText = 'Included',
}: EntitlementContentModalProps): React.JSX.Element {
  const dispatch = useDispatch();

  const entitlementContentBodyText = (content: EntitlementContent, index: number): React.JSX.Element => (
    <>
      {content.body.map((text: string) => (
        <CustomText
          key={text.concat(index.toString())}
          $isBulletPoint={text.includes('\t')}
          $isNewLine={text.includes('\n')}
          variant="body1"
        >
          {text}
        </CustomText>
      ))}
    </>
  );

  return (
    <Modal
      variant="info"
      title={title}
      open={openModal}
      showCloseFab
      onClose={() => {
        setOpenModal(!openModal);
        dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
      }}
      dataTestid={`diagnosis-modal-${title}`}
    >
      <Box>
        {subHeaderText?.length > 0 && (
          <Box marginBottom="24px">
            {subHeaderText?.split('\n').map((str, index) => (
              <H4 key={`sub-header-${index.toString()}`} marginBottom="8px">
                {str}
              </H4>
            ))}
          </Box>
        )}
        {showTitles && <CategoryTitle color={colors.darkGrey}>What’s included?</CategoryTitle>}
        <AccordionWrapper key="included-accordion-wrapper">
          {includedPoints?.map((content, index) => (
            <Box key={`${content.description.concat(index.toString())}-accordion-container`}>
              <StyledAccordion
                key={content.description.concat(index.toString())}
                variant={AccordionVariants.entitlements}
                title={content.description}
                chipText={chipText}
              >
                {entitlementContentBodyText(content, index)}
              </StyledAccordion>
            </Box>
          ))}
        </AccordionWrapper>
        {excludedPoints?.length > 0 && showTitles && (
          <CategoryTitle color={colors.darkGrey}>What’s not included?</CategoryTitle>
        )}
        <AccordionWrapper key="excluded-accordion-wrapper">
          {excludedPoints?.map((content, index) => (
            <Box key={`${content.description.concat(index.toString())}-accordion-container`}>
              <StyledAccordion
                key={content.description.concat(index.toString())}
                variant={AccordionVariants.entitlements}
                title={content.description}
                chipText="Not Included"
                chipColor="#F4D0D6"
              >
                {entitlementContentBodyText(content, index)}
              </StyledAccordion>
            </Box>
          ))}
        </AccordionWrapper>
        {displayHandBookText && (
          <H4 semibold mt={3}>
            Please see our handbook for full details of what is and isn’t covered
          </H4>
        )}
      </Box>
    </Modal>
  );
}

export default EntitlementContentModal;
