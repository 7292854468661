import styled from 'styled-components';
import { Button, CircularProgress } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const OvalButtonStyled = styled(Button)<{ $checked: boolean }>`
  text-transform: none;
  border-radius: 16px;
  border-color: ${colors.oceanBlue};
  color: ${({ $checked }) => ($checked ? colors.white : colors.oceanBlue)};
  background-color: ${({ $checked }) => ($checked ? colors.oceanBlue300 : colors.white)};
  width: 60px;
  font-weight: 600;
  font-size: 12px;
  padding: 3px;
  height: 24px;

  &:hover,
  &:hover.Mui-disabled {
    background-color: ${({ $checked }) => ($checked ? colors.oceanBlueDark : '')};
  }

  ${theme.breakpoints.up('md')} {
    width: 80px;
    height: 32px;
    font-size: 14px;
  }
`;

export const StyledLoader = styled(CircularProgress)<{ $checked: boolean }>`
  color: ${({ $checked }) => ($checked ? colors.white : colors.grey)};
`;
