import { useField, ErrorMessage } from 'formik';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { colors } from '@digitalportal-ui/core';
import { StyledCheckboxLabel, StyledCheckboxLabelBrick, StyledCheckboxLabelBox } from './styles';
import { ErrorMessageText } from '../../molecules/TextInputField/styles';
import CheckboxVariants from '../../../enums/checkboxVariants';

export type CheckboxProps = {
  children?: React.ReactNode;
  variant?: CheckboxVariants;
  isChecked?: boolean;
  isErrored?: boolean;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  testId: string;
  tabIndex?: number;
};

export default function Checkbox({
  variant,
  children,
  isChecked,
  isErrored,
  onChange,
  name,
  testId,
  tabIndex = 0,
}: CheckboxProps): React.JSX.Element {
  const [field, meta, helpers] = useField(name);
  let Label = StyledCheckboxLabel;
  const checkboxColor = isErrored && variant !== CheckboxVariants.brick ? colors.shyTomato : colors.grey;
  const checkboxStyle = { color: checkboxColor };

  field.value = isChecked !== undefined ? isChecked : field.value;

  if (variant === CheckboxVariants.box) {
    Label = StyledCheckboxLabelBox;
  } else if (variant === CheckboxVariants.brick) {
    Label = StyledCheckboxLabelBrick;
  }

  if (meta.error && meta.touched && variant !== CheckboxVariants.brick) {
    checkboxStyle.color = colors.shyTomato;
  } else if (field.value) {
    checkboxStyle.color = colors.oceanBlue;
  }

  return (
    <>
      <Label $ischecked={field.value} $iserrored={isErrored} data-testid={`label-${testId}`}>
        <MuiCheckbox
          name={name}
          onChange={(e) => {
            helpers.setTouched(true);
            helpers.setValue(e.target.checked);
            if (onChange) {
              onChange(e);
            }
          }}
          checked={field.value}
          style={checkboxStyle}
          tabIndex={tabIndex}
          data-testid={`checkbox-${testId}`}
        />
        {children}
      </Label>
      <ErrorMessage
        name={name}
        render={(message) => (
          <ErrorMessageText mt={variant ? 1 : 0} textAlign="left" data-testid={`error-message-${testId}`}>
            {message}
          </ErrorMessageText>
        )}
      />
    </>
  );
}
