import { Dispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import { updateApiError } from '../../redux/slices/helpers';

export default function HandleError(error: unknown, dispatch: Dispatch, unhandledErrorMessage?: string) {
  const errorMessage = unhandledErrorMessage ?? '';
  if (axios.isAxiosError(error)) {
    let apiErrorMessage = errorMessage;

    if (error.response?.status === 400) {
      if (typeof error.response.data?.errors === 'object') {
        apiErrorMessage = error.response.data.errors?.map((m: any) => m.reason).join(', ') ?? errorMessage;
      }

      if (typeof error.response.data === 'string') {
        apiErrorMessage = error.response.data;
      }
    }

    if (error.response?.status === 500) {
      if (typeof error.response.data?.detail === 'string') {
        apiErrorMessage = error.response.data.detail;
      }
    }

    dispatch(
      updateApiError({
        apiError: {
          hasError: true,
          message: apiErrorMessage,
        },
      }),
    );
  } else {
    dispatch(
      updateApiError({
        apiError: {
          hasError: true,
          message: '',
        },
      }),
    );
  }
}
