import { colors, H2, Text } from '@digitalportal-ui/core';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';
import { CardContainer, LinkWrapper, StyledButton } from './styles';

function BusinessCard(): React.JSX.Element {
  return (
    <CardContainer data-testid="business-card">
      <H2
        data-testid="business-card-header"
        bold
        color={colors.white}
        mb={2}
      >
        Do you own a business?
      </H2>
      <Text
        data-testid="business-card-body"
        color={colors.white}
        mb={2}
      >
        If you or somebody you’d like cover for owns a business, we offer health insurance for small businesses,
        including business owners and their employees. We can also cover family members too.
      </Text>
      <LinkWrapper
        to="https://www.axahealth.co.uk/small-business/ahp-small-business-healthcare-cover/"
        aria-label="find-out-more-link"
        data-testid="find-out-more-link"
      >
        <StyledButton
          variant="outlined"
          type="button"
          endIcon={<ChevronRightIcon sx={{ color: colors.white }} />}
        >
          Find out more
        </StyledButton>
      </LinkWrapper>
    </CardContainer>
  );
}
export default BusinessCard;
