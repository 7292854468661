import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Text } from '@digitalportal-ui/core';
import { useMemo } from 'react';
import routes from '../../../../enums/routes';
import { TStore } from '../../../../redux/store';
import CoreCard from '../../../molecules/CoreCard/CoreCard';
import { StyledLink } from '../../../atoms/Link/Link';
import config from '../../../../config/config.json';
import GuidedSelectionOptions from '../../../../enums/guidedSelection';
import { CoreSpecialistCardContent, SpecialistExcerpt, SpecialistTitle } from './styles';

const IsRHLOn = String(config.FEATURES.RHL) === 'true';

export default function SpecialistSelection(): React.JSX.Element | null {
  const history = useNavigate();

  const { members } = useSelector((state: TStore) => state.quoteState.quote);
  const { navigateOptions, guidedSelection } = useSelector((state: TStore) => state.helpersState.helpers);
  const isGuided: boolean = guidedSelection === GuidedSelectionOptions.guided;

  const specialistTitle = isGuided ? 'We’ll source your specialist' : 'You choose your own specialist from our list';
  const specialistExcerpt = isGuided
    ? 'If you want to use a different specialist, you’ll need to pay towards the cost of treatment'
    : 'We’ll let you know which specialists we cover when you get in touch, and can book the appointment for you if you prefer';

  const modalTitle = 'About your options for sourcing a specialist';
  const modalContent = useMemo(
    () => (
      <Box>
        <Text semibold mb={1}>
          If you choose &apos;I&apos;m happy for AXA Health to source a specialist for me&apos;:
        </Text>
        <Text display="list-item" ml={4}>
          Please contact us when you need treatment. We can book the appointment for you too.
        </Text>
        <Text display="list-item" ml={4}>
          We&apos;ll source a specialist based on your GP&apos;s referral.
        </Text>
        <Text display="list-item" ml={4} mb={1}>
          We&apos;ll try to source a specialist in a convenient location. This will usually be within 20 miles (5 miles
          in London)
        </Text>
        <Text display="list-item" ml={4}>
          The specialist may not work at your closest local hospital, or we may not cover them at that hospital. For
          certain treatment, you may need to travel further.
        </Text>
        <Text display="list-item" mb={1} ml={4}>
          If you use the specialist we source, we&apos;ll cover their fees in full. If you want to use a different
          specialist, you&apos;ll usually need to pay towards your treatment. This could be a lot of money, especially
          if you have surgery. Sometimes you may need to pay the full cost.
        </Text>
        <Text semibold mb={1}>
          If you choose &apos;I want to source my own specialist&apos;:
        </Text>
        <Text display="list-item" ml={4}>
          Please contact us with the name of your specialist so we can check we work with them.
        </Text>
        <Text display="list-item" ml={4} mb={1}>
          We can still find you a specialist and book the appointment for you if you prefer.
        </Text>
      </Box>
    ),
    [],
  );

  if (!IsRHLOn) return null;

  return (
    <CoreCard
      title="Choosing a specialist"
      bubbleTitle={members.length > 1 ? 'Applies to every member' : ''}
      modalTitle={modalTitle}
      modalChildren={modalContent}
      testId="specialist-network-selection"
      cardBodyMobileMarginBottom="8px"
    >
      <CoreSpecialistCardContent>
        <SpecialistTitle>{specialistTitle}</SpecialistTitle>
        <SpecialistExcerpt>{specialistExcerpt}</SpecialistExcerpt>
        <Box textAlign="left">
          <StyledLink
            to=" "
            aria-label="change-your-choice-button-choosing-a-specialist"
            data-testid="change-your-choice-button-choosing-a-specialist"
            $marginTop="0"
            onClick={(e) => {
              e.preventDefault();
              history(routes.guidedSelection, navigateOptions);
            }}
          >
            Change your choice
          </StyledLink>
        </Box>
      </CoreSpecialistCardContent>
    </CoreCard>
  );
}
