import { MouseEventHandler } from 'react';
import { H2, colors } from '@digitalportal-ui/core';
import { CardHeaderWrapper } from './styles';
import OvalButton from '../OvalButton/OvalButton';
import { InfoBubble } from '../../templates/styles';

export type CardHeaderProps = {
  title: string;
  interactable: boolean;
  clickHandler?: MouseEventHandler<HTMLButtonElement>;
  selected: boolean;
  loading: boolean;
  isButtonHidden?: boolean;
  dataTestId?: string;
  bubbleTitle?: string;
};

function CardHeader({
  title,
  clickHandler,
  interactable,
  selected,
  loading,
  isButtonHidden,
  dataTestId = '',
  bubbleTitle,
}: CardHeaderProps): React.JSX.Element {
  return (
    <CardHeaderWrapper>
      <H2 color={colors.darkGrey} data-testid={`price-card-title-${dataTestId}`}>
        {title}
      </H2>
      {bubbleTitle && <InfoBubble sx={{ mb: { xs: '8px' } }} $chipColor={colors.pacificBlue10}>{bubbleTitle}</InfoBubble>}
      {!isButtonHidden && (
        <OvalButton
          dataTestId={`price-card-select-entitlement-${dataTestId}`}
          clickHandler={clickHandler}
          disabled={!interactable}
          checked={selected}
          loading={loading}
        >
          {selected ? 'Remove' : 'Add'}
        </OvalButton>
      )}
    </CardHeaderWrapper>
  );
}

export default CardHeader;
