import { Box, RadioGroup } from '@mui/material';
import styled from 'styled-components';
import { theme } from '@digitalportal-ui/core';

export const Wrapper = styled(Box)`
  ${theme.breakpoints.up('md')} {
    width: 570px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const RadioContainer = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 auto;
  flex-wrap: nowrap;

  ${theme.breakpoints.up('md')} {
    width: 570px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 540px;
  }
`;

export const RadioWrapper = styled(Box)<{ $noGutter?: boolean }>`
  ${theme.breakpoints.up('xs')} {
    margin-bottom: ${({ $noGutter }) => ($noGutter ? 0 : '16px')};
  }
  ${theme.breakpoints.up('md')} {
    margin-bottom: ${({ $noGutter }) => ($noGutter ? 0 : '24px')};
  }
`;

export const ErrorContainer = styled(Box)`
  margin-bottom: 16px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 24px;
  }
`;

export const BusinessCardWrapper = styled(Box)`
  margin-top: 16px;

  ${theme.breakpoints.up('md')} {
    margin-top: 32px;
  }
`;
