import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { getFaqs } from '../lib/utils/services/contentService';
import { createQuote } from '../lib/utils/services/quoteService';
import { updateHelperFAQ, updatePastDateWarningModalInfo } from '../redux/slices/helpers';
import { Quote, updateQuote, updateQuoteSnapshot } from '../redux/slices/quote';
import { TStore } from '../redux/store';
import { GAQuoteData } from '../lib/tagManager/commonFunctions';
import { checkCoverStartDate } from '../lib/utils/checkCoverStartDate';
import HandleError from '../lib/utils/handleError';

export type RequestCreateQuoteType = {
  paymentFrequency: string;
  triggerQuoteEmail: boolean;
  updateQuoteDetails?: boolean;
  getFAQs?: boolean;
  successCallback?: (quoteResponse: Partial<Quote>) => void;
  errorCallback?: () => void;
};

export function useCreateQuote() {
  const dispatch = useDispatch();
  const initialQuote = useSelector((state: TStore) => state.quoteState.quote);
  const members = useSelector((state: TStore) => state.quoteState.quote.members);
  const { coverStartDate } = initialQuote;

  const requestCreateQuote = async (payload: RequestCreateQuoteType) => {
    const { paymentFrequency, triggerQuoteEmail, updateQuoteDetails, getFAQs, successCallback, errorCallback } = payload;

    const membersBody = members.map((member) => ({
      title: member.title,
      firstname: member.firstname,
      lastname: member.lastname,
      email: member.email,
      phone: member.phone,
      dateOfBirth: `${member.dob.year}-${member.dob.month}-${member.dob.day}`,
      excess: member.excess,
      entitlements: member.entitlements,
      postcode: member.postcode,
      city: member.city,
      addressLine1: member.addressLine1,
      addressLine2: member.addressLine2,
      policyHolder: member.policyHolder,
      majorHealthQuestions: member.majorHealthQuestions,
      consultation: member.consultation,
      ncdProtection: member.ncdProtection === 'Yes',
      preferredName: member.preferredName,
    }));
    try {
      const coverstartDatecheck = checkCoverStartDate(coverStartDate, dispatch);

      const response = await createQuote({
        members: membersBody,
        coverStartDate: coverstartDatecheck.coverStartDate,
        hospitalNetwork: initialQuote.hospitalNetwork,
        leadId: members.find((member) => member.policyHolder)?.leadId ?? '',
        paymentFrequency,
        opportunityId: initialQuote.opportunityId,
        triggerQuoteEmail,
        quoteKey: initialQuote.quoteKey,
        quoteNumber: initialQuote.quoteNumber,
      });

      const quoteResponse: Partial<Quote> = {
        quoteKey: response.quoteKey,
        quoteNumber: response.quoteNumber,
        coverStartDate: response.coverStartDate,
        expirationDate: response.expirationDate,
        quoteStatus: response.quoteStatus,
        opportunityId: response.opportunityId,
      };

      if (coverstartDatecheck.updated) {
        dispatch(updatePastDateWarningModalInfo(true));
      }

      if (updateQuoteDetails) {
        dispatch(updateQuote(quoteResponse));
        dispatch(updateQuoteSnapshot(quoteResponse));
      }

      if (getFAQs) {
        getFaqs().then((result) => dispatch(updateHelperFAQ({ faqs: result })));
      }

      const quoteEventData = GAQuoteData(initialQuote, 'quote_event');
      quoteEventData.quote_total_policy_revenue = paymentFrequency === 'Monthly' ? initialQuote.monthlyTotalContractPrice : initialQuote.annualTotalContractPrice;
      quoteEventData.quote_policy_ref = response.quoteNumber;
      quoteEventData.policy_payment_type = paymentFrequency;

      const tagManagerArgs = {
        dataLayer: quoteEventData,
      };
      TagManager.dataLayer(tagManagerArgs);

      if (successCallback) {
        successCallback(quoteResponse);
      }
    } catch (error) {
      HandleError(error, dispatch);
      if (errorCallback) {
        errorCallback();
      }
    }
  };

  return { requestCreateQuote };
}
