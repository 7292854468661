import axios from 'axios';
import { FAQ } from '../../../redux/slices/helpers/helpers.types';
import configData from '../../../config/config.json';

const headers = {
  'Content-Type': 'application/json',
};

const Faqs: FAQ[] = [
  {
    question: 'Lorem ipsum dolor sit amet',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
           Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
  {
    question: 'Lorem ipsum dolor sit',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
           Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
  {
    question: 'Lorem ipsum dolor',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
           Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
];

export async function getFaqs(): Promise<FAQ[]> {
  try {
    if (String(configData.FEATURES.FAQ_ARTICLES) === 'true') {
      const response = await axios.get(configData.GETFAQS_URL + configData.FAQCATEGORY, {
        headers,
      });
      return Object.keys(response.data.faqs).map((key) => response.data.faqs[key]) as FAQ[];
    }
    return Faqs;
  } catch (err) {
    return Faqs;
  }
}
