import styled from 'styled-components';
import { Box, InputLabel } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const DatePickerWrapper = styled(Box)`
  width: 100%;
  > .MuiFormControl-root {
    .MuiInputBase-root {
      height: 44px;
      border-radius: 6px;
      background-color: ${colors.white};
      input {
        text-transform: uppercase;
        height: 44px;
        padding: 0 8px;
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: ${colors.darkGrey};
          opacity: 1; /* Firefox */
        }
        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: ${colors.darkGrey};
        }
      }
      .MuiSvgIcon-root {
        cursor: pointer;
      }
      .MuiInputAdornment-root {
        .MuiButtonBase-root {
          color: ${colors.darkGrey};
        }
        svg {
          width: 18px;
        }
      }
      fieldset {
        top: 0;
        legend {
          display: none;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.grey};
      }
      &.Mui-error fieldset.MuiOutlinedInput-notchedOutline {
        border-color: ${colors.shyTomato};
      }
    }

    ${theme.breakpoints.up('md')} {
      .MuiInputBase-root {
        font-size: 14px;
        height: 50px;
        input {
          height: 50px;
          font-size: 16px;
          padding: 0 16px;
        }
        .MuiInputAdornment-root {
          padding-right: 4px;
          svg {
            width: 24px;
          }
        }
      }
    }
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  color: ${colors.darkGrey};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  margin-bottom: 8px;

  ${theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 20px;
  }
`;
