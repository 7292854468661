import React from 'react';
import { PopperProps } from '@mui/material';
import { ResponsivePopper } from './styles';

interface PopProps extends PopperProps {
  idName: string;
}

const Pop = ({ idName, ...props }: PopProps): React.JSX.Element => {
  const { anchorEl, ...rest } = props;
  const anchorNode = anchorEl as Element;
  const bound = anchorNode?.getBoundingClientRect();
  return (
    <ResponsivePopper
      {...rest}
      disablePortal
      id={`${idName}-dropdown`}
      data-testid="autocomplete-popper"
      anchorEl={anchorEl}
      placement="bottom-start"
      $width={`${bound.width + 18}px !important`}
    />
  );
};

export default Pop;
