import { useState } from 'react';
import { Box } from '@mui/material';
import { Formik, Form } from 'formik';
import { Button, Text, RadioVariants } from '@digitalportal-ui/core';
import { RadioBlockContainer, RadioBlockWrapper, ExplanationTextWrapper, NextButtonWrapper } from './styles';
import { FieldOption } from '../../../redux/slices/quote';
import FormikRadioBlock from '../../molecules/FormikRadioBlock/FormikRadioBlock';

export type AddMemberDetailsCardExpandFormProps = {
  isMemberPolicyHolder: boolean;
  isApplyToAllButtonAvailable: boolean;
  selectedFieldValue: string;
  fieldOptions: FieldOption[];
  selectedFieldValuesEqualForAllMembers: boolean;
  formSubmitHandler: (value: string) => void;
  radioClickHandler: (value: string) => void;
  explanationText: React.ReactNode;
  explanationTextVisibleOnlyOnValue?: string;
  radioBlockWrapperWidthMd?: string;
};

const AddMemberDetailsCardExpandForm = ({
  isMemberPolicyHolder,
  isApplyToAllButtonAvailable,
  selectedFieldValue,
  fieldOptions,
  selectedFieldValuesEqualForAllMembers,
  formSubmitHandler,
  radioClickHandler,
  explanationText,
  explanationTextVisibleOnlyOnValue,
  radioBlockWrapperWidthMd,
}: AddMemberDetailsCardExpandFormProps): React.JSX.Element => {
  // upodateState is used for radio block's onKeyDown event meaning it will force rerender on AddMemberDetailsCardExpandForm component.
  // This fixes bug when tabbing gets used and focusing on radio group focus does not get put on newly selected radio button.
  const [, updateState] = useState({});

  const showExplanationText = explanationTextVisibleOnlyOnValue ?
    selectedFieldValue === explanationTextVisibleOnlyOnValue : true;

  const showApplyToAllButton = isMemberPolicyHolder && isApplyToAllButtonAvailable;

  return (
    <Formik
      initialValues={{ addMemberExpandedFormField: selectedFieldValue }}
      enableReinitialize
      onSubmit={(value) => {
        formSubmitHandler(value.addMemberExpandedFormField);
      }}
    >
      <Form data-testid="expand-form">
        <Box display="flex" flexDirection="column">
          <RadioBlockContainer $addMarginBottom={showExplanationText || showApplyToAllButton}>
            {fieldOptions?.map((option) => (
              <RadioBlockWrapper
                onClick={(e) => {
                  e.preventDefault();
                  radioClickHandler(option.value);
                }}
                onKeyDown={() => {
                  updateState({});
                }}
                key={option.value}
                data-testid={`radioButton-${option.value.replace(/\s+/g, '-')}`}
                $widthMd={radioBlockWrapperWidthMd}
              >
                <FormikRadioBlock ariaLabel={option.displayName} variant={RadioVariants.brick} value={option.value} name="addMemberExpandedFormField">
                  <Text style={{ whiteSpace: 'pre-line', textAlign: 'left' }} semibold>{option.displayName}</Text>
                </FormikRadioBlock>
              </RadioBlockWrapper>
            ))}
          </RadioBlockContainer>
          {showExplanationText && explanationText && (
            <ExplanationTextWrapper data-testid="text-explanation" $addMarginBottom={showApplyToAllButton}>
              {explanationText}
            </ExplanationTextWrapper>
          )}
          {showApplyToAllButton && (
            <NextButtonWrapper data-testid="button-applyToAll">
              <Button disabled={selectedFieldValuesEqualForAllMembers}>Apply to all members</Button>
            </NextButtonWrapper>
          )}
        </Box>
      </Form>
    </Formik>
  );
};

export default AddMemberDetailsCardExpandForm;
