import ProductRulesMhqExclusionTypes from '../../../enums/productMhqExclusionTypes';
import { EntitlementErrorMessages } from '../../../redux/slices/helpers/helpers.types';
import MemberNotice from '../../atoms/CardListItem/MemberNotice/MemberNotice';
import MemberWarning from '../../atoms/CardListItem/MemberWarning/MemberWarning';
import { MemberNoticeWarningWrapper } from './styles';

type MemberNoticeAndWarningsProps = {
  entitlementRulesMet: EntitlementErrorMessages[];
  productCode: string;
};

export default function MemberNoticeAndWarnings({ entitlementRulesMet, productCode } : MemberNoticeAndWarningsProps) : React.JSX.Element {
  return (
    <>
      {entitlementRulesMet
        .filter(
          (x) => x.mhqExclusionType === ProductRulesMhqExclusionTypes.limitedCover && x.productCode === productCode,
        )
        .map((rule: EntitlementErrorMessages) => (
          <MemberNoticeWarningWrapper key={`warning_${rule.memberIndex}`}>
            <MemberNotice dataTestId={productCode} message={rule.message} />
          </MemberNoticeWarningWrapper>
        ))}
      {entitlementRulesMet
        .filter(
          (x) =>
            (x.error || x.mhqExclusionType === ProductRulesMhqExclusionTypes.noCover) && x.productCode === productCode,
        )
        .map((rule: EntitlementErrorMessages) => (
          <MemberNoticeWarningWrapper key={`notice_${rule.memberIndex}`}>
            <MemberWarning dataTestId={productCode} message={rule.message} />
          </MemberNoticeWarningWrapper>
        ))}
    </>
  );
}
