import styled from 'styled-components';
import { Card, TableCell, Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { theme, colors } from '@digitalportal-ui/core';

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 350px;
  width: 350px;
  padding: 32px 32px;
  border-radius: 6px;
  border: none;
  box-shadow: 0px 2px 4px 0px #0000000f;
  align-items: center;

  ${theme.breakpoints.down('xl')} {
    max-width: 290px;
  }

  ${theme.breakpoints.down('lg')} {
    max-width: 330px;
  }

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
    width: 100%;
    padding: 16px 16px;
  }
`;

export const StyledTable = styled(Box)<{ $quotePurchased: boolean }>`
  margin-bottom: ${({ $quotePurchased }) => ($quotePurchased ? '0' : '32px')};

  ${theme.breakpoints.down('md')} {
    margin-bottom: ${({ $quotePurchased }) => ($quotePurchased ? '0' : '16px')};
  }
`;

export const StyledTableCell = styled(TableCell)<{ $right?: boolean; $noGutter?: boolean }>`
  text-align: ${({ $right }) => ($right ? 'right' : 'left')};
  padding: ${({ $noGutter }) => ($noGutter ? '0' : '0px 0px 16px 0px')};
  font-size: 16px;
  line-height: 20px;
  border: none;

  ${theme.breakpoints.down('lg')} {
    padding: ${({ $noGutter }) => ($noGutter ? '0' : '0px 0px 8px 0px')};
  }

  ${theme.breakpoints.down('md')} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const SummaryBodyWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 28px;

  ${theme.breakpoints.down('md')} {
    margin-top: 16px;
    margin-bottom: 12px;
  }
`;

export const StyledPriceTypography = styled(Typography)`
  font-weight: bold;
  font-size: 64px;
  font-family: Source Sans Pro;
  color: ${colors.darkGrey};

  ${theme.breakpoints.down('md')} {
    font-size: 48px;
  }
`;

export const PerMonthWrapper = styled(Box)`
  color: ${colors.grey};
  margin-top: 8px;

  ${theme.breakpoints.up('md')} {
    margin-top: 16px;
  }
`;

export const DescriptionBox = styled(Box)`
  color: ${colors.grey};
  margin-bottom: 32px;
  ${theme.breakpoints.down('md')} {
    margin-bottom: 8px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${colors.mercury};
  margin-bottom: 32px;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 8px;
  }
`;

export const StyledInfoIcon = styled(InfoOutlinedIcon)`
  margin-top: -5px;
  font-size: 18px;
`;
