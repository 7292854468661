import { H2, Text, theme } from '@digitalportal-ui/core';
import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import { CardContainer } from './styles';

export type CampaignCardProps = {
  header: string;
  subHeader: string;
  bodyText: string | React.ReactNode | React.ReactNode[];
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

function CampaignCard({ header, subHeader, bodyText, image }: CampaignCardProps): React.JSX.Element {
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CardContainer>
      <Box sx={{ width: { md: '60%' } }} flexDirection="column" alignSelf="center">
        <H2 data-testid="campaign-card-header" mb={2}>{header}</H2>
        <Text data-testid="campaign-card-sub-header" mb={1} semibold>{subHeader}</Text>
        <Text data-testid="campaign-card-body-text">{bodyText}</Text>
      </Box>
      <Box data-testid="campaign-card-image" sx={{ width: { md: '40%' } }} textAlign="center">
        {React.createElement(image, { style: { width: mobileBreakpoint ? '232px' : '280px', height: '100%' } })}
      </Box>
    </CardContainer>
  );
}
export default CampaignCard;
