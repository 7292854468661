export function scrollToFirstError(errorSelector: string) {
  const header = document.querySelector('header') || {
    getBoundingClientRect: () => ({ height: 0 }),
  };
  const footer = document.querySelector('footer') || {
    getBoundingClientRect: () => ({ height: 0 }),
  };
  const error = document.querySelector(errorSelector) || {
    getBoundingClientRect: () => ({ height: 0, top: 0 }),
  };

  if (error) {
    const errorTopBorderFromTop = error.getBoundingClientRect().top + window.scrollY;
    const errorBottomBorderFromTop =
      error.getBoundingClientRect().height + error.getBoundingClientRect().top + window.scrollY;

    const errorTopBorderBelowHeadersBottomBorder =
      errorTopBorderFromTop >= window.scrollY + header.getBoundingClientRect().height;
    const errorBottomBorderAboveFootersTopBorder =
      window.scrollY + window.innerHeight >= errorBottomBorderFromTop + footer.getBoundingClientRect().height;

    if (!errorTopBorderBelowHeadersBottomBorder || !errorBottomBorderAboveFootersTopBorder) {
      const pixelsToScrollUp = window.scrollY + header.getBoundingClientRect().height - errorTopBorderFromTop;
      const pixelsToScrollDown =
        errorBottomBorderFromTop - (window.scrollY + window.innerHeight - footer.getBoundingClientRect().height);

      window.scrollTo({
        top: !errorTopBorderBelowHeadersBottomBorder
          ? window.scrollY - pixelsToScrollUp
          : window.scrollY + pixelsToScrollDown,
        behavior: 'smooth',
      });
    }
    return true;
  }
  return false;
}
