import TagManager from 'react-gtm-module';
import { Quote } from '../../redux/slices/quote';
import { Ecommerce, EcommerceItem, GAQuote, ProductAddon } from './types';
import { MarketingConsent, Policy } from '../../redux/slices/policy/policy.types';

export const EcommerceData = (quote: Quote, price: number, policyNumber?: string): Ecommerce => {
  const ecommerce = {
    value: price,
    currency: 'GBP',
    items: [] as EcommerceItem[],
  } as Ecommerce;
  const { members } = quote;

  if (policyNumber) {
    ecommerce.transaction_id = policyNumber;
  }

  members.forEach((member) => {
    member.entitlements
      .filter((x) => x.selected)
      .forEach((entitlement) => {
        const entitlementItem = ecommerce?.items?.filter((x) => x.item_id === entitlement.productCode);
        if (entitlementItem?.length === 1) {
          entitlementItem[0].quantity += 1;
        } else {
          const data: EcommerceItem = {
            item_name: entitlement.name,
            item_id: entitlement.productCode,
            price: 0.0,
            item_brand: 'AXA Health',
            quantity: 1,
          };
          ecommerce.items.push(data);
        }
      });

    const consultationItem = ecommerce.items.filter((x) => x.item_variant === member.consultation);
    if (consultationItem.length === 1) {
      consultationItem[0].quantity += 1;
    } else {
      const data: EcommerceItem = {
        item_name: 'outpatient consultations',
        item_id: 'outpatient consultations',
        price: 0.0,
        item_brand: 'AXA Health',
        item_variant: member.consultation,
        quantity: 1,
      };
      ecommerce.items.push(data);
    }
  });

  ecommerce.items.push({
    item_name: 'Everyday healthcare',
    item_id: 'EveryDayHealthCare',
    price: 0.0,
    item_brand: 'AXA Health',
    quantity: members.length,
  });

  return ecommerce;
};

export const GAQuoteData = (quote: Quote, event: string) => {
  const { members } = quote;
  const numberOfMembers = members.length;
  let excessLevels = '';
  let ncdProtectionValues = '';

  members.forEach((member, index) => {
    let { excess, ncdProtection } = member;
    if (index !== numberOfMembers - 1) {
      excess += ',';
      ncdProtection += ',';
    }
    excessLevels += excess;
    ncdProtectionValues += ncdProtection;
  });

  return {
    event,
    lead_id: members.find((x) => x.policyHolder)?.leadId,
    quote_policy_ref: quote.quoteNumber,
    policy_members: numberOfMembers.toString(),
    policy_excess: excessLevels,
    policy_specialists: quote.hospitalNetwork,
    ncd_protection: ncdProtectionValues,
  } as GAQuote;
};

export const AddProductAddonToDataLayer = (productAddon: string, membersUpdatedIndex?: string) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'entitlement_interaction',
      entitlement_product: productAddon,
      entitlement_category: 'addons',
      entitlement_interaction_type: 'select',
    } as ProductAddon,
  };

  if (membersUpdatedIndex) {
    tagManagerArgs.dataLayer.entitlement_member = membersUpdatedIndex;
  }

  TagManager.dataLayer(tagManagerArgs);
};

export function MarketingConsentsStringBuilder(marketingConsents: MarketingConsent) {
  let resultString = '';
  if (marketingConsents.email) {
    resultString += 'email,';
  }
  if (marketingConsents.textMessage) {
    resultString += 'text messages,';
  }
  if (marketingConsents.phoneCall) {
    resultString += 'phone call,';
  }
  if (marketingConsents.post) {
    resultString += 'post,';
  }
  if (resultString.endsWith(',')) {
    resultString = resultString.slice(0, resultString.length - 1);
  } else {
    resultString = 'none';
  }
  return resultString;
}
export const GaPurchase = (policy: Policy, quote: Quote) => {
  const price =
    policy.paymentFrequency === 'Monthly' ? quote.monthlyTotalContractPrice : quote.annualTotalContractPrice;
  const quoteEventData = GAQuoteData(quote, 'purchase');
  const ecommerceEventData = EcommerceData(quote, price, policy.policyNumber);
  quoteEventData.ecommerce = ecommerceEventData;
  quoteEventData.policy_payment_type = policy.paymentFrequency;
  quoteEventData.policy_payment_method = policy.selectedPaymentMethod;
  quoteEventData.marketing_preferences = MarketingConsentsStringBuilder(policy.marketingConsents);
  const tagManagerArgs = {
    dataLayer: quoteEventData,
  };
  TagManager.dataLayer(tagManagerArgs);
};
