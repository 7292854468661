import styled from 'styled-components';
import { theme, colors } from '@digitalportal-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Box } from '@mui/system';
import AccordionVariants from '../../../enums/accordionVariants';

export const CustomAccordion = styled(Accordion)<{
  $variant: AccordionVariants;
}>`
  border-top: ${({ $variant }) => ($variant === AccordionVariants.faq ? '1px solid' : '')};
  border-top-color: ${({ $variant }) => ($variant === AccordionVariants.faq && colors.mercury)};
  box-shadow: ${({ $variant }) => (($variant === AccordionVariants.faq || $variant === AccordionVariants.coreCard) ? 'none' : '0px 2px 2px 0px #33333326')};
  border-radius: ${({ $variant }) => ($variant === AccordionVariants.faq ? '' : '6px')};
  border-bottom: ${({ $variant }) => ($variant === AccordionVariants.entitlements ? '1px solid' : '')};
  border-bottom-color: ${({ $variant }) => ($variant === AccordionVariants.entitlements && colors.mercury)};
  padding: ${({ $variant }) => ($variant === AccordionVariants.coreCard ? '32px 0 32px' : '32px')};
  width: 100%;
  
  background-color: ${({ $variant }) => ($variant === AccordionVariants.entitlements ? colors.alabaster : colors.white)} !important;

  &.Mui-expanded {
    margin: 0;
    overflow: hidden;
  }
  &:before {
    display: none;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  &.Mui-disabled {
    border-radius: 0;
    background-color: ${colors.alabaster};
  }
  ${theme.breakpoints.down('md')} {
    padding: ${({ $variant }) => ($variant === AccordionVariants.coreCard ? '16px 0 16px' : '16px')};
  };
`;

export const CustomAccordionSummary = styled(AccordionSummary)<{
  $variant: AccordionVariants;
}>`
  padding: 0;
  min-height: 0;
  text-align: left;

  &.Mui-disabled {
    opacity: 100;
  }
  .MuiIconButton-root {
    color: ${colors.mercury};
  }
  &.Mui-expanded {
    min-height: 0;
  }
  .MuiAccordionSummary-content {
    margin: 0;
    align-items: flex-start;
    flex-direction: column-reverse;

    ${theme.breakpoints.up('md')} {
      justify-content: space-between;
      flex-direction: row;
      align-items: unset;
    };
  }
`;

export const CustomAccordionDetails = styled(AccordionDetails)<{
  $variant: AccordionVariants;
}>`
  text-align: left;
  padding: ${({ $variant }) => ($variant === AccordionVariants.entitlements ? '8px 0 0 0' : '16px 0 0 0')};
  ${theme.breakpoints.up('md')} {
    padding: ${({ $variant }) => ($variant === AccordionVariants.entitlements ? '16px 0 0 0' : '32px 0 0 0')};
  } ;
`;

export const CustomAccordionSummaryHeading = styled(Box)<{
  $variant: AccordionVariants;
}>`
  align-self: flex-start;
  text-align: left;
  padding-right: ${({ $variant }) => ($variant === AccordionVariants.email ? '56px' : '0')};
`;
