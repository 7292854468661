import { Text, Modal } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import { PreExistingConditionsModalProps } from '../types';

export const PreExisitingConditionsModal = ({ open, modalTitle, onClose, onSave }: PreExistingConditionsModalProps) => (
  <Modal
    dataTestid="Pre-Existing-Conditions-Modal"
    variant="confirmation"
    open={open}
    title={modalTitle}
    onClose={onClose}
    onSave={onSave}
    customCloseButtonText="Go back"
    customSaveButtonText="Yes, continue"
    showCloseFab
  >
    <Box>
      <Text mb={1}>
        A pre-existing condition is any disease, illness or injury that you&apos;ve had medication, advice or treatment
        for or symptoms of, in the last three years before your cover starts. It doesn&apos;t matter if your condition
        has been diagnosed or not.
      </Text>
      <Text semibold>Are you happy to go ahead?</Text>
    </Box>
  </Modal>
);
