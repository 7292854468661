import { Text, colors, RadioVariants } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import { ContentSection, RadioContainer } from '../styles';
import { YourCardQuestionProps } from '../types';
import UnhappyPathDetails from '../UnhappyPathDetails';
import ErrorMessageBox from '../../../molecules/ErrorMessageBox/errorMessageBox';
import QuestionHeader from '../../../atoms/QuestionHeader/QuestionHeader';
import FormikRadioBlock from '../../../molecules/FormikRadioBlock/FormikRadioBlock';

function YourCardQuestion({
  touched,
  value,
  errorClose,
  setErrorClose,
  paymentMethod,
  quoteNumber,
  setFieldValue,
  phoneNumber,
}: YourCardQuestionProps): React.JSX.Element {
  return (
    <ContentSection>
      <QuestionHeader title={`Are you using your own ${paymentMethod === 1 ? 'bank account' : 'credit or debit card'}?`} testId="your-card-question" />
      <RadioContainer aria-label="radio-group-answers" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Box
          display="contents"
          onClick={() => {
            setFieldValue('sameAddress', undefined);
            setFieldValue('manualPolicyHolderBilling', false);
          }}
        >
          <FormikRadioBlock
            dataTestId="radioButton-yourCard-yes"
            isErrored={touched && value === undefined}
            variant={RadioVariants.dot}
            name="yourCard"
            value={0}
            checkedColor={colors.oceanBlue}
            ariaLabel="Yes"
          >
            <Text>Yes</Text>
          </FormikRadioBlock>
        </Box>
        <Box
          display="contents"
          onClick={() => {
            setFieldValue('sameAddress', undefined);
            setFieldValue('manualPolicyHolderBilling', false);
          }}
        >
          <FormikRadioBlock
            dataTestId="radioButton-yourCard-no"
            isErrored={touched && value === undefined}
            variant={RadioVariants.dot}
            name="yourCard"
            value={1}
            checkedColor={colors.oceanBlue}
            ariaLabel="No"
          >
            <Text>No</Text>
          </FormikRadioBlock>
        </Box>
      </RadioContainer>

      {UnhappyPathDetails(value === 1, paymentMethod ? 1 : 0, quoteNumber, phoneNumber)}

      {touched && value === undefined && !errorClose && (
        <Box sx={{ marginBottom: { md: '16px' } }}>
          <ErrorMessageBox closeError={setErrorClose} />
        </Box>
      )}
    </ContentSection>
  );
}

export default YourCardQuestion;
