import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaymentDetailsForm from '../../organisms/PaymentDetailsForm/PaymentDetailsForm';
import { updateHelperIsProgressShown } from '../../../redux/slices/helpers';
import ConfirmAndPaySummary from '../../organisms/ConfirmAndPaySummary/ConfirmAndPaySummary';
import { persistor, TStore } from '../../../redux/store';
import Routes from '../../../enums/routes';
import Header from '../../templates/Header/Header';
import { Main } from '../../templates/styles';
import { StyledBox, MainWrapper, Wrapper } from './styles';
import { updateFormsPart, updateFrequency } from '../../../redux/slices/policy/policy.slice';
import { PolicyFormsPart } from '../../../redux/slices/policy/policy.types';
import { updateQuote } from '../../../redux/slices/quote';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import Loader from '../../atoms/Loader/Loader';
import { useCreateQuote } from '../../../hooks/useCreateQuote';

export default function PaymentDetails(): React.JSX.Element {
  const dispatch = useDispatch();
  const history = useNavigate();

  const initialValues = useSelector((state: TStore) => state.quoteState.quote);
  const quotePaymentFrequency = useSelector((state: TStore) => state.quoteState.quote.paymentFrequency);
  const policyHolder = initialValues.members.find((member) => member.policyHolder);
  const initialQuoteNumber = initialValues.quoteNumber;
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const { selectedPaymentMethod } = useSelector((state: TStore) => state.policyState.policy);
  const policyPaymentFrequency = useSelector((state: TStore) => state.policyState.policy.paymentFrequency);
  const [formCompleted, setFormCompleted] = useState(false);
  const [isMonthlyShown, setIsMonthlyShown] = useState(false);
  const [preferredDay, setPreferredDay] = useState('');
  const [loadingQuote, setLoadingQuote] = useState(false);
  const { requestCreateQuote } = useCreateQuote();

  useEffect(() => {
    if (!initialQuoteNumber) {
      history(Routes.triage);
    }
  }, []);

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: false }));
  }, [dispatch]);

  const routeToPayment = async () => {
    const redirectToCardDetails = selectedPaymentMethod === 'Card' && formCompleted;
    const redirectToDirectDebit = selectedPaymentMethod !== 'Card' && formCompleted;
    let apiError = false;

    if (policyPaymentFrequency !== quotePaymentFrequency && formCompleted) {
      if (initialValues.quoteKeyAnnual === '' && initialValues.quoteNumberAnnual === '') {
        setLoadingQuote(true);
        await requestCreateQuote({
          paymentFrequency: policyPaymentFrequency,
          triggerQuoteEmail: false,
          successCallback: (quoteResponse) => {
            dispatch(
              updateQuote({
                quoteKeyAnnual: quoteResponse.quoteKey,
                quoteNumberAnnual: quoteResponse.quoteNumber,
                opportunityId: quoteResponse.opportunityId,
              }),
            );
            dispatch(
              updateFormsPart({
                quoteKey: quoteResponse.quoteKey,
                quoteNumber: quoteResponse.quoteNumber,
              }),
            );
          },
          errorCallback: () => {
            setLoadingQuote(false);
            apiError = true;
          },
        });
      } else {
        dispatch(
          updateFormsPart({
            quoteKey: initialValues.quoteKeyAnnual,
            quoteNumber: initialValues.quoteNumberAnnual,
          }),
        );
      }
    } else {
      dispatch(
        updateFormsPart({
          quoteKey: initialValues.quoteKey,
          quoteNumber: initialValues.quoteNumber,
        }),
      );
    }

    if (!apiError && redirectToCardDetails) {
      persistor.flush().then(() => {
        window.location.href = Routes.cardDetails;
      });
    } else if (!apiError && redirectToDirectDebit) {
      history(Routes.directDebit, navigateOptions);
    } else {
      setLoadingQuote(false);
    }
  };

  useCustomEventListener('pageNavigationBack', () => {
    history(Routes.checkoutPolicyDocs, navigateOptions);
  });

  const detailsSubmit = (payload: PolicyFormsPart): void => {
    dispatch(updateFormsPart(payload));
    routeToPayment();
  };

  if (loadingQuote) {
    return (
      <>
        <Header />
        <Main maxWidth="lg">
          <Loader title="Time for a sprint finish..." subtitle="We’re just securing our payment gateway." />
        </Main>
      </>
    );
  }

  return (
    <>
      <Header />
      <Main>
        <PageIntroduction title="How would you like to pay?" titleTestId="title" />
        <MainWrapper mb={16}>
          <StyledBox>
            <Wrapper>
              <PaymentDetailsForm
                coverStartDate={initialValues.coverStartDate}
                initialValues={initialValues}
                quoteNumber={initialQuoteNumber}
                onSubmit={detailsSubmit}
                contacts={{
                  firstName: policyHolder?.firstname ?? '',
                  lastName: policyHolder?.lastname ?? '',
                  phone: policyHolder?.phone ?? '',
                  email: policyHolder?.email ?? '',
                }}
                address={{
                  addressLine1: policyHolder?.addressLine1 ?? '',
                  addressLine2: policyHolder?.addressLine2 ?? '',
                  city: policyHolder?.city ?? '',
                  country: policyHolder?.countrycode ?? '',
                  postcode: policyHolder?.postcode ?? '',
                }}
                onDateChange={(prefDay: string) => {
                  const matchedDate = prefDay.match(/(\d+)/);
                  setPreferredDay(matchedDate ? matchedDate[0] : '');
                }}
                onPaymentFrequencyChange={(isMonthly: boolean) => {
                  dispatch(updateFrequency(isMonthly));
                  setIsMonthlyShown(isMonthly);
                }}
                formCompleted={setFormCompleted}
              />
            </Wrapper>
            <PageNavigation />
          </StyledBox>
          <ConfirmAndPaySummary
            regularPaymentDate={preferredDay}
            cardData={initialValues}
            isMonthlyShown={isMonthlyShown}
          />
        </MainWrapper>
      </Main>
    </>
  );
}
