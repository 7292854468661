import { Box } from '@mui/material';
import { Text } from '@digitalportal-ui/core';
import { ContentSection, DropdownHolder } from '../styles';
import TextInputField from '../../../molecules/TextInputField';
import { PaymentDayQuestionProps } from '../types';
import QuestionHeader from '../../../atoms/QuestionHeader/QuestionHeader';

const dates = Array.from({ length: 28 }, (e, index) => {
  if (index + 1 === 1) return `${index + 1}st of every month`;
  if (index + 1 === 2) return `${index + 1}nd of every month`;
  if (index + 1 === 3) return `${index + 1}rd of every month`;
  if (index + 1 === 21) return `${index + 1}st of every month`;
  if (index + 1 === 22) return `${index + 1}nd of every month`;
  if (index + 1 === 23) return `${index + 1}rd of every month`;
  return `${index + 1}th of every month`;
});

function PaymentDayQuestion({ displayOverlay, phoneNumber }: PaymentDayQuestionProps): React.JSX.Element {
  return (
    <ContentSection>
      <Box>
        <QuestionHeader
          title="Which day of the month would you like us to take your payment?"
          testId="payment-day-question"
        />
        <DropdownHolder>
          <TextInputField
            wrapperMarginDisabled
            placeholder="Select"
            name="preferredDay"
            label=""
            list={[...dates]}
            testId="payment-details-day"
            swapTabIndex
            InputProps={{ tabIndex: displayOverlay ? -1 : 0 }}
          />
        </DropdownHolder>
      </Box>
      <Text semibold mb={1}>
        When we take your first payment
      </Text>
      <Text mb={1}>
        We’ll take your first payment on the date shown on the right. Then, we’ll take your payments on or just after
        the day you choose.
        <br />
        If you want to change this day at any time, please call us on {phoneNumber}.
      </Text>
      <br />
    </ContentSection>
  );
}

export default PaymentDayQuestion;
