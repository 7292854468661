import { theme } from '@digitalportal-ui/core';
import { Box, RadioGroup } from '@mui/material';
import styled from 'styled-components';

export const MainWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0;
  flex-direction: column-reverse;
  margin: 0 16px 100px;
  ${theme.breakpoints.up('lg')} {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
  }
  ${theme.breakpoints.down('lg')} {
    align-items: center;
  }
`;

export const BulletPoint = styled.span`
  margin: 8px;
`;

export const RadioBlockWrapper = styled(Box)`
  ${theme.breakpoints.down('md')} {
    width: 100%;
    &:first-child {
      margin-bottom: 16px;
    }
  }

  ${theme.breakpoints.up('md')} {
    display: inline-block;
    width: calc(50% - 15px);
  }
`;

export const RadioContainer = styled(RadioGroup)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-direction: column;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
    flex-direction: row;
  }
`;

export const ContentWrapper = styled(Box)`
  ${theme.breakpoints.up('xs')} {
    margin-top: 16px;
  }
  ${theme.breakpoints.up('md')} {
    margin-top: 32px;
  }
  ${theme.breakpoints.up('lg')} {
    margin-right: 30px;
    margin-top: 0px;
  }
`;

export const TitleWrapper = styled(Box)`
  ${theme.breakpoints.up('xs')} {
    margin-bottom: 16px;
    word-break: break-word;
  }
  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
    width: 570px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const AccordionWrapper = styled(Box)`
  ${theme.breakpoints.up('md')} {
    width: 690px;
  }

  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }

  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const CheckboxWrapper = styled(Box)`
  ${theme.breakpoints.down('md')} {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  ${theme.breakpoints.up('md')} {
    display: inline-block;
    width: 50%;
    padding: 15px 16px;
  }
`;

export const CheckboxContainer = styled(Box)`
  ${theme.breakpoints.up('md')} {
    text-align: left;
    margin: -15px -16px;
  }
`;
