import styled from 'styled-components';
import { Box } from '@mui/material';
import { H3, Text, theme } from '@digitalportal-ui/core';

export const CoreNetworkCardContent = styled(Box)`
  justify-content: column;
  text-align: left;
  width: 100%;
`;

export const NetworkModalContent = styled(Box)`
  padding: 0px !important;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  ${theme.breakpoints.up('md')} {
    > *:not(:last-child) {
      margin-bottom: 0px;
    }
  }
`;

export const NetworkTitle = styled(H3)`
  font-weight: bold !important;

  ${theme.breakpoints.up('sm')} {
    margin-bottom: 8px;
  }
  ${theme.breakpoints.up('md')} {
    margin-bottom: 16px;
  }
`;

export const NetworkExcerpt = styled(Text)`
  ${theme.breakpoints.up('sm')} {
    margin-bottom: 8px;
  }
  ${theme.breakpoints.up('md')} {
    margin-bottom: 16px;
  }
`;
