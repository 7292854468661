import { useEffect } from 'react';
import { H2, H4, Text } from '@digitalportal-ui/core';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TStore } from '../../../redux/store';
import Header from '../../templates/Header/Header';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import {
  PageWrapper,
  PageIntroductionWrapper,
  ContentWrapperRefEmail,
  ContentWrapperSteps,
  RefNrWrapper,
  StepsDescriptionWrapper,
  EntitlementImage,
  StyledMain,
  StyledList,
  StepsDescription,
  ImageWrapper,
} from './styles';
import { FooterWithStaticLinks } from '../../templates/Footer/Footer';
import Routes from '../../../enums/routes';
import { GaPurchase } from '../../../lib/tagManager/commonFunctions';

const stepsValues = [
  'Check your emails for a link to  set up your online account',
  'Set up your online account',
  'Check your membership information',
  'Explore all the extras you get',
];

export default function PaymentSuccess(): React.JSX.Element {
  const history = useNavigate();
  const policy = useSelector((state: TStore) => state.policyState.policy);
  const quote = useSelector((state: TStore) => state.quoteState.quote);
  const policyHolder = quote.members.find((member) => member.policyHolder);

  useEffect(() => {
    if (!policy?.policyNumber) {
      history(Routes.triage);
    }

    GaPurchase(policy, quote);
    return () => {
      history(Routes.triage);
    };
  }, []);

  return (
    <>
      <Header />
      <StyledMain maxWidth="lg">
        <PageWrapper display="flex" flexDirection="column" alignItems="center">
          <PageIntroductionWrapper sx={{ wordBreak: 'break-all' }}>
            <PageIntroduction
              title={`Thank you, ${
                policyHolder?.preferredName ? policyHolder?.preferredName : policyHolder?.firstname
              }. Glad to have you with us.`}
              titleTestId="title"
              includeWrapper={false}
              applyDecibelClass
            />
          </PageIntroductionWrapper>
          <ContentWrapperRefEmail>
            <RefNrWrapper>
              <H4 data-testid="referenceNrMessage">Your payment has been accepted, your reference number is</H4>
              <H4 data-testid="referenceNr" bold>{`${policy.policyNumber}`}</H4>
            </RefNrWrapper>
            <Text>
              We’ve emailed you your payment details - this tells you when we’ll take your first payment. Please keep the email as it’s your record of your payments.
            </Text>
          </ContentWrapperRefEmail>
          <ContentWrapperSteps display="flex">
            <StepsDescriptionWrapper>
              <StepsDescription>
                <H2>Next steps...</H2>
                <Text color="white">
                  You’ll receive a welcome email from us which will contain all the information you need to get going
                  and make the most of your AXA Health membership.
                </Text>
              </StepsDescription>
              <StyledList>
                {stepsValues.map((step, index) => (
                  <ListItem key={step}>
                    <ListItemIcon>
                      <H4 semibold>{`${index + 1}.`}</H4>
                    </ListItemIcon>
                    <ListItemText>
                      <H4 semibold>{step}</H4>
                    </ListItemText>
                  </ListItem>
                ))}
              </StyledList>
            </StepsDescriptionWrapper>
            <ImageWrapper>
              <EntitlementImage />
            </ImageWrapper>
          </ContentWrapperSteps>
        </PageWrapper>
      </StyledMain>
      <FooterWithStaticLinks />
    </>
  );
}
