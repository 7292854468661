import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import AddMemberCardDetailsCard from '../../atoms/AddMemberDetailsCard/AddMemberDetailsCard';
import routes from '../../../enums/routes';
import { TStore } from '../../../redux/store';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import Footer from '../../templates/Footer/Footer';
import { updateMemberNCD, updateQuote, updateQuoteAnnualPremiums } from '../../../redux/slices/quote';
import { updateHelperIsProgressShown, updateQuoteRetrieved } from '../../../redux/slices/helpers';
import AddMemberDetailsCardExpandForm from '../../atoms/AddMemberDetailsCardExpandForm/AddMemberDetailsCardExpandForm';
import { Member } from '../../../redux/slices/quote/quote.types';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import Loader from '../../atoms/Loader/Loader';
import { PageWrapper, PageIntroductionWrapper, AddMemberCardDetailsCardWrapper } from './styles';
import { UpdatePageTitle } from '../../../lib/utils/pageTitleHandler';
import { useGetPrice } from '../../../hooks/useGetPrice';
import isQuoteUpdated from '../../../lib/utils/isQuoteUpdated';
import { useCreateQuote } from '../../../hooks/useCreateQuote';
import { AddProductAddonToDataLayer } from '../../../lib/tagManager/commonFunctions';
import configData from '../../../config/config.json';

export default function Ncd(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { getPrice, loading } = useGetPrice();
  const { requestCreateQuote } = useCreateQuote();

  const [memberCardsExpanded, setMemberCardsExpanded] = useState([true]);
  const [loadingQuote, setLoadingQuote] = useState(false);

  const { navigateOptions, initialEntitlements, majorHealthQuestions } = useSelector(
    (state: TStore) => state.helpersState.helpers,
  );
  const initialQuote = useSelector((state: TStore) => state.quoteState.quote);
  const members = useSelector((state: TStore) => state.quoteState.quote.members);
  const quoteSnapshot = useSelector((state: TStore) => state.quoteState.quoteSnapshot);
  const { quoteNumber } = initialQuote;

  const ncdValuesEqualForAllMembers =
    members.filter((member) => member.ncdProtection === members.find((mem) => mem.policyHolder)?.ncdProtection)
      .length === members.length;

  const ncdOptions = [
    {
      displayName: 'Yes',
      value: 'Yes',
    },
    {
      displayName: 'No',
      value: 'No',
    },
  ];

  useEffect(() => {
    let title = '';

    if (loadingQuote) {
      title = 'Loading your quote';
    } else {
      title = 'Protecting your no claims discount';
    }

    UpdatePageTitle(title);
  }, [loadingQuote]);

  useCustomEventListener('pageNavigationBack', () => {
    if (String(configData.FEATURES.RHL) === 'true') {
      history(routes.excess, navigateOptions);
    } else {
      history(routes.hospitals, navigateOptions);
    }
  });

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: true }));
  }, [dispatch]);

  useEffect(() => {
    setMemberCardsExpanded(Array(members.length).fill(true));
  }, [members?.length]);

  useCustomEventListener('pageNavigationNext', async () => {
    if (
      quoteNumber === '' ||
      (quoteNumber !== '' && isQuoteUpdated(initialQuote, majorHealthQuestions, initialEntitlements, quoteSnapshot))
    ) {
      setLoadingQuote(true);
      requestCreateQuote({
        paymentFrequency: 'Monthly',
        triggerQuoteEmail: true,
        updateQuoteDetails: true,
        getFAQs: true,
        successCallback: () => {
          dispatch(updateQuoteRetrieved(false));
          setLoadingQuote(false);
          history(routes.quoteSummary, navigateOptions);
        },
        errorCallback: () => setLoadingQuote(false),
      });
      dispatch(
        updateQuoteAnnualPremiums({
          price: 0,
          tax: 0,
          totalContractPrice: 0,
        }),
      );
      dispatch(updateQuote({ quoteKeyAnnual: '', quoteNumberAnnual: '' }));
    } else {
      history(routes.quoteSummary, navigateOptions);
    }
  });

  const toggleExpandedHandler = (expanded: boolean, index: number) => {
    const newMemberCardsExpanded = [...memberCardsExpanded];
    newMemberCardsExpanded[index] = !expanded;
    setMemberCardsExpanded(newMemberCardsExpanded);
  };

  const formSubmitHandler = (ncdProtection: string) => {
    setMemberCardsExpanded(Array(members.length).fill(true));
    let membersUpdatedIndex = '';
    members.forEach((member: Partial<Member>, index: number) => {
      dispatch(
        updateMemberNCD({
          userEditIndex: index,
          ncdProtection,
        }),
      );
      membersUpdatedIndex += index;
      if (index !== members.length - 1) {
        membersUpdatedIndex += ',';
      }
    });
    AddProductAddonToDataLayer(`ncd ${ncdProtection}`, membersUpdatedIndex);
    getPrice({ partialMember: { ncdProtection } });
  };

  const radioClickHandler = (member: Partial<Member>, index: number) => {
    dispatch(
      updateMemberNCD({
        userEditIndex: index,
        ncdProtection: member.ncdProtection ?? '',
      }),
    );
    if (member.ncdProtection) {
      AddProductAddonToDataLayer(`ncd ${member.ncdProtection}`, index.toString());
    }
    getPrice({ partialMember: member });
  };

  if (loadingQuote) {
    return (
      <>
        <Header />
        <Main maxWidth="lg">
          <Loader
            title=" Now, let's crunch the numbers"
            subtitle="Whatever healthy looks like for you, we’re here to help you get there."
          />
        </Main>
      </>
    );
  }

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <PageWrapper display="flex" flexDirection="column" alignItems="center">
          <PageIntroductionWrapper>
            <PageIntroduction
              title="Would you like to protect your no claims discount?"
              titleTestId="title"
              subtitle={
                <>
                  <Box mb={1}>
                    Each year you don’t claim, you build up a no claims discount, just as with car insurance.
                  </Box>
                  <Box>
                    You can choose to protect that discount now, so if you happen to claim this year, you’ll still have
                    a discount on your payments if you renew next year.
                  </Box>
                </>
              }
              subtitleTestId="subtitle"
              includeWrapper={false}
              modalTitle="How does the no claims discount work?"
            >
              <Text mb={1}>
                Each year, any claims you make can affect the price you pay next year (not all claims will affect this).
                If you don’t make any claims, this will mean you get a no claims discount that can help to lower your
                renewal price.
              </Text>
              <Text>
                You can protect this discount by paying an extra cost now – so if you do claim this year, you’ll still
                have a discount if you renew.
              </Text>
            </PageIntroduction>
          </PageIntroductionWrapper>
          {members.map((member, index) => (
            <AddMemberCardDetailsCardWrapper
              key={`${member.firstname + index}`}
              className="add-member-card-details-wrapper"
            >
              <AddMemberCardDetailsCard
                policyHolder={member.policyHolder}
                memberDetailsProps={member}
                expanded={memberCardsExpanded[index]}
                toggleExpanded={(expanded) => {
                  toggleExpandedHandler(expanded, index);
                }}
                statusText={member.ncdProtection}
                cardContentChildren={
                  <AddMemberDetailsCardExpandForm
                    isMemberPolicyHolder={member.policyHolder}
                    isApplyToAllButtonAvailable={members.length > 1}
                    selectedFieldValue={member.ncdProtection}
                    fieldOptions={ncdOptions}
                    selectedFieldValuesEqualForAllMembers={ncdValuesEqualForAllMembers}
                    formSubmitHandler={formSubmitHandler}
                    radioClickHandler={(ncdProtection) => {
                      radioClickHandler({ id: member.id, ncdProtection }, index);
                    }}
                    explanationTextVisibleOnlyOnValue="No"
                    explanationText={
                      <Text display="inline">
                        If you don’t protect your no claims discount, and claim this year, your renewal price will be
                        higher.
                      </Text>
                    }
                    radioBlockWrapperWidthMd="50%"
                  />
                }
              />
            </AddMemberCardDetailsCardWrapper>
          ))}
          <PageNavigation wrapperMarginDisabled isNextButtonDisabled={loading} />
        </PageWrapper>
        <Footer data-testid="footer" isPrice priceLoading={loading} />
      </Main>
    </>
  );
}
