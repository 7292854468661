import CardHeader from '../../atoms/CardHeader/CardHeader';
import CardBody from '../../atoms/CardBody/CardBody';
import PriceCardProps from './types';
import { Wrapper, CardHeaderBodyWrapper, StyledCardContent } from './styles';

function PriceCard({
  cardData: {
    interactable,
    showChildren,
    selected,
    displayHandBookText,
    name,
    included,
    excluded,
    description,
    isButtonHidden,
    productCode,
    ShowTitles,
    BubbleTitle = '',
    subHeaderText = '',
    chipText = 'Included',
  },
  children,
  loading,
  clickHandler,
  dataTestId = '',
  disableModalLink,
}: PriceCardProps): React.JSX.Element {
  return (
    <Wrapper data-testid={`price-card-${dataTestId}`} id={dataTestId} $isincluded={selected}>
      <StyledCardContent>
        <CardHeaderBodyWrapper display="flex" flexDirection="column">
          <CardHeader
            title={name}
            interactable={interactable}
            clickHandler={clickHandler}
            selected={selected}
            loading={loading}
            isButtonHidden={isButtonHidden}
            dataTestId={dataTestId}
            bubbleTitle={BubbleTitle}
          />
          <CardBody
            title={name}
            subtitle={description}
            includedPoints={included}
            excludedPoints={excluded}
            productCode={productCode}
            showTitles={ShowTitles}
            subHeaderText={subHeaderText}
            chipText={chipText}
            displayHandBookText={displayHandBookText}
            disableModalLink={disableModalLink}
          />
        </CardHeaderBodyWrapper>
        {showChildren && children}
      </StyledCardContent>
    </Wrapper>
  );
}
export default PriceCard;
