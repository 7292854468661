import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { theme, ReactGoogleMaps } from '@digitalportal-ui/core';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { CookiesProvider } from 'react-cookie';
import MessageSnackBar from './components/templates/MessageSnackBar/MessageSnackBar';
import App from './apps/App';
import { store, persistor } from './redux/store';
import './assets/slick.min.css';
import PastDateWarningModal from './components/templates/PastDateWarningModal/PastDateWarningModal';
import { reactPlugin } from './lib/utils/services/applicationInsightsService';
import Fallback from './components/templates/Fallback/Fallback';
import config from './config/config.json';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StyledEngineProvider injectFirst>
    <ReactGoogleMaps.APIProvider apiKey={config.MAP_API_KEY}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <BrowserRouter>
            <CookiesProvider defaultSetOptions={{ secure: true, sameSite: 'strict', path: '/' }}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <PastDateWarningModal />
                  <AppInsightsErrorBoundary onError={() => <Fallback header="Whoops, looks like something went wrong!" subHeader="Try refreshing the page, if the error persists, please contact us using the number provided in the header" />} appInsights={reactPlugin}>
                    <App />
                  </AppInsightsErrorBoundary>
                  <MessageSnackBar />
                </PersistGate>
              </Provider>
            </CookiesProvider>
          </BrowserRouter>
        </ThemeProvider>
      </AppInsightsContext.Provider>
    </ReactGoogleMaps.APIProvider>
  </StyledEngineProvider>,
);
