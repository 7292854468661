import { useNavigate } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import { Box, Divider, ListItem, useMediaQuery } from '@mui/material';
import {
  Text,
  H4,
  colors,
  Modal,
  HospitalMarkerProps,
  theme,
  setBounds,
  HospitalsView,
  ReactGoogleMaps,
  Banner,
  H3,
  HospitalList,
  HospitalCardContentProps,
  ContactCard,
  RadioVariants,
} from '@digitalportal-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { TStore } from '../../../redux/store';
import { InfoLink } from '../../atoms/Link/Link';
import routes from '../../../enums/routes';
import { FieldOption, updateQuoteHospitalNetwork } from '../../../redux/slices/quote';
import { updateHelperIsProgressShown, updateModalInfo } from '../../../redux/slices/helpers';
import Footer from '../../templates/Footer/Footer';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import {
  PageWrapper,
  PageIntroductionWrapper,
  RadioBlockContainer,
  RadioBlockWrapper,
  RadioContent,
  NetworkContainer,
  NetworkWrapper,
  PostcodeContainer,
  UkMap,
  MapContainer,
  NetworkOptionsContainer,
  SearchButton,
  SearchPostcodeContainer,
  PhinLinkIcon,
  CustomText,
  ModalPhinLink,
  PhinWrapper,
  PhinText,
  ModalStyledList,
  NetworkContentList,
  PanelContainer,
} from './styles';
import NetworkContent from '../../../content/networkContent.json';
import RhlNetworkContent from '../../../content/rhlNetworkContent.json';
import { useGetPrice } from '../../../hooks/useGetPrice';
import { AddProductAddonToDataLayer } from '../../../lib/tagManager/commonFunctions';
import config from '../../../config/config.json';
import NetworkCodes from '../../../enums/networks';
import { Providers } from '../../../redux/slices/helpers/helpers.types';
import { getHospitals } from '../../../lib/utils/services/productService';
import HandleError from '../../../lib/utils/handleError';
import TextInputField from '../../molecules/TextInputField';
import { PostcodeSchema } from './validationSchema';
import PhinLogo from '../../../assets/svg/phin_logo.svg?react';
import GuidedSelectionOptions from '../../../enums/guidedSelection';
import { makePhoneCall } from '../../../lib/utils/phoneCall';
import Phone from '../../../assets/svg/phone.svg?react';
import {
  foundationHospitalListPdf,
  guidedHospitalListPdf,
} from '../quoteSummary/DownloadCards/DownloadCards';
import FormikRadioBlock from '../../molecules/FormikRadioBlock/FormikRadioBlock';

const NAME = 'hospitalNetwork';
const isRHLOn = String(config.FEATURES.RHL) === 'true';

export default function Hospitals(): React.JSX.Element {
  // Hooks
  const history = useNavigate();
  const dispatch = useDispatch();
  const largeDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const smallDesktopAndAbove = useMediaQuery(theme.breakpoints.up('lg'));
  const tabletAndBelow = useMediaQuery(theme.breakpoints.down('lg'));
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const mapContext = ReactGoogleMaps.useMap('hospital-map');
  const { getPrice, loading } = useGetPrice();

  // Getting from redux state
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const { providers, productRules, guidedSelection, availability } = useSelector((state: TStore) => state.helpersState.helpers);
  const { members, hospitalNetworkConfig, hospitalNetwork } = useSelector((state: TStore) => state.quoteState.quote);

  // Local state
  const [showMap, setShowMap] = useState(false);
  const [markers, setMarkers] = useState([] as HospitalMarkerProps[]);
  const [networks, setNetworks] = useState({} as Providers);
  const [fetchingNetworks, setFetchingNetworks] = useState(false);
  const [displayPostcode, setDisplayPostcode] = useState('');
  const [openOptionSelected, setOpenOptionSelected] = useState(false);
  const [showPostcodeField, setShowPostcodeField] = useState(false);
  const [postcodeErrored, setPostcodeErrored] = useState(false);
  const [networkOptions, setNetworkOptions] = useState([] as FieldOption[]);
  const [currentNetworkOptionModal, setCurrentNetworkOptionModal] = useState<{ open: boolean; value: string }>({
    open: false,
    value: '',
  });
  const [frictionModal, setFrictionModal] = useState(false);
  const [mapContextLoaded, setMapContextLoaded] = useState(false);

  const initialValues = {
    [NAME]: hospitalNetwork || 'GuidedOption',
    postcode: '',
  };

  const showPostcode = showMap && !openOptionSelected && !showPostcodeField;
  const showPhinLink = (networkOption: string) => networkOption === NetworkCodes.openOption;
  const isGuided: boolean = guidedSelection === GuidedSelectionOptions.guided;

  useEffect(() => {
    if (isRHLOn) {
      const guidedSelectionText = guidedSelection === GuidedSelectionOptions.guided ? 'guided' : 'nonGuided';

      const options = productRules.networks?.find(
        (networkRule) => networkRule.restrictions.guidedSelection === guidedSelectionText,
      )?.options;

      setNetworkOptions(
        hospitalNetworkConfig.options?.filter((network) => options?.some((option) => option.code === network.value)),
      );
    } else {
      setNetworkOptions(hospitalNetworkConfig.options);
    }
  }, [guidedSelection, hospitalNetworkConfig.options, productRules.networks]);

  // Update progress bar
  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: true }));
  }, [dispatch]);

  // Setting up initial data on first render only
  useEffect(() => {
    setNetworks(providers);
    setDisplayPostcode(members.find((mem) => mem.policyHolder)?.postcode ?? '');
    setOpenOptionSelected(hospitalNetwork === NetworkCodes.openOption);
  }, []);

  // Updating when to show the map
  useEffect(() => {
    const isMapEnabled = providers.networks?.some((x) => x.hospitals.length > 0);
    setShowMap(isMapEnabled);
  }, [providers]);

  // Setting bounds when viewport is changed or number of markers have changed
  useEffect(() => {
    if (mapContext && markers?.length > 0) {
      setBounds(mapContext, markers);
      setMapContextLoaded(true);
    }
  }, [mobile, smallDesktopAndAbove, mapContext, markers]);

  // Setting up markers for the map and closing any open info windows
  useEffect(() => {
    if (hospitalNetwork === NetworkCodes.openOption) {
      setOpenOptionSelected(true);
    } else {
      setOpenOptionSelected(false);
      const hospitals = networks?.networks?.find((x) => x.network === hospitalNetwork)?.hospitals;

      if (hospitals) {
        setMarkers(
          hospitals.map(
            (hospital) =>
              ({
                position: { lat: hospital.lat, lng: hospital.lng },
                title: hospital.hospitalName,
                hospitalInfo: {
                  hospital: hospital.hospitalName,
                  address: hospital.hospitalAddress,
                  drivingDistance: hospital.drivingDistance,
                  specialities: [],
                },
                infoWindowMaxWidth: 280,
              } as HospitalMarkerProps),
          ),
        );

        const event = new CustomEvent('close-info-window');
        mapContext?.getDiv().dispatchEvent(event);
      } else {
        setMarkers([]);
      }
    }
  }, [hospitalNetwork, mapContext, networks]);

  useCustomEventListener('pageNavigationBack', () => {
    if (isRHLOn) {
      history(routes.guidedSelection, navigateOptions);
    } else {
      history(routes.excess, navigateOptions);
    }
  });

  // Setup of the Map component and changing behaviour based off of specific dependencies
  const mapComponent = useMemo(() => {
    let viewButtonMarginBottom = '0';
    let height = '445px';
    const hospitalListInfo: HospitalCardContentProps[] = [];
    const hospitalCount = networks?.networks?.find((x) => x.network === hospitalNetwork)?.hospitals.length ?? 0;
    const hospitalsBannerText = `This shows your closest ${hospitalCount} hospitals and other clinics on this list`;

    if (tabletAndBelow && !mobile && postcodeErrored) {
      viewButtonMarginBottom = '25px';
    }

    if (mobile) {
      height = '320px';
    } else if (largeDesktop) {
      height = '465px';
    }

    if (!mapContextLoaded) {
      height = '0';
      markers?.forEach((marker) => hospitalListInfo.push(marker.hospitalInfo));
    }

    return (
      !openOptionSelected ? (
        <MapContainer
          data-testid="map-container"
          $postcodeErrored={postcodeErrored}
          $showPostcodeField={showPostcodeField}
          $hasMapContext={!!mapContextLoaded}
        >
          <HospitalsView
            mapProps={{
              markers,
              defaultCenter: { lat: networks?.postcodeLat, lng: networks?.postcodeLng },
              mapId: config.MAP_ID,
              style: { height, width: '100%' },
              reuseMaps: true,
            }}
            bannerText={hospitalsBannerText}
            viewButtonMarginBottom={viewButtonMarginBottom}
            hideViewToggle={!mapContextLoaded}
          />
          {!mapContextLoaded && <HospitalList hospitals={hospitalListInfo} />}
        </MapContainer>
      ) : (
        <PanelContainer>
          <Banner
            testId="hospitals-view"
            text="With this option you can use any private hospital in the UK"
          />
          <UkMap role="img" aria-label="uk map" />
        </PanelContainer>
      )
    );
  }, [
    networks?.networks,
    networks?.postcodeLat,
    networks?.postcodeLng,
    isGuided,
    tabletAndBelow,
    mobile,
    postcodeErrored,
    largeDesktop,
    markers,
    hospitalNetwork,
    mapContext,
    openOptionSelected,
  ]);

  const radioClickHandler = (value: string) => {
    dispatch(updateQuoteHospitalNetwork({ hospitalNetwork: value }));
    AddProductAddonToDataLayer(value);
    getPrice({ selectedHospitalNetwork: value });
  };

  function Submitter() {
    const { submitForm, errors, touched } = useFormikContext<{ [NAME]: string; postcode: string }>();

    useEffect(() => {
      setPostcodeErrored(errors.postcode !== undefined && touched.postcode === true);
    }, [errors, touched]);

    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }

  const PageSubTitle = useMemo(() => {
    if (isRHLOn) {
      if (isGuided) {
        return (
          <Box textAlign="center">
            <H3 mb={1}>If you’re happy with a smaller choice of hospitals, this will lower your price.</H3>
            <H3 mb={1}>
              We’ll always try to source a specialist who works from a convenient location, depending on where you live.
              It may not be your nearest hospital, even if that hospital is on your list.
            </H3>
            <H3 mb={1}>
              If you want to use a different specialist or hospital, you’ll usually need to pay towards the cost. This
              could be a lot of money.
            </H3>
            <H3>This choice is for everyone on your membership.</H3>
          </Box>
        );
      }
      return (
        <Box textAlign="center">
          <H3 mb={1}>If you’re happy with a smaller choice of hospitals, this will lower your price.</H3>
          <H3 mb={1}>
            Whichever option you choose here, you’ll be able to choose your own specialist, so long as they’re on our
            list.
          </H3>
          <H3>This choice is for everyone on your membership.</H3>
        </Box>
      );
    }
    return (
      <Box textAlign="center">
        <H3 mb={1}>We can give you a choice from our list, or you can choose yourself.</H3>
        <H3 mb={1}>
          The nearest hospitals you have access to with the Guided and Flexible options are shown below. With the Open
          option, you have access to these hospitals plus any other private hospital you like.
        </H3>
        <H3>
          The hospital you use will depend on the type of specialist you see and the condition you claim for. For the
          Guided option you usually won’t need to travel more than 20 miles (5 miles in London) from your chosen
          location.
        </H3>
      </Box>
    );
  }, [isGuided]);

  const handleSearchOnClick = async (
    searchPostcode: string,
    isValid: boolean,
    keyDownEvent?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if ((!keyDownEvent || keyDownEvent.key === 'Enter') && searchPostcode && isValid) {
      try {
        setFetchingNetworks(true);
        const response = await getHospitals(searchPostcode);
        setNetworks(response);
        setDisplayPostcode(searchPostcode);
        setFetchingNetworks(false);
        setShowPostcodeField(false);
      } catch (err) {
        setFetchingNetworks(false);
        HandleError(err, dispatch, 'Something went wrong. Unable to search using the provided postcode');
      }
    }
  };

  const RhlModalTitle = isGuided
    ? 'How hospital lists work when we source your specialist'
    : 'How hospital lists work when you choose your own specialist';
  const RhlModalBody: React.JSX.Element = isGuided ? (
    <Box>
      <Text mb={4}>
        With these options, we’ll source a specialist and hospital for you from the list you’ve chosen, and can book
        your appointment. If you want to use a different specialist or hospital, you’ll usually need to pay towards the
        cost.
      </Text>
      <Text semibold mb={1}>
        Which specialists will AXA Health source for me?
      </Text>
      <Text mb={1}>We’ll source a specialist based on your GP’s referral.</Text>
      <Text mb={1}>
        We’ll try to source a specialist in a convenient location, depending on where you live. This will usually be
        within 20 miles (5 miles in London).
      </Text>
      <Text mb={4}>
        The specialist may not work at your closest local hospital, or we may not cover them at that hospital. For
        certain treatment, you may need to travel further.
      </Text>
      <Text semibold mb={1}>
        What if I want to use another specialist or hospital?
      </Text>
      <Text mb={1}>
        If you use the specialist and hospital we source, we’ll cover their fees in full. If you want to use a different
        specialist, or go to a hospital that’s not on your list, you’ll usually need to pay towards the cost. This could
        be a lot of money, especially if you have surgery.
      </Text>
      <Text mb={4}>Please always contact us before treatment, and we’ll tell you how much you may need to pay.</Text>
      <Text semibold mb={1}>
        How does the booking service work?
      </Text>
      <Text mb={4}>
        When you contact us about treatment, we’ll ask you what times you’re available, and then book an appointment
        that suits you.
      </Text>
    </Box>
  ) : (
    <Box>
      <Text mb={4}>With these options, you can choose any specialist from our list.</Text>
      <Text semibold mb={1}>
        Which specialists can I see?
      </Text>
      <Text mb={4}>
        You can see any specialist on our list. Check with us first so we can confirm whether they’re on the list.
      </Text>
      <Text semibold mb={1}>
        Will I have to pay towards treatment if I use a specialist on the AXA Health list?
      </Text>
      <Text mb={1}>
        If you choose the Flexible option, we’ll usually cover the specialist’s fees in full. Sometimes you may need to
        pay towards treatment, if the specialist charges more than we usually pay. We’ll let you know if this is the
        case.
      </Text>
      <Text mb={4}>If you choose the Open option, we’ll cover the specialist’s fees in full.</Text>
      <Text semibold mb={1}>
        Can AXA Health help me find a specialist and book my appointment?
      </Text>
      <Text mb={1}>
        Yes, if you prefer we can book an appointment for you. We’ll ask you what times you’re available, and then book
        an appointment that suits you.
      </Text>
      <Divider sx={{ marginY: '24px' }} />
      <PhinWrapper>
        <Box alignSelf="center">
          <PhinLogo width="90px" height="auto" />
        </Box>
        <PhinText>
          You can find independent information about private consultants and hospitals, including costs and quality of
          care, from the Private Healthcare Information Network:{' '}
          <ModalPhinLink
            to="https://www.phin.org.uk/"
            target="_blank"
            aria-label="www.phin.org.uk"
            color={colors.oceanBlue300}
          >
            www.phin.org.uk
          </ModalPhinLink>
        </PhinText>
      </PhinWrapper>
    </Box>
  );

  const modalTitle = isRHLOn ? RhlModalTitle : 'How does this work?';
  const modalBody: React.JSX.Element = isRHLOn ? (
    RhlModalBody
  ) : (
    <Box>
      <Text mb={1}>If you’re happy for us to source your specialist, this will lower your price.</Text>
      <Text semibold mb={1}>
        Guided option:
      </Text>
      <Text display="list-item" ml={4}>
        We’ll only cover treatment with a specialist we’ve sourced for you at a hospital we have agreed.
      </Text>
      <Text display="list-item" ml={4}>
        We can book your appointment for you too.
      </Text>
      <Text display="list-item" ml={4} mb={1}>
        We’ll tell you which hospital to go to. The specialist may not work at your nearest hospital.
      </Text>
      <Text semibold mb={1}>
        Flexible option:
      </Text>
      <Text display="list-item" ml={4}>
        You can choose any specialist that we work with.
      </Text>
      <Text display="list-item" ml={4}>
        We’ll pay for treatment up to our agreed rates. If the specialist or hospital charges more, you may need to pay
        the difference.
      </Text>
      <Text display="list-item" ml={4}>
        You can use any hospital in our Directory of Hospitals.
      </Text>
      <Text display="list-item" ml={4} mb={1}>
        If you prefer, we can find a specialist for you and book your appointment.
      </Text>
      <Text semibold mb={1}>
        Open option:
      </Text>
      <Text display="list-item" ml={4}>
        You can choose any specialist that we work with.
      </Text>
      <Text display="list-item" ml={4}>
        We’ll pay the specialist’s and hospital’s fees in full.
      </Text>
      <Text display="list-item" ml={4}>
        You can use any private hospital in the UK.
      </Text>
      <Text display="list-item" ml={4} mb={1}>
        If you prefer, we can find a specialist for you and book your appointment.
      </Text>
      <Text>Please see our handbook for more about the specialists and hospitals we cover.</Text>
    </Box>
  );

  const FrictionModalContent = useMemo(
    () =>
      isGuided ? (
        <Box>
          <ModalStyledList>
            <ListItem>
              The specialist we source for you may not work at your nearest hospital, so you may need to travel further.
            </ListItem>
            <ListItem>
              If you want to use a specialist other than the one we source for you, or go to a hospital that’s not on
              your list, you’ll usually need to pay towards the cost. This may be a large amount.
            </ListItem>
          </ModalStyledList>
          <Text bold>Are you happy with this choice?</Text>
        </Box>
      ) : (
        <Box>
          <ModalStyledList>
            <ListItem>
              You can choose a specialist from our list, and any hospital from the full AXA Health list.{' '}
            </ListItem>
            <ListItem>
              We’ll usually cover the specialist’s fees in full. However, you may sometimes need to pay a top-up fee, if
              the specialist charges more than we usually pay. We’ll tell you about this when you get in touch with us
              about your treatment, and you’ll be able to switch to a specialist who doesn’t charge extra.
            </ListItem>
          </ModalStyledList>
          <Text bold>Are you happy with this choice?</Text>
        </Box>
      ),
    [isGuided],
  );

  const OptionTitle = useCallback(
    ({ displayName, value }: FieldOption) => {
      const hospitalOptionCount =
        networks.networks?.find((network) => network.network === value)?.hospitalTotalWithinDistance ?? 0;
      const optionTitle = RhlNetworkContent.find((x) => x.networkValue === value)?.header;
      const titleSuffix = hospitalOptionCount !== 1 ? 'hospitals within 20 miles' : 'hospital within 20 miles';
      const isOpenOption = value === NetworkCodes.openOption;
      const openOptionSuffix = '600+ hospitals across the UK';

      let title;
      if (isRHLOn) {
        if (isOpenOption) {
          title = `${optionTitle} - ${openOptionSuffix}`;
        } else {
          title = `${optionTitle} - ${hospitalOptionCount} ${titleSuffix}`;
        }
      } else {
        title = displayName;
      }

      return (
        <H4 bold color={colors.darkGrey} mb={1}>
          {title}
        </H4>
      );
    },
    [networks.networks],
  );

  const HospitalListLink = useCallback((option: string) => {
    if (showMap || option === NetworkCodes.openOption) {
      return null;
    }

    let pdf: string;

    switch (option) {
      case NetworkCodes.foundationOption:
        pdf = foundationHospitalListPdf;
        break;

      default:
        pdf = guidedHospitalListPdf;
        break;
    }

    const link = () => (
      <InfoLink
        link="View hospitals on this list"
        hideInfoLinkIcon
        testId={`${option}-hospital-list`}
        onClickHandler={() => window.open(pdf, '_blank')}
      />
    );

    if (isRHLOn) {
      return (
        <ListItem key={`${option}-hospital-list`}>{link()}</ListItem>
      );
    }

    return (
      <Box sx={{ mb: { md: '8px' } }}>{link()}</Box>
    );
  }, [showMap]);

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <PageWrapper display="flex" flexDirection="column" alignItems="center">
          <PageIntroductionWrapper>
            <PageIntroduction
              title={
                isRHLOn ? 'Which hospitals would you like to use?' : 'How would you like to choose your specialists?'
              }
              titleTestId="title"
              subtitle={PageSubTitle}
              subtitleTestId="subtitle"
              includeWrapper={false}
              modalTitle={modalTitle}
            >
              {modalBody}
            </PageIntroduction>
          </PageIntroductionWrapper>
          <NetworkContainer>
            <NetworkWrapper>
              <PanelContainer>
                <Formik
                  initialValues={initialValues}
                  validationSchema={PostcodeSchema}
                  validateOnBlur
                  enableReinitialize
                  onSubmit={(values) => {
                    if (isRHLOn) {
                      setFrictionModal(true);
                    } else {
                      history(routes.ncd, navigateOptions);
                    }
                    dispatch(updateQuoteHospitalNetwork({ hospitalNetwork: values[NAME] }));
                  }}
                >
                  {({ values, isValid }) => (
                    <Form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                      <NetworkOptionsContainer>
                        {showPostcode && (
                          <PostcodeContainer>
                            <Text mr={2}>
                              {'Based on your selection, the hospitals or clinics shown are closest to '}
                              <Text variantMapping={{ body1: 'span' }} display="inline" bold>
                                {displayPostcode.toUpperCase()}
                              </Text>
                            </Text>
                            <InfoLink
                              testId="change-location-link"
                              link="Change location or check family member’s postcode"
                              infoLinkIcon={
                                <FmdGoodIcon style={{ marginRight: '8px' }} htmlColor={colors.oceanBlue300} />
                              }
                              onClickHandler={() => {
                                setShowPostcodeField(true);
                              }}
                            />
                          </PostcodeContainer>
                        )}
                        {showPostcodeField && !openOptionSelected && (
                          <SearchPostcodeContainer onKeyDown={(e) => handleSearchOnClick(values.postcode, isValid, e)}>
                            <TextInputField
                              wrapperMarginDisabled
                              label="Enter town or postcode – this doesn’t need to be your home address"
                              name="postcode"
                              testId="textInputField-postcode"
                            >
                              <SearchButton
                                loading={fetchingNetworks}
                                type="button"
                                disabled={values.postcode.length === 0}
                                onClick={() => handleSearchOnClick(values.postcode, isValid)}
                              >
                                Search
                              </SearchButton>
                            </TextInputField>
                          </SearchPostcodeContainer>
                        )}
                        <RadioBlockContainer aria-label="radio-group-answers">
                          {networkOptions?.map((option, index) => (
                            <RadioBlockWrapper
                              key={option.value}
                              onClick={(e) => {
                                e.preventDefault();
                                radioClickHandler(option.value);
                              }}
                              data-testid={`radioHospitalButton-${index}`}
                            >
                              <FormikRadioBlock ariaLabel={option.displayName} variant={RadioVariants.brick} value={option.value} name={NAME}>
                                <RadioContent>
                                  {OptionTitle(option)}
                                  {isRHLOn ? (
                                    <NetworkContentList>
                                      {showPhinLink(option.value) && (
                                        <ListItem key={option.value}>
                                          You can choose any private hospital – please go to{' '}
                                          <InfoLink
                                            link="www.phin.org.uk"
                                            hideInfoLinkIcon
                                            testId="phin"
                                            onClickHandler={() => {
                                              window.open(
                                                `https://www.phin.org.uk/search/hospitals?s_location_input=${
                                                  members.find((mem) => mem.policyHolder)?.postcode
                                                }&s_location_coordinates=${providers.postcodeLat}%2C${
                                                  providers.postcodeLng
                                                }`,
                                                '_blank',
                                              );
                                            }}
                                          />{' '}
                                          to find hospitals near you
                                        </ListItem>
                                      )}
                                      {RhlNetworkContent.find((x) => x.networkValue === option.value)?.description.map(
                                        (text: string, i: number) => (
                                          <ListItem key={text.concat(i.toString())}>{text}</ListItem>
                                        ),
                                      )}
                                      {HospitalListLink(option.value)}
                                    </NetworkContentList>
                                  ) : (
                                    <>
                                      <Text sx={{ mb: { md: '8px' } }}>
                                        {NetworkContent.find((x) => x.networkValue === option.value)?.description}
                                      </Text>
                                      {showPhinLink(option.value) && (
                                        <Box sx={{ mb: { md: '8px' } }}>
                                          <InfoLink
                                            link="See a list of private hospitals near you"
                                            infoLinkIcon={<PhinLinkIcon />}
                                            testId="phin"
                                            onClickHandler={() => {
                                              window.open(
                                                `https://www.phin.org.uk/search/hospitals?s_location_input=${
                                                  members.find((mem) => mem.policyHolder)?.postcode
                                                }&s_location_coordinates=${providers.postcodeLat}%2C${
                                                  providers.postcodeLng
                                                }`,
                                                '_blank',
                                              );
                                            }}
                                          />
                                        </Box>
                                      )}
                                      {HospitalListLink(option.value)}
                                      <InfoLink
                                        aria-label="More-about-specialist-choice-and-fees-link"
                                        testId={`More-about-specialist-choice-and-fees-${index}`}
                                        onClickHandler={() => {
                                          setCurrentNetworkOptionModal({
                                            ...currentNetworkOptionModal,
                                            open: true,
                                            value: option.value,
                                          });
                                          dispatch(
                                            updateModalInfo({
                                              isModalOpen: true,
                                              modalTitle:
                                                NetworkContent.find((x) => x.networkValue === option.value)?.header ??
                                                '',
                                            }),
                                          );
                                        }}
                                        link="More about specialist choice and fees"
                                      />
                                    </>
                                  )}
                                </RadioContent>
                              </FormikRadioBlock>
                            </RadioBlockWrapper>
                          ))}
                        </RadioBlockContainer>
                        <Modal
                          variant="info"
                          title={
                            NetworkContent.find((x) => x.networkValue === currentNetworkOptionModal.value)?.header ?? ''
                          }
                          open={currentNetworkOptionModal.open}
                          onClose={() => {
                            setCurrentNetworkOptionModal({ ...currentNetworkOptionModal, open: false });
                            dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
                          }}
                          dataTestid={`network-option-modal-${currentNetworkOptionModal.value}`}
                        >
                          <Box>
                            <Box>
                              {NetworkContent.find((x) => x.networkValue === currentNetworkOptionModal.value)?.body.map(
                                (text: string, index: number) => (
                                  <CustomText
                                    $isBulletPoint={text.includes('\t')}
                                    $isNewLine={text.includes('\n')}
                                    key={text.concat(index.toString())}
                                    variant="body1"
                                  >
                                    {text}
                                  </CustomText>
                                ),
                              )}
                            </Box>
                            {currentNetworkOptionModal.value === NetworkCodes.openOption ? (
                              <>
                                <Divider sx={{ marginY: '24px' }} />
                                <PhinWrapper>
                                  <Box alignSelf="center">
                                    <PhinLogo width="90px" height="auto" />
                                  </Box>
                                  <PhinText>
                                    You can find independent information about private consultants and hospitals,
                                    including costs and quality of care, from the Private Healthcare Information
                                    Network:{' '}
                                    <ModalPhinLink
                                      to="https://www.phin.org.uk/"
                                      target="_blank"
                                      aria-label="www.phin.org.uk"
                                      color={colors.oceanBlue300}
                                    >
                                      www.phin.org.uk
                                    </ModalPhinLink>
                                  </PhinText>
                                </PhinWrapper>
                              </>
                            ) : null}
                          </Box>
                        </Modal>
                        <Submitter />
                      </NetworkOptionsContainer>
                    </Form>
                  )}
                </Formik>
              </PanelContainer>
              {showMap || openOptionSelected ?
                mapComponent :
                <PanelContainer height="fit-content">
                  <ContactCard
                    icon={<Phone />}
                    buttonText="Call 0800 169 7593"
                    title="Call us"
                    description="To find out which hospitals are on your chosen list, please download the list, or give us a call."
                    availabilityStatus={availability.workTimeText}
                    statusTextColor={availability.workTimeColor}
                    avatarBgColor={availability.availabilityStatus ? colors.pacificBlue10 : colors.mercury}
                    buttonWidthFitContent
                    isButtonDisabled={!availability.availabilityStatus}
                    onButtonClick={() => makePhoneCall('08001697593')}
                    testId="hospital-contact"
                  />
                </PanelContainer>
              }
            </NetworkWrapper>

            <Modal
              variant="confirmation"
              title="A little more about how this works..."
              dataTestid="hospital-modal"
              open={frictionModal}
              showCloseFab
              onSave={() => {
                setFrictionModal(false);
                history(routes.excess, navigateOptions);
              }}
              onClose={() => {
                setFrictionModal(false);
              }}
              customSaveButtonText="Yes I'm happy"
              customCloseButtonText="Go back"
            >
              {FrictionModalContent}
            </Modal>
            <PageNavigation isNextButtonDisabled={loading} />
          </NetworkContainer>
        </PageWrapper>
        <Footer data-testid="footer" isPrice priceLoading={loading} />
      </Main>
    </>
  );
}
