import { Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import Header from '../../templates/Header/Header';
import { Main } from '../../templates/styles';
import { Hero, StyledButton } from './styles';
import { FooterWithStaticLinks } from '../../templates/Footer/Footer';

function OfflineRedirect(): React.JSX.Element {
  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <Box display="flex" flexDirection="column" alignItems="center">
          <PageIntroduction title="Thanks for that information. One of our agents will call you shortly with a detailed quote" titleTestId="title" />
          <Hero role="img" aria-label="person working at a desk" />
          <StyledButton
            data-testid="homepage-button"
            href="https://www.axahealth.co.uk/axahealthplan/"
            color="secondary"
            startIcon={<HomeIcon />}
          >
            Return to the homepage
          </StyledButton>
        </Box>
      </Main>
      <FooterWithStaticLinks />
    </>
  );
}

export default OfflineRedirect;
