/* eslint-disable */
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import { FormHelperTextProps } from '@mui/material/FormHelperText';
import { InputLabelProps } from '@mui/material/InputLabel';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { FieldProps, getIn } from 'formik';
import * as React from 'react';

export interface SelectProps extends FieldProps, Omit<MuiSelectProps, 'name' | 'value'> {
  formControl?: FormControlProps;
  formHelperText?: FormHelperTextProps;
  inputLabel?: InputLabelProps;
}

export function fieldToSelect({
  disabled,
  field: { onBlur: _onBlur, onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors, setFieldTouched, setFieldValue },
  onClose,
  ...props
}: Omit<SelectProps, 'formControl' | 'formHelperText' | 'inputLabel'>): MuiSelectProps & { formError?: string } {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    disabled: disabled ?? isSubmitting,
    error: showError,
    formError: showError ? fieldError : undefined,
    onBlur: () => {
      // no-op
    },
    onChange:
      fieldOnChange ??
      (() => {
        // no-op
      }),
    // we must use `onClose` instead of `onChange` to be able to trigger validation when users click outside of the select list.
    onClose:
      onClose ??
      (async (e: React.SyntheticEvent) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataset = (e.target as any).dataset as DOMStringMap;
        if (dataset && dataset.value) {
          // out-of-sync issue since November 2019: https://github.com/formium/formik/issues/2059#issuecomment-890613538
          // without the await, formik validates with the former value
          await setFieldValue(field.name, dataset.value, false);
        }
        setFieldTouched(field.name, true, true);
      }),
    ...field,
    ...props,
  };
}

export function Select({ formControl, inputLabel, formHelperText, ...selectProps }: SelectProps) {
  const { error, formError, disabled, ...selectFieldProps } = fieldToSelect(selectProps);

  return (
    <FormControl fullWidth disabled={disabled} error={error} {...formControl}>
      <MuiSelect
        MenuProps={{
          MenuListProps: {
            'aria-label': 'dropdown',
          },
        }}
        {...selectFieldProps}
      />
    </FormControl>
  );
}

Select.displayName = 'FormikMaterialUISelect';
