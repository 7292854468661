import styled from 'styled-components';
import { IconButton, Box, Grid, CardContent } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const ContainerBox = styled(Box)`
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 2px rgba(51, 51, 51, 0.15);
`;

export const CardContainerGrid = styled(Grid)`
  position: relative;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  padding: 16px;

  ${[theme.breakpoints.up('md')]} {
    flex-direction: row;
    padding: 32px;
  }
`;

export const AvatarGrid = styled(Grid)`
  margin-right: 16px;
  ${theme.breakpoints.down('md')} {
    position: absolute;
    align-self: flex-start;
  }
`;

export const ButtonGrid = styled(Grid)`
  align-items: center;
  justify-content: center;

  ${[theme.breakpoints.down('md')]} {
    width: 100%;
  }
`;

export const DetailsBox = styled(Grid)`
  width: 100%;
  ${[theme.breakpoints.up('md')]} {
    padding-right: 16px;
  }
`;

export const ExpandMoreButton = styled(IconButton)<{
  $expanded?: boolean;
}>`
  padding-right: 12px;
  transform: ${({ $expanded }) => ($expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  color: ${colors.darkGrey};

  ${theme.breakpoints.down('md')} {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  ${theme.breakpoints.up('md')} {
    margin-right: -15px;
  }
`;

export const StatusTextWrapper = styled(Box)`
  white-space: nowrap;
  ${theme.breakpoints.down('md')} {
    width: 100%;
    text-align: left;
    margin-top: 16px;
  }

  ${theme.breakpoints.up('md')} {
    margin-right: 20px;
  }
`;

export const ShowMoreWrapper = styled(IconButton)<{
  $expanded?: boolean;
}>`
  transform: ${({ $expanded }) => ($expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  color: #4976ba;
`;

export const ShowHideBox = styled(Box)<{
  $expanded?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ $expanded }) => ($expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  border-top: 1px solid #e5e5e5;
`;

export const CardContentWrapper = styled(CardContent)<{ padding?: string }>`
  padding: ${(props) => props.padding || '16px'};
  padding-top: 0;

  &.MuiCardContent-root:last-child {
    padding-bottom: ${(props) => props.padding || '16px'};
  }

  ${theme.breakpoints.up('md')} {
    padding: ${(props) => props.padding || '32px'};
    padding-top: 0;

    &.MuiCardContent-root:last-child {
      padding-bottom: ${(props) => props.padding || '32px'};
    }
  }
`;
