export enum NameTitles {
  none ='',
  mr = 'Mr',
  mrs = 'Mrs',
  ms = 'Ms',
  mx = 'Mx',
  dr = 'Dr',
  prof = 'Prof',
  miss = 'Miss',
  master = 'Master',
  lady = 'Lady',
  rev = 'Rev',
  captain = 'Captain',
  sir = 'Sir',
  admiral = 'Admiral',
  airmarshall = 'Air Marshall',
  airvicemarshall = 'Air Vice Marshall',
  archbishop = 'Archbishop',
  archdeacon = 'Archdeacon',
  baron = 'Baron',
  baroness = 'Baroness',
  bishop = 'Bishop',
  brigadier = 'Brigadier',
  cardinal = 'Cardinal',
  chief = 'Chief',
}

export const TitlesList = [
  NameTitles.none,
  NameTitles.mr,
  NameTitles.ms,
  NameTitles.mrs,
  NameTitles.mx,
  NameTitles.dr,
  NameTitles.prof,
  NameTitles.miss,
  NameTitles.master,
  NameTitles.lady,
  NameTitles.rev,
  NameTitles.captain,
  NameTitles.sir,
  NameTitles.admiral,
  NameTitles.airmarshall,
  NameTitles.airvicemarshall,
  NameTitles.archbishop,
  NameTitles.archdeacon,
  NameTitles.baron,
  NameTitles.baroness,
  NameTitles.bishop,
  NameTitles.brigadier,
  NameTitles.cardinal,
  NameTitles.chief,
];
