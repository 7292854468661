import { InputLabel } from '@mui/material';
import styled from 'styled-components';
import { theme, colors } from '@digitalportal-ui/core';

export const StyledCheckboxLabel = styled(InputLabel)<{
  $ischecked: boolean;
  $iserrored?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: normal;
  margin-left: -11px;

  input {
    cursor: pointer;
  }

  .MuiCheckbox-root {
    align-self: baseline;
  }
`;

export const StyledCheckboxLabelBrick = styled(InputLabel)<{
  $ischecked: boolean;
  $iserrored?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 44px;
  padding: ${({ $ischecked }) => ($ischecked ? '0 8px' : '1px 9px')};
  border: solid;
  border-color: ${({ $ischecked, $iserrored }) => {
    if ($iserrored) {
      return colors.shyTomato;
    }
    if ($ischecked) {
      return colors.oceanBlue;
    }
    return colors.grey;
  }};
  border-width: ${({ $ischecked, $iserrored }) => ($ischecked || $iserrored ? '2px' : '1px')};
  border-radius: 6px;
  box-shadow: 0px 2px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

  ${theme.breakpoints.up('md')} {
    height: 50px;
  }

  ${theme.breakpoints.up('lg')} {
    padding-left: ${({ $ischecked }) => ($ischecked ? '24px' : '25px')};
  }

  input {
    cursor: pointer;
  }
`;

export const StyledCheckboxLabelBox = styled(InputLabel)<{
  $ischecked: boolean;
  $iserrored?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px;
  background: ${colors.lightBlue};
  border: 1px solid ${colors.grey};
  border-radius: 6px;

  input {
    cursor: pointer;
  }
`;
