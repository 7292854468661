import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { H3, H4 } from '@digitalportal-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import routes from '../../../enums/routes';
import { TStore } from '../../../redux/store';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import {
  Wrapper,
  StyledCard,
  ContentWrapper,
  ListWrapper,
  StyledListItem,
  ListBulletPoint,
  ListBulletPointText,
} from './styles';
import { updateHelperIsProgressShown } from '../../../redux/slices/helpers';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { FooterWithStaticLinks } from '../../templates/Footer/Footer';
import config from '../../../config/config.json';

type InfoDataType = {
  text: string;
  title: string;
  display: boolean;
};

const InfoData: Array<InfoDataType> = [
  {
    title: 'Answer a few questions – it won’t take long',
    text: 'We need to ask these so you can be sure you’re getting cover that matches your needs. We won’t ask for anything tricky. We don’t give personal recommendations or offer advice – we only provide information.',
    display: true,
  },
  {
    title: 'Check you’ve given us accurate information',
    text: 'We ask these questions because the information you give here may affect how much you pay and whether we will pay claims later on. ',
    display: true,
  },
  {
    title: 'Buy now, or come back later',
    text: 'We’ll email you a link to your quote, in case you want time to think about it. Any questions, just let us know.',
    display: String(config.FEATURES.OFFLINE_REDIRECT) !== 'true',
  },
];

export default function BeforeYouStart(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const helpers = useSelector((state: TStore) => state.helpersState.helpers);

  useCustomEventListener('pageNavigationBack', () => {
    if (helpers.switchLapse === 2) {
      history(routes.switchLapse, navigateOptions);
    } else if (helpers.triage === 1) {
      history(routes.triage, navigateOptions);
    }
  });

  useCustomEventListener('pageNavigationNext', () => {
    history(routes.coverStartDate, navigateOptions);
  });

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: false }));
  }, [dispatch]);

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <Wrapper>
          <PageIntroduction
            title="Before you start..."
            titleTestId="title"
            subtitle="Getting a quote from AXA Health"
            subtitleTestId="subtitle"
          />
          <StyledCard data-testid="card">
            <ContentWrapper data-testid="card-content">
              <ListWrapper>
                {InfoData.filter(x => x.display).map(({ title, text }, index) => (
                  <StyledListItem data-testid={`card-${index + 1}`} key={title}>
                    <ListBulletPoint data-testid={`card-bulletpoint-number-${index + 1}`}>
                      <H4 bold>{index + 1}</H4>
                    </ListBulletPoint>
                    <ListBulletPointText
                      data-testid={`card-bulletpoint-content-${index + 1}`}
                      disableTypography
                      primary={
                        <H3
                          sx={{ marginBottom: '8px' }}
                          bold
                          data-testid={`card-bulletpoint-content-heading-${index + 1}`}
                        >
                          {title}
                        </H3>
                      }
                      secondary={<H3 data-testid={`card-bulletpoint-content-text-${index + 1}`}>{text}</H3>}
                    />
                  </StyledListItem>
                ))}
              </ListWrapper>
            </ContentWrapper>
          </StyledCard>
          <PageNavigation />
        </Wrapper>
      </Main>
      <FooterWithStaticLinks />
    </>
  );
}
