import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme, colors, H3, Text } from '@digitalportal-ui/core';
import { Link } from 'react-router-dom';
import Download from '../../../assets/svg/download_icon.svg?react';

export const PersonalDetailsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;

  ${[theme.breakpoints.down('md')]} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${[theme.breakpoints.up('md')]} {
    align-items: center;
  }
`;

export const NameContainer = styled(H3)<{
  $expanded?: boolean;
}>`
  font-family: Source Sans Pro;
  color: ${colors.darkGrey};
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: left;
  word-break: break-word;
  margin-bottom: 0px;

  ${[theme.breakpoints.down('md')]} {
    font-size: 18px;
    line-height 23px;
    padding-left: 48px;
    padding-right: ${({ $expanded }) => ($expanded === undefined ? '' : '30px')};
    min-height: 48px;
  }

  ${[theme.breakpoints.up('md')]} {
    margin-bottom: 4px;
  }

  ${[theme.breakpoints.down('lg')]} {
    display: block;
  }
`;

export const DOBContainer = styled(Box)`
  margin-right: 0.5rem;
  color: ${colors.darkGrey};
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  ${[theme.breakpoints.up('md')]} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const PostCodeContainer = styled(Box)`
  margin-right: 1.5rem;
  color: ${colors.darkGrey};
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  text-align: left;
  ${[theme.breakpoints.between('md', 'xl')]} {
    margin-top: -6px;
  }
  ${[theme.breakpoints.up('lg')]} {
    margin-top: -6px;
    margin-right: 1.5rem;
  }
  ${[theme.breakpoints.up('md')]} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const DobPostcodeWrapper = styled(Box)`
  ${[theme.breakpoints.up('md')]} {
    display: flex;
  }
  ${[theme.breakpoints.between('md', 'lg')]} {
    width: 100%;
  }
`;

export const SeparatorContainer = styled.span`
  content: '&#183;';
  font-size: 34px;
  font-weight: normal;
  position: relative;
  bottom: -6px;
  color: ${colors.darkGrey};
  margin-right: 0.5rem;
  ${[theme.breakpoints.between('xs', 'md')]} {
    display: none;
  }
`;

export const EmailContainer = styled(Box)`
  margin-right: 0.5rem;
  color: ${colors.darkGrey};
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  ${[theme.breakpoints.up('md')]} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const MobileContainer = styled(Box)`
  margin-right: 0.5rem;
  color: ${colors.darkGrey};
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  ${[theme.breakpoints.up('lg')]} {
    margin-left: 0 !important;
  }
  ${[theme.breakpoints.up('md')]} {
    font-size: 16px;
    line-height: 20px;
    margin-top: -6px;
  }
`;

export const DownloadLink = styled(Link)`
  text-align: left;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
  ${theme.breakpoints.up('md')} {
    margin-top: 6px;
  }
`;

export const LinkStyle = styled(Text)`
  color: ${colors.oceanBlue};
  text-decoration: none;
  font-weight: 600;
  display: inline-grid;
  grid-template-columns: 98% 2%;
  justify-items: left;
  align-items: center;
  margin-right: 10px;
  ${theme.breakpoints.up('xs')} {
    font-size: 14px;
    line-height: 17.6px;
  }
  ${theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 20.11px;
  }
`;

export const DownloadIcon = styled(Download)`
  ${theme.breakpoints.up('xs')} {
    height: 17px;
  }
  ${theme.breakpoints.up('md')} {
    height: 20px;
  }
`;
