import { colors, theme } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import styled from 'styled-components';

export const CardContainer = styled(Box)`
  width: 100%;
  padding: 16px;
  text-align: left;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  background-color: ${colors.pacificOpaque};
  border-radius: 6px;
  border: 1px solid ${colors.pacific};

  ${theme.breakpoints.up('md')} {
    padding: 32px;
    gap: 30px;
    flex-direction: row;
  }

  ${theme.breakpoints.up('lg')} {
    flex: 1 0 0;
  }
`;
