import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { TStore } from '../../../redux/store';
import Routes from '../../../enums/routes';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import { MainWrapper, Wrapper, StyledBox } from '../paymentDetails/styles';
import ConfirmAndPaySummary from '../../organisms/ConfirmAndPaySummary/ConfirmAndPaySummary';
import DirectDebitForm from '../../organisms/DirectDebitForm/DirectDebitForm';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';

export default function DirectDebit(): React.JSX.Element {
  const initialValues = useSelector((state: TStore) => state.quoteState.quote);
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const regularPaymentDate = useSelector((state: TStore) => state.policyState.policy.regularPaymentDay);
  const paymentFrequency = useSelector((state: TStore) => state.policyState.policy.paymentFrequency);

  const history = useNavigate();
  const [canContinue, setCanContinue] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (canContinue) {
      history(Routes.directDebitInstruction, navigateOptions);
    }
  }, [canContinue]);

  useCustomEventListener('pageNavigationBack', () => {
    history(Routes.paymentDetails, navigateOptions);
  });

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <PageIntroduction title="Direct Debit details" titleTestId="title" />
        <MainWrapper mb={15}>
          <StyledBox>
            <Wrapper>
              <DirectDebitForm formCompleted={setCanContinue} validating={setLoading} />
            </Wrapper>
            <PageNavigation loading={loading} />
          </StyledBox>
          <ConfirmAndPaySummary
            cardData={initialValues}
            isMonthlyShown={paymentFrequency === 'Monthly'}
            regularPaymentDate={regularPaymentDate}
          />
        </MainWrapper>
      </Main>
    </>
  );
}
