import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const FormHolder = styled(Box)`
  text-align: left;
  width: 100%;

  ${theme.breakpoints.up('md')} {
    width: 540px;
  }
`;
