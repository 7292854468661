import React from 'react';
import { H3, Modal } from '@digitalportal-ui/core';
import RoundelIcon from '../../../../../assets/svg/icon_roundels.svg?react';
import { InfoModalContent } from './styles';
import { InfoModalProps } from '../types';

export const InfoModal = ({ open, modalTitle, text, onClose, onSave }: InfoModalProps) => (
  <Modal variant="info" title={modalTitle} open={open} onClose={onClose} onSave={onSave} dataTestid="info-modal">
    <InfoModalContent>
      <RoundelIcon />
      <H3>{text}</H3>
    </InfoModalContent>
  </Modal>
);
