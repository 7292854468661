import styled from 'styled-components';
import { theme, colors, Text, H2 } from '@digitalportal-ui/core';
import { Box, Link, Typography } from '@mui/material';
import CheckIcon from '../../../assets/svg/check_icon.svg?react';
import CrossIcon from '../../../assets/svg/cross_icon.svg?react';

export const PriceCardStyle = styled(Box)`
  display: flex;
  padding: 34px 32px 34px 32px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${colors.mercury};

  ${theme.breakpoints.down('md')} {
    padding: 16px;
    flex-direction: column;
    gap: 24px;
  }
`;

export const IconHolder = styled(Box)<{ $isSelected?: boolean }>`
  position: relative;
  border-radius: 100%;
  background: ${({ $isSelected }) => ($isSelected ? colors.pacificOpaque : colors.mercury)};
  display: flex;
  min-width: 72px;
  height: 72px;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    min-width: 64px;
    max-width: 64px;
    height: 64px;
  }
`;

export const DescriptionWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    justify-content: center;
  }
`;

export const TitleHolder = styled(Box)`
  text-align: left;
  padding: 9px 0px 0px 26px;
  display: flex;
  gap: 10px;
  flex-direction: column;

  ${theme.breakpoints.down('md')} {
    padding-left: 16px;
  }
`;

export const StyledLink = styled(Link)`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  line-height: 20px;
  color: ${colors.oceanBlueDark};
  margin: 0;
  display: block;
  cursor: pointer;
  pointer-events: auto;

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const StyledLinkMobile = styled(Link)`
  display: none;
  text-decoration: none;
  color: ${colors.oceanBlue};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  ${theme.breakpoints.down('md')} {
    display: flex;
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${colors.grey};
  font-size: 14px;
  font-weight: 600;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
  }
`;

export const EndTextHolder = styled(Box)`
  display: flex;
  align-items: flex-end;
  gap: 3px;

  ${theme.breakpoints.down('md')} {
    padding-left: 16px;
  }
`;

export const CheckIconStyled = styled(CheckIcon)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const CrossIconStyled = styled(CrossIcon)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const Subtitle = styled(Text)`
  ${theme.breakpoints.up('xs')} {
    margin-bottom: 16px;
  }
  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
  }
`;

export const CategoryTitle = styled(H2)`
  ${theme.breakpoints.up('xs')} {
    margin-bottom: 8px;
  }
  ${theme.breakpoints.up('md')} {
    margin-bottom: 16px;
  }
`;

export const ListWithChecksWrapper = styled(Box)`
  margin-bottom: 16px;
  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
  }
`;
