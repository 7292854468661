import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme, Text, H2 } from '@digitalportal-ui/core';

export const CategoryTitle = styled(H2)`
  ${theme.breakpoints.up('xs')} {
    margin-bottom: 16px;
  }
  ${theme.breakpoints.up('md')} {
    margin-bottom: 24px;
  }
`;

export const AccordionWrapper = styled(Box)`
  margin-bottom: 16px;
  ${theme.breakpoints.up('md')} {
    margin-bottom: 24px;
  }
`;

export const CustomText = styled(Text)<{
  $isBulletPoint: boolean;
  $isNewLine: boolean;
}>`
  margin-bottom: ${({ $isNewLine }) => ($isNewLine ? '8px' : '0')};
  margin-left: ${({ $isBulletPoint }) => ($isBulletPoint ? '32px' : '0')};
  display: ${({ $isBulletPoint }) => ($isBulletPoint ? 'list-item' : 'block')};
`;
