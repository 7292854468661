import styled from 'styled-components';
import MuiCheckIcon from '@mui/icons-material/Check';
import MuiCloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import { ListItemIcon, ListItemText, ListItem } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';
import ReactMarkdown from 'react-markdown';

export const CheckIcon = styled(MuiCheckIcon)<{ $color: string }>`
  color: ${({ $color }) => $color || 'black'};
  ${theme.breakpoints.down('md')} {
    width: 18px;
    height: 18px;
  }
`;

export const CloseIcon = styled(MuiCloseIcon)`
  color: ${colors.mediumGrey};
  ${theme.breakpoints.down('md')} {
    width: 18px;
    height: 18px;
  }
`;

export const StyledList = styled(List)`
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const StyledListItemText = styled(ListItemText)`
  margin: 0;

  span {
    font-weight: 400;
    font-size: 14px;
    color: ${colors.darkGrey};
  }

  .MuiListItemText-secondary {
    margin-left: -26px;
    margin-top: 8px;
  }

  ${theme.breakpoints.down('md')} {
    span {
      font-size: 12px;
      line-height: 15px;
    }

    .MuiListItemText-secondary {
      margin-top: 4px;
    }
  }
`;

export const ListItemIconStyled = styled(ListItemIcon)`
  flex-direction: row-reverse;
  align-self: baseline;
  margin-right: 7px;
  min-width: 0px;
`;

export const StyledListItem = styled(ListItem)`
  padding-left: 0;
`;

export const ReactMarkdownStyled = styled(ReactMarkdown)`
  strong {
    font-weight: 600;
  }
`;
