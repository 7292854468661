import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const PageWrapper = styled(Box)`
  margin-bottom: 100px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 180px;
    max-width: 690px;
  }

  ${theme.breakpoints.up('lg')} {
    max-width: 930px;
  }

  ${theme.breakpoints.up('xl')} {
    margin-bottom: 240px;
  }
`;

export const PageIntroductionWrapper = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;

  ${theme.breakpoints.up('md')} {
    width: 570px;
    margin-bottom: 64px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const PageWarningContainer = styled(Box)`
  margin-bottom: 16px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
    width: 690px;
  }

  ${theme.breakpoints.up('lg')} {
    width: 930px;
  }

  ${theme.breakpoints.up('xl')} {
    margin: 0px auto 32px auto;
  }
`;

export const MemberNoticeWarningWrapper = styled(Box)`
  padding: 0 16px 16px;

  ${theme.breakpoints.up('md')} {
    padding: 0 32px 32px;
  }
`;
