import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { Button, H5, Modal, Text } from '@digitalportal-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useCookies } from 'react-cookie';
import {
  updateMember,
  updateLeadMemberSnapshot,
  addMember,
  removeMember,
  initialMemberState,
  updateMemberMajorHealthQuestions,
  updateQuote,
  updateQuoteHospitalNetworkOptions,
  updateQuoteHospitalNetwork,
} from '../../../redux/slices/quote';
import {
  updateModalInfo,
  updateHelperIsProgressShown,
  updateHelperEntitlements,
  updatePastDateWarningModalInfo,
  updateHelperProviders,
} from '../../../redux/slices/helpers';
import routes from '../../../enums/routes';
import { TStore } from '../../../redux/store';
import AddMemberDetailsCard from '../../atoms/AddMemberDetailsCard/AddMemberDetailsCard';
import EditMemberModal from '../../organisms/EditMemberModal/EditMemberModal';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import { BuyMembersWrapper, AddMemberDetailsCardWrapper, FrictionModalContent } from './styles';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { Member } from '../../../redux/slices/quote/quote.types';
import Loader from '../../atoms/Loader/Loader';
import { UpdatePageTitle } from '../../../lib/utils/pageTitleHandler';
import { createLead } from '../../../lib/utils/services/leadService';
import { getHospitals, getProductDefinition } from '../../../lib/utils/services/productService';
import { ProductDefinition, ProductMemberType } from '../../../redux/slices/helpers/helpers.types';
import { checkCoverStartDate } from '../../../lib/utils/checkCoverStartDate';
import { CreateLeadRequest } from '../../../lib/utils/services/types';
import HandleError from '../../../lib/utils/handleError';
import AddMemberCardActions from '../../molecules/CardActionsChildren/AddMemberCardActions';
import { defaultNetworkSelection } from '../../../lib/utils/defaultNetworkSelection';
import { FEATURES } from '../../../config/config.json';
import NetworkCodes from '../../../enums/networks';

const CardContentChildren: React.FC = () => <span />;
export default function AdditionalMembers(): React.JSX.Element {
  const [editMemberModalOpen, setEditMemberModalOpen] = useState(false);
  const [removeMemberModalOpen, setRemoveMemberModalOpen] = useState(false);
  const [frictionModalOpen, setFrictionModalOpen] = useState(false);
  const [isRHLOn, setIsRHLOn] = useState(false);
  const [isOfflineRedirectOn, setIsOfflineRedirectOn] = useState(false);
  const [userEditIndex, setUserIndex] = useState(0);
  const [userAction, setUserAction] = useState('add');
  const [frictionStateEnabled, setFrictionState] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [memberUpdated, setMemberUpdated] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();
  const frictionModalTitle = 'Asking about your health';
  const removeMemberModalTitle = 'Removing a member';

  const coverStartDate = useSelector((state: TStore) => state.quoteState.quote.coverStartDate);
  const members = useSelector((state: TStore) => state.quoteState.quote.members);
  const { hospitalNetwork } = useSelector((state: TStore) => state.quoteState.quote);
  const leadMember = members.find((member) => member.policyHolder) ?? ({} as Member);
  const leadMemberSnapshot = useSelector((state: TStore) => state.quoteState.leadMemberSnapshot);
  const helpers = useSelector((state: TStore) => state.helpersState.helpers);
  const [cookies] = useCookies(['campaign_name', 'source_ref']);
  let defaultMHQs = {};
  helpers.majorHealthQuestions.questions.forEach((question) => {
    defaultMHQs = { ...defaultMHQs, [question.code]: question.answers.find((answer) => answer.isFavourable)?.value };
  });

  useEffect(() => {
    setIsRHLOn(FEATURES.RHL === 'true');
    setIsOfflineRedirectOn(FEATURES.OFFLINE_REDIRECT === 'true');
  }, []);

  useEffect(() => {
    let title = '';

    if (showLoader) {
      title = 'Loading members';
    } else {
      title = 'Your additional members';
    }

    UpdatePageTitle(title);
  }, [showLoader]);

  const unhappyPathOptions: NavigateOptions = {
    state: {
      usedRouter: true,
      title: 'We are unable to progress your quote at this time',
      body: 'Please try again or call us',
      route: routes.additionalMembers,
      fromRoute: routes.additionalMembers,
    },
  };

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: true }));
  }, [dispatch]);

  const updateMHQ = () => {
    const policyHolderIndex = members.findIndex((member) => member.policyHolder);
    if (Object.keys(members[policyHolderIndex].majorHealthQuestions).length === 0) {
      dispatch(
        updateMemberMajorHealthQuestions({
          userEditIndex: policyHolderIndex,
          majorHealthQuestions: defaultMHQs,
        }),
      );
    }
  };

  const isLeadMemberUpdated = () => {
    if (
      leadMemberSnapshot !== undefined &&
      (leadMember?.title !== leadMemberSnapshot.title ||
        leadMember?.firstname !== leadMemberSnapshot.firstname ||
        leadMember?.lastname !== leadMemberSnapshot.lastname ||
        leadMember?.preferredName !== leadMemberSnapshot.preferredName ||
        leadMember?.dob.day !== leadMemberSnapshot.dob.day ||
        leadMember?.dob.month !== leadMemberSnapshot.dob.month ||
        leadMember?.dob.year !== leadMemberSnapshot.dob.year ||
        leadMember?.addressLine1 !== leadMemberSnapshot.addressLine1 ||
        leadMember?.addressLine2 !== leadMemberSnapshot.addressLine2 ||
        leadMember?.city !== leadMemberSnapshot.city ||
        leadMember?.postcode !== leadMemberSnapshot.postcode ||
        leadMember?.email !== leadMemberSnapshot.email ||
        leadMember?.phone !== leadMemberSnapshot.phone)
    ) {
      return true;
    }
    return false;
  };

  const updateProduct = async (productDefResponse: ProductDefinition, responseLeadId?: string) => {
    productDefResponse.members.forEach((productMember: ProductMemberType, index: number) => {
      const member = members[index];
      let leadId = '';

      if (member.policyHolder) {
        leadId = responseLeadId ?? member.leadId;
      }

      dispatch(
        updateMember({
          userEditIndex: index,
          values: {
            leadId,
            excess: member.excess || productMember.excessConfig.defaultValue,
            consultation: member.consultation || productMember.consultationConfig.defaultValue,
            excessConfig: productMember.excessConfig,
            consultationConfig: productMember.consultationConfig,
          },
        }),
      );

      dispatch(
        updateHelperEntitlements({
          entitlements: productDefResponse.entitlements,
          mhQs: productDefResponse.mhQs,
          productRules: productDefResponse.productRules,
        }),
      );
    });
  };

  const handleNavigation = () => {
    if (isOfflineRedirectOn) {
      history(routes.offlineRedirect, helpers.navigateOptions);
    } else {
      history(routes.majorHealthQuestions, helpers.navigateOptions);
    }
  };

  const addLead = async (values: Member) => {
    const addLeadModel = {
      title: values.title,
      firstName: values.firstname,
      lastName: values.lastname,
      preferredName: values.preferredName,
      dateOfBirth: `${values.dob.year}-${values.dob.month}-${values.dob.day}`,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city: values.city,
      postcode: values.postcode,
      phone: values.phone,
      email: values.email,
      marketingRef: cookies.campaign_name,
      marketingSource: cookies.source_ref,
    } as CreateLeadRequest;

    try {
      const coverstartDatecheck = checkCoverStartDate(coverStartDate, dispatch);
      const responses = await Promise.all([
        createLead(addLeadModel),
        getProductDefinition(coverstartDatecheck.coverStartDate, members),
      ]);

      const leadResponse = responses[0];
      const productResponse = responses[1];

      updateProduct(productResponse, leadResponse.id);
      const tagManagerArgs = {
        dataLayer: {
          event: 'lead',
          lead_id: leadResponse.id,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
      if (coverstartDatecheck.updated) {
        dispatch(updatePastDateWarningModalInfo(true));
      }
      dispatch(updateQuote({ opportunityId: '' }));
      dispatch(updateLeadMemberSnapshot({ userEditIndex: 0, values: { leadId: leadResponse.id } }));
      if (!isRHLOn) {
        const defaultNetwork = productResponse.hospitalNetworkConfig.defaultValue;
        const networkOptions = productResponse.hospitalNetworkConfig.options.filter(
          (x) => x.value !== NetworkCodes.foundationOption && x.value !== NetworkCodes.inSpireOption,
        );
        dispatch(updateQuoteHospitalNetwork({ hospitalNetwork: hospitalNetwork || defaultNetwork }));
        dispatch(
          updateQuoteHospitalNetworkOptions({
            hospitalNetworkConfig: {
              defaultValue: defaultNetwork,
              options: networkOptions,
            },
          }),
        );
      } else {
        dispatch(updateQuoteHospitalNetworkOptions({ hospitalNetworkConfig: productResponse.hospitalNetworkConfig }));
      }
      handleNavigation();
    } catch (err) {
      HandleError(err, dispatch);
      history(routes.unhappyPath, unhappyPathOptions);
    }
  };

  const getNetworks = async (postcode: string) => {
    try {
      const response = await getHospitals(postcode);
      dispatch(updateHelperProviders({ providers: response }));
      if (isRHLOn) {
        dispatch(updateQuoteHospitalNetwork({ hospitalNetwork: hospitalNetwork || defaultNetworkSelection(response) }));
      }
    } catch (err) {
      dispatch(updateHelperProviders({ providers: { networks: [], postcodeLat: 0, postcodeLng: 0 } }));
      if (isRHLOn) {
        dispatch(updateQuoteHospitalNetwork({ hospitalNetwork: hospitalNetwork || 'FoundationOption' }));
      }
    }
  };

  useCustomEventListener('pageNavigationNext', async () => {
    if (members.filter((user) => user.frictionEnabled).length) {
      setFrictionModalOpen(true);
      dispatch(updateModalInfo({ isModalOpen: true, modalTitle: frictionModalTitle }));
      return;
    }
    updateMHQ();
    if (!leadMember?.leadId || (leadMember?.leadId && isLeadMemberUpdated())) {
      setShowLoader(true);
      await getNetworks(leadMember.postcode);
      await addLead(leadMember);
      setShowLoader(false);
    } else if (memberUpdated) {
      setShowLoader(true);
      const productResponse = await getProductDefinition(coverStartDate, members);
      updateProduct(productResponse);
      setShowLoader(false);
      handleNavigation();
    } else {
      handleNavigation();
    }
  });

  useCustomEventListener('pageNavigationBack', () => {
    history(routes.contactDetails, helpers.navigateOptions);
  });

  const addRemoveButtonClick = () => {
    setUserAction('add');
    setEditMemberModalOpen(true);
    dispatch(updateModalInfo({ isModalOpen: true, modalTitle: 'Who would you like to add?' }));
  };

  useEffect(() => {
    if (members.length && !!Object.keys(leadMember?.majorHealthQuestions).length) {
      setFrictionState(true);
    }
  }, [members]);

  const submitHandler = (values: Member) => {
    const fullAddress = `${values.addressLine1}, ${values.city}, ${values.postcode}`;

    if (userAction === 'add') {
      const member = values;
      member.id = uuidv4();
      member.majorHealthQuestions = defaultMHQs;
      if (frictionStateEnabled) {
        dispatch(addMember({ ...member, fullAddress, frictionEnabled: true }));
      } else {
        dispatch(addMember({ ...member, fullAddress }));
      }

      const dataLayer = {
        event: 'member_added',
        modal_title: 'add a member',
        member_number: members.length.toString(),
      };
      TagManager.dataLayer({ dataLayer });
    } else {
      dispatch(updateMember({ userEditIndex, values: { ...values, fullAddress } }));
      if (values.policyHolder) {
        members.forEach((user, userEditIndexAddressUpdate) => {
          if (!user.policyHolder && user.addressSameAsPolicyHolder) {
            dispatch(
              updateMember({
                userEditIndex: userEditIndexAddressUpdate,
                values: {
                  fullAddress,
                  addressLine1: values.addressLine1,
                  addressLine2: values.addressLine2,
                  city: values.city,
                  postcode: values.postcode,
                },
              }),
            );
          }
        });
      }
    }
    setMemberUpdated(true);
    setEditMemberModalOpen(false);
    dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
  };

  if (showLoader) {
    return (
      <>
        <Header />
        <Main maxWidth="lg">
          <Loader
            title="Hold tight, let's build your plan..."
            subtitle="Whatever healthy looks like for you, we’re here to help you get there."
          />
        </Main>
      </>
    );
  }

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <BuyMembersWrapper display="flex" flexDirection="column" alignItems="center">
          <PageIntroduction
            title="Who would you like to add?"
            titleTestId="addMemberPlanHeader"
            subtitle="You can add up to eight family members, including yourself. They don’t have to live with you."
            subtitleTestId="addMemberPlanSubHeader"
            includeWrapper={false}
            modalTitle="Who can I add from my family?"
          >
            <Text semibold mb={1}>
              You can normally add:
            </Text>
            <Text display="list-item" ml={4}>
              your partner
            </Text>
            <Text display="list-item" ml={4}>
              your or your partner’s children
            </Text>
            <Text display="list-item" ml={4}>
              your or your partner’s parents
            </Text>
            <Text display="list-item" ml={4}>
              your or your partner’s grandparents
            </Text>
            <Text display="list-item" mb={1} ml={4}>
              your or your partner’s grandchildren.
            </Text>
            <Text mb={1}>
              When we say partner, we mean someone you’re married to, in a civil partnership or living permanently in a
              similar relationship with.
            </Text>
            <Text semibold mb={1}>
              This means we don’t cover:
            </Text>
            <Text display="list-item" ml={4}>
              your siblings
            </Text>
            <Text display="list-item" ml={4}>
              siblings of your parents or grandparents.
            </Text>
          </PageIntroduction>
          {members.length > 0 &&
            members.map((user, index) => (
              <AddMemberDetailsCardWrapper key={`${user.firstname + index}`}>
                <AddMemberDetailsCard
                  key={`${user.firstname + index}`}
                  memberDetailsProps={user}
                  policyHolder={user.policyHolder}
                  data-testid="addMemberPlanCardId"
                  cardContentChildren={<CardContentChildren />}
                  addMemberCardActions={
                    <AddMemberCardActions
                      policyHolder={user.policyHolder}
                      memberIndex={index}
                      setUserIndex={setUserIndex}
                      setUserAction={setUserAction}
                      editMemberModalOpen
                      setEditMemberModalOpen={setEditMemberModalOpen}
                      removeMemberModalOpen
                      setRemoveMemberModalOpen={setRemoveMemberModalOpen}
                      frictionModalOpen
                      removeMemberModalTitle={removeMemberModalTitle}
                    />
                  }
                />
              </AddMemberDetailsCardWrapper>
            ))}
          {members.length < 8 && (
            <Button
              onClick={addRemoveButtonClick}
              tabIndex={removeMemberModalOpen || editMemberModalOpen || frictionModalOpen ? -1 : 0}
              data-testid="addRemoveMemberBtn"
              variant="outlined"
            >
              Add member
            </Button>
          )}

          {editMemberModalOpen && (
            <EditMemberModal
              data-testid="edit-member-modal"
              modalOpen={editMemberModalOpen}
              closeModal={() => {
                setEditMemberModalOpen(false);
                dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
              }}
              handleSubmit={submitHandler}
              initialValues={userAction === 'edit' ? members[userEditIndex] : initialMemberState}
              policyHolderValues={leadMember}
              coverStartDate={coverStartDate}
            />
          )}

          <Modal
            variant="confirmation"
            title={removeMemberModalTitle}
            open={removeMemberModalOpen}
            onSave={() => {
              setRemoveMemberModalOpen(false);
              dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
              const dataLayer = {
                event: 'member_removed',
                modal_title: 'removing a member',
                member_number: userEditIndex.toString(),
              };
              TagManager.dataLayer({ dataLayer });

              dispatch(removeMember(userEditIndex));
            }}
            onClose={() => {
              setRemoveMemberModalOpen(false);
              dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
            }}
            dataTestid="modal-remove-member-confirmation"
          >
            <Text data-testid="modal-remove-member-confirmation-content-text">
              If you remove this member, they won&apos;t have any cover. If you change your mind, you can add them back
              before or after you buy.
            </Text>
          </Modal>

          <Modal
            variant="confirmation"
            title={frictionModalTitle}
            open={frictionModalOpen}
            onSave={async () => {
              setFrictionModalOpen(false);
              dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
              setFrictionState(false);
              members.forEach((user, index) => {
                dispatch(updateMember({ userEditIndex: index, values: { frictionEnabled: false } }));
              });
              setShowLoader(true);
              const productResponse = await getProductDefinition(coverStartDate, members);
              updateProduct(productResponse);
              setShowLoader(false);
              handleNavigation();
            }}
            onClose={() => {
              setFrictionModalOpen(false);
              dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
            }}
            dataTestid="modal-friction-member"
          >
            <FrictionModalContent>
              <Text data-testid="modal-friction-member-content-text">
                Please ensure you have completed these questions accurately so we can update your quote, you’ve added
                the following members to your plan:
              </Text>
              {frictionModalOpen &&
                members
                  .filter((user) => user.frictionEnabled)
                  .map((user) => (
                    <H5 data-testid="member-name" key={`${user.firstname}${user.phone}`} bold>
                      {`${user.firstname} ${user.lastname}`}
                    </H5>
                  ))}
            </FrictionModalContent>
          </Modal>
          <PageNavigation />
        </BuyMembersWrapper>
      </Main>
    </>
  );
}
