import { MouseEventHandler } from 'react';
import { OvalButtonStyled, StyledLoader } from './styles';

export type ButtonProps = {
  disabled?: boolean;
  children: React.ReactNode;
  checked: boolean;
  loading?: boolean;
  clickHandler: MouseEventHandler<HTMLButtonElement> | undefined;
  dataTestId?: string;
};

export default function OvalButton({
  children,
  checked,
  loading,
  clickHandler,
  dataTestId,
}: ButtonProps): React.JSX.Element {
  return (
    <OvalButtonStyled
      variant="outlined"
      onClick={clickHandler}
      disabled={loading}
      $checked={checked}
      data-testid={dataTestId}
    >
      {loading ? <StyledLoader data-testid="circular-loader" $checked={checked} size={16} /> : children}
    </OvalButtonStyled>
  );
}
