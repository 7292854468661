import { RadioBlock } from '@digitalportal-ui/core';
import { RadioBlockProps } from '@digitalportal-ui/core/lib/molecules/RadioBlock/types';
import { useField } from 'formik';

interface FormikRadioBlockProps extends Omit<RadioBlockProps, 'isChecked' | 'setValue'> {
  value: string | boolean | number;
}

export default function FormikRadioBlock(props: FormikRadioBlockProps): React.JSX.Element {
  const { name, value } = props;
  const [field, , helpers] = useField(name);
  const isChecked = field.value === value;

  return (
    <RadioBlock {...props} isChecked={isChecked} setValue={() => helpers.setValue(value)} />
  );
}
