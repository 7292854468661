import { Modal, Text } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import mhqPageContent from '../../../content/mhqPageContent.json';
import { updateModalInfo } from '../../../redux/slices/helpers';

type MhqModalProps = {
  code: string;
  currentMhqModal: {
    open: boolean;
    value: string;
  };
  setCurrentMhqModal: (
    value: React.SetStateAction<{
      open: boolean;
      value: string;
    }>,
  ) => void;
};

export default function MhqModal({ code, currentMhqModal, setCurrentMhqModal }: MhqModalProps) {
  const dispatch = useDispatch();
  return (
    <Modal
      variant="info"
      title={mhqPageContent.find((x) => x.code === code)?.modalTitle ?? ''}
      open={currentMhqModal.open}
      onClose={() => {
        setCurrentMhqModal({ ...currentMhqModal, open: false });
        dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
      }}
      dataTestid={`network-option-modal-${currentMhqModal.value}`}
    >
      <Box>
        {mhqPageContent
          .find((x) => x.code === currentMhqModal.value)
          ?.modalBody.map((text: string, index: number) => (
            <Text
              key={text.concat(index.toString())}
              variant="body1"
              style={{
                marginBottom: text.includes('\n') ? '8px' : '0',
                display: text.includes('\t') ? 'list-item' : '',
                marginLeft: text.includes('\t') ? '32px' : '',
                fontWeight: text.includes('\b') ? 'bold' : 'normal',
              }}
            >
              {text.replace('\b', '')}
            </Text>
          ))}
      </Box>
    </Modal>
  );
}
