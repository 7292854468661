import { theme } from '@digitalportal-ui/core';
import { Paper } from '@mui/material';
import styled from 'styled-components';

export const CardWrapper = styled(Paper)<{ $gutter?: boolean }>`
  text-align: left;
  border-radius: 6px;
  padding: 32px;
  box-shadow: 0px 2px 2px rgba(51, 51, 51, 0.15);
  ${theme.breakpoints.up('xs')} {
    padding: 16px;
    margin-bottom: ${(props) => (props.$gutter ? '16px' : 0)};
  }
  ${theme.breakpoints.up('md')} {
    width: 690px;
    padding: 32px;
    margin-bottom: ${(props) => (props.$gutter ? '32px' : 0)};
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;
