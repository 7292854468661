import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const EveryDayHealthCareWrapper = styled(Box)`
  display: none;

  ${theme.breakpoints.up('lg')} {
    display: flex;
    align-items: center;
  }

  svg {
    max-width: 250px;
    max-height: 250px;
  }
`;

export const ImageWrapper = styled(Box)`
  display: none;

  ${theme.breakpoints.up('lg')} {
    display: flex;
    align-items: center;
    margin-right: 32px;
    margin-top: -64px;
  }

  svg {
    max-height: 150px;
  }
`;
