import { Text, colors } from '@digitalportal-ui/core';
import { MemberNoticeWrapper } from './styles';

const MemberNotice = ({
  dataTestId = '',
  message,
}: {
  dataTestId?: string,
  message: string,
}) => (
  <MemberNoticeWrapper>
    <Text display="inline" color={colors.darkGrey} data-testid={`member-notice-text-${dataTestId}`}>
      {message}
    </Text>
  </MemberNoticeWrapper>
);

export default MemberNotice;
