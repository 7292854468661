import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContactCard, colors } from '@digitalportal-ui/core';
import { updateHelperIsProgressShown } from '../../../redux/slices/helpers';
import { TStore } from '../../../redux/store';
import routes from '../../../enums/routes';
import { Main } from '../../templates/styles';
import Header from '../../templates/Header/Header';
import { Wrapper } from '../triage/styles';
import { LeftIcon, BackButtonStyled, ContactCardWrapper } from './styles';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import RoundedPhoneIcon from '../../../assets/svg/phone.svg?react';
import { makePhoneCall } from '../../../lib/utils/phoneCall';

export default function SwitchHandoff(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();

  const insurerOption = useSelector((state: TStore) => state.helpersState.helpers.insurer);
  const triageOption = useSelector((state: TStore) => state.helpersState.helpers.triage);
  const switchLapseOption = useSelector((state: TStore) => state.helpersState.helpers.switchLapse);

  const { navigateOptions, availability } = useSelector((state: TStore) => state.helpersState.helpers);

  const axaSwitchIntroduction = () => (
    <PageIntroduction
      title="Please call us – we may be able to continue your health insurance"
      subtitle="We may be able to continue your health insurance on the same terms as your previous cover. For example, this may mean that we continue to cover existing conditions that we covered before."
      titleTestId="title"
    />
  );
  function renderSwitch() {
    if (triageOption === 2 && insurerOption === 1 && switchLapseOption === 1) {
      return axaSwitchIntroduction();
    }
    if (triageOption === 2 && insurerOption === 2 && switchLapseOption === 1) {
      return (
        <PageIntroduction
          title="Please call us"
          subtitle="As you’ve had health insurance before, we need to have a quick chat with you so we can build the best plan for you."
          titleTestId="title"
        />
      );
    }
    if (triageOption === 3 && insurerOption === 1 && switchLapseOption === 1) {
      return axaSwitchIntroduction();
    }
    if (triageOption === 3 && insurerOption === 2 && switchLapseOption === 1) {
      return (
        <PageIntroduction
          title="Please call us so we can help you build your plan"
          subtitle="As you’ve had health insurance before, we need to have a quick chat with you so we can build the best plan for you."
          titleTestId="title"
        />
      );
    }
    return null;
  }

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: false }));
  }, [dispatch]);

  return (
    <>
      <Header noPhone />
      <Main>
        <Wrapper>
          {renderSwitch()}
          <ContactCardWrapper>
            <ContactCard
              icon={<RoundedPhoneIcon />}
              title="Call us"
              description="Please contact us on the number below so we can build the best plan for you"
              avatarBgColor={availability.availabilityStatus ? colors.pacificBlue10 : colors.mercury}
              availabilityStatus={availability.workTimeText}
              statusTextColor={availability.workTimeColor}
              buttonText="Call 0800 111 4004"
              isButtonDisabled={!availability.availabilityStatus}
              onButtonClick={() => makePhoneCall('08001114004')}
              testId="triage-contact-card"
              buttonWidthFitContent
            />
          </ContactCardWrapper>

          <BackButtonStyled
            onClick={() => history(routes.switchLapse, navigateOptions)}
            variant="outlined"
            type="button"
            size="large"
            data-testid="footer-back-button"
          >
            <LeftIcon />
            Back
          </BackButtonStyled>
        </Wrapper>
      </Main>
    </>
  );
}
