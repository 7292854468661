import styled from 'styled-components';
import { Typography, Box, Button, Card, List, RadioGroup } from '@mui/material';
import { colors, theme } from '@digitalportal-ui/core';

export const AddressLine = styled(Typography)`
  color: ${colors.grey};
`;

export const Section = styled(Box)<{ $noMargin?: boolean }>`
  margin-bottom: ${({ $noMargin }) => ($noMargin ? '0' : '32px')};
`;
export const SectionWithLogo = styled(Box)`
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`;

export const LinkButton = styled(Button)`
  color: ${colors.oceanBlue};
`;

export const Wrapper = styled(Card)`
  text-align: left;
  border-radius: 10px;
  border: none;
  padding: 16px;
  box-shadow: 0px 2px 4px 0px #0000000f;
  &:first-child {
    margin-bottom: 20px;
  }
  ${theme.breakpoints.up('md')} {
    width: 100%;
    padding: 32px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const PageIntroductionWrapper = styled(Box)`
  margin-bottom: 16px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
  }
`;

export const RadioContainer = styled(RadioGroup)`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  flex-wrap: nowrap;

  ${theme.breakpoints.up('xs')} {
    flex-direction: column;
  }
  ${theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

export const LinkWrapper = styled(Box)`
  width: 100%;
  ${theme.breakpoints.up('xs')} {
    margin-top: 0px;
    margin-bottom: 32px;
  }
  ${theme.breakpoints.up('md')} {
    margin-top: 16px;
    margin-bottom: 64px;
  }
`;

export const StyledList = styled(List)`
  margin-bottom: 32px;
  .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
  }
  > * {
    &:after {
      content: '•';
      top: 0;
      position: absolute;
      margin-left: -12px;
      width: 10px;
      height: 10px;
    }
  }
`;
