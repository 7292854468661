import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme, colors, Text } from '@digitalportal-ui/core';
import QuestionRoundel from '../../../assets/svg/question_roundel.svg?react';

export const Wrapper = styled(Box)`
  width: 1110px;
  border-radius: 6px;
  box-shadow: 0px 2px 2px 0px #33333326;
  ${theme.breakpoints.down('xl')} {
    width: 930px;
  }
  ${theme.breakpoints.down('lg')} {
    width: 690px;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const Heading = styled(Box)`
  display: flex;
  align-items: center;
  padding: 16px;
  background: ${colors.white};
  ${theme.breakpoints.up('md')} {
    padding: 32px;
  }
`;

export const QuestionIcon = styled(QuestionRoundel)`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  ${theme.breakpoints.up('md')} {
    width: 64px;
    height: 64px;
    margin-right: 16px;
  }
`;

export const ExclusionTextListItem = styled(Text)`
  padding-left: 18px;
  &:before {
    content: '•';
    width: 18px;
    height: 18px;
    margin-left: -18px;
    display: inline-flex;
    justify-content: center;
  }
  ${theme.breakpoints.up('md')} {
    padding-left: 20px;
    &:before {
      width: 20px;
      height: 20px;
      margin-left: -20px;
    }
  }
`;

export const StyledText = styled(Text)`
  line-height: inherit;
`;
