import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const MemberWarningWrapper = styled(Box)`
  background: ${colors.shyTomato90};
  padding: 16px;
  border: 1px solid ${colors.shyTomato};
  border-radius: 6px;

  ${theme.breakpoints.up('lg')} {
    padding: 8px 16px;
  }

  ${theme.breakpoints.down('md')} {
    flex-wrap: wrap;
  }
`;

export const TextWrapper = styled(Box)`
  padding: 0 0 16px;

  ${theme.breakpoints.up('md')} {
    padding: 0 16px 0 0;
  }
`;
