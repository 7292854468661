import styled from 'styled-components';
import { Paper, TextField, Popper, Box } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { colors, Text, theme } from '@digitalportal-ui/core';

export const Wrapper = styled(Box)`
  width: 100%;
`;

export const CloseIcon = styled(Close)<{ $visibility: boolean }>`
  visibility: ${(props) => (props.$visibility ? 'visible' : 'hidden')};
  display: ${(props) => (props.$visibility ? 'inline-flex' : 'none')};
  cursor: pointer;
  margin: 0 5px;
`;

export const AddressList = styled(Paper)`
  border-radius: unset;
  border: none;
  width: 100%;
  .MuiAutocomplete-noOptions,
  .MuiAutocomplete-loading {
    color: ${colors.darkGrey};
    font-size: 14px;
    line-height: 17px;
    ${theme.breakpoints.up('md')} {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .MuiAutocomplete-listbox {
    border: none;
    max-height: 180px;
    width: 100%;
  }
`;

export const Option = styled(Box)`
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.lightBlue};
  }
`;

export const AutoCompleteTextField = styled(TextField)`
  z-index: 2;
  background-color: ${colors.white};
  .MuiOutlinedInput-root {
    padding: 4.5px;
    border-radius: 6px;
    line-height: 17px;
    .MuiAutocomplete-input {
      font-size: 14px !important;
      line-height: 17px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.grey};
    }
    ${theme.breakpoints.up('md')} {
      padding: 5.5px;
      .MuiAutocomplete-input {
        font-size: 16px !important;
        line-height: 20px;
      }
    }
  }
`;

export const ResponsivePopper = styled(Popper)<{ $width: string }>`
  position: absolute;
  z-index: 2;
  width: 100%;
`;

export const EnterAddressManuallyLinkWrapper = styled(Box)<{ $showAddressFields: boolean }>`
  margin-bottom: ${(props) => (props.$showAddressFields ? '24px' : '0')};
`;
export const StyledTextAsLink = styled(Text)<{ $disabled?: boolean }>`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  line-height: 20px;
  color: ${(props) => (props.$disabled ? colors.mediumGrey : colors.oceanBlueDark)};
  margin: 24px 0 0;
  display: block;
  cursor: pointer;
  pointer-events: ${(props) => (props.$disabled ? 'none' : 'auto')};

  ${theme.breakpoints.down('md')} {
    margin-top: 16px;
  }
`;
