import styled from 'styled-components';
import { Typography, Box } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const AddMemberCardDetailsCardWrapper = styled(Box)`
  max-width: 1110px;

  ${theme.breakpoints.down('xl')} {
    max-width: 930px;
  }
  ${theme.breakpoints.down('lg')} {
    max-width: 690px;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const ButtonWrapper = styled(Box)`
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const AnswerWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 32px 32px 0px;
  width: 100%;

  h3 {
    margin-bottom: 16px;
  }

  h5 {
    margin-bottom: 32px;
  }

  ${theme.breakpoints.down('md')} {
    padding: 16px 16px 0px;

    h3 {
      margin-bottom: 8px;
    }

    h5 {
      margin-bottom: 16px;
    }
  }
`;

export const AddressContainer = styled(Box)`
  margin-bottom: 16px;

  h5 {
    margin-bottom: 0px;
  }
`;

export const ButtonHolder = styled(Box)`
  border-top: 1px solid ${colors.mercury};
  display: flex;
  justify-content: center;
  padding: 32px;

  ${theme.breakpoints.down('md')} {
    padding: 16px;
  }
`;

export const NotificationWrapper = styled(Box)`
  text-align: left;
  max-width: 100%;
  padding: 16px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colors.oceanBlue};
  border-radius: 6px;
  border: 1px solid ${colors.mercury};
  margin: 0 32px 32px;

  ${theme.breakpoints.down('md')} {
    margin: 0 16px 16px;
  }
`;

export const ChildrenContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const EntitlementHolder = styled(Box)`
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SectionHeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 32px 32px 0px;
  width: 100%;

  h3 {
    margin-bottom: 16px;
  }

  h5 {
    margin-bottom: 16px;
  }

  ${theme.breakpoints.down('md')} {
    padding: 16px 16px 0px;

    h3 {
      margin-bottom: 8px;
    }

    h5 {
      margin-bottom: 16px;
    }
  }
`;

export const StyledPriceTypography = styled(Typography)`
  font-size: 14px;
  color: ${colors.grey};
  font-weight: 600;
  ${theme.breakpoints.down('md')} {
    line-height: 20px;
  }
`;
