import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const Wrapper = styled(Box)`
  width: 100%;
  ${theme.breakpoints.up('md')} {
    width: 570px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 540px;
  }
`;

export const DropdownInputHolder = styled(Box)`
  width: 129px;

  ${theme.breakpoints.up('md')} {
    width: 285px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 305px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 270px;
  }
`;
