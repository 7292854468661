import { Text, H4, Modal } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateModalInfo } from '../../../redux/slices/helpers';
import { BuildingBlocksImage, Container, EntitlementsBlockContainer, YellowBlockImage, BlueBlockImage, RedBlockImage, GreenBlockImage, EntitlementsContainer } from './styles';
import EntitlementBlock from './EntitlementBlock';

export enum EntitlementsInfoModalVariant {
  variantOne = 'Variant1',
  variantTwo = 'Variant2',
  variantThree = 'Variant3',
}

export type EntitlementsInfoModalProps = {
  variant: EntitlementsInfoModalVariant;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};

function EntitlementsInfoModal({ variant, openModal, setOpenModal }: EntitlementsInfoModalProps): React.JSX.Element {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [bodyText, setBodyText] = useState('');

  useEffect(() => {
    switch (variant) {
      case EntitlementsInfoModalVariant.variantTwo:
        setTitle('We’ll take you through a few questions now');
        setBodyText('You will need to choose cover options and other selections for you and each person on your plan, so that we can produce the best quote for you.');
        break;
      case EntitlementsInfoModalVariant.variantThree:
        setTitle('Here’s a reminder of what we’ll be asking you to choose for each person on your plan');
        setBodyText('Let’s get started.');
        break;
      default:
        setTitle('Let’s get started – we’ll be asking you to choose from four options to create the plan that’s right for you');
        setBodyText('Then you’ll be able to choose the same or different options for anyone else on your plan.*');
        break;
    }
  }, [variant]);

  useEffect(() => {
    if (openModal) {
      dispatch(updateModalInfo({ isModalOpen: true, modalTitle: title }));
    }
  }, [openModal, title, dispatch]);

  return (
    <Modal
      variant="info"
      title={title}
      open={openModal}
      showCloseFab
      onClose={() => {
        setOpenModal(!openModal);
        dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
      }}
      dataTestid="entitlements-info-modal"
    >
      <Box>
        <H4 data-testid="info-modal-body-text" marginBottom="24px">{bodyText}</H4>
        <Container>
          <BuildingBlocksImage data-testid="building-blocks-image" />
          <EntitlementsContainer>
            <EntitlementsBlockContainer>
              <EntitlementBlock testId="outpatient-block" child={<YellowBlockImage />} text="Outpatient diagnosis and care" />
              <EntitlementBlock testId="inpatient-block" child={<RedBlockImage />} text="Inpatient and day patient care" />
            </EntitlementsBlockContainer>
            <EntitlementsBlockContainer>
              <EntitlementBlock testId="cancer-block" child={<GreenBlockImage />} text="Cancer care" />
              <EntitlementBlock testId="mental-health-block" child={<BlueBlockImage />} text="Mental health care" />
            </EntitlementsBlockContainer>
          </EntitlementsContainer>
        </Container>
        <Text sx={{ paddingTop: { md: '24px' } }}>*Please note, Mental Health Care can only be selected alongside another option.</Text>
      </Box>
    </Modal>
  );
}

export default EntitlementsInfoModal;
