import { Box, Card, CardContent, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import styled from 'styled-components';
import { theme } from '@digitalportal-ui/core';

export const Wrapper = styled(Box)`
  ${theme.breakpoints.up('md')} {
    width: 570px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const StyledCard = styled(Card)`
  padding: 0;
  border-radius: 6px;
`;

export const ContentWrapper = styled(CardContent)`
  text-align: left;
  ${theme.breakpoints.up('xs')} {
    padding: 0 16px;
    padding-bottom: 0px;
  }
  ${theme.breakpoints.up('md')} {
    padding: 16px 32px 0px;
    padding-bottom: 0px;
  }
  &:last-child {
    padding-bottom: 0px;
  }
`;

export const ListWrapper = styled(List)`
  padding: 0;
`;

export const StyledListItem = styled(ListItem)`
  padding: 0;
  ${theme.breakpoints.up('xs')} {
    flex-direction: column;
    align-items: center;
    margin: 24px 0;
  }
  ${theme.breakpoints.up('md')} {
    align-items: flex-start;
    flex-direction: row;
    margin: 28px 0;
  }
`;

export const ListBulletPoint = styled(ListItemAvatar)`
  display: flex;
  align-items: center;
  background: #00adc6;
  color: white;
  min-width: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  margin-top: 0;
  h4 {
    width: 100%;
  }

  ${theme.breakpoints.up('xs')} {
    margin-right: 0;
  }
  ${theme.breakpoints.up('md')} {
    margin-right: 16px;
  }
`;

export const ListBulletPointText = styled(ListItemText)`
  &:first-child {
    margin: 100px;
  }
  ${theme.breakpoints.up('xs')} {
    text-align: center;
  }
  ${theme.breakpoints.up('md')} {
    text-align: left;
  }
`;
