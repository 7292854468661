import { H3 } from '@digitalportal-ui/core';
import { FlexOption, OptionsContainer } from './styles';

type FlexOptionsProps = {
    options: string[];
}

export default function FlexOptions({ options }: FlexOptionsProps): React.JSX.Element {
    return (
      <OptionsContainer>
        {options.map((option, index) => (
          <FlexOption key={`FO-${index.toString()}`}>
            <H3 data-testid={`flex-option-${index.toString()}`} bold>
              {option}
            </H3>
          </FlexOption>
        ))}
      </OptionsContainer>
    );
  }
