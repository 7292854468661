import { Text, colors } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import CoreCard from '../../../molecules/CoreCard/CoreCard';

type TermsAndConditionsProps = {
  terms: string;
};

export default function TermsAndConditions({ terms }: TermsAndConditionsProps): React.JSX.Element {
  return (
    <Box sx={{ borderRadius: '6px', border: `2px solid ${colors.pacific}` }}>
      <CoreCard
        title="Terms and conditions"
        testId="terms-and-conditions-section"
        backgroundColor={colors.pacificBlue10}
      >
        <Text textAlign="left">{terms}</Text>
      </CoreCard>
    </Box>
  );
}
