import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const PageWrapper = styled(Box)`
  margin-bottom: 100px;

  ${theme.breakpoints.up('md')} {
    width: 690px;
    margin-bottom: 180px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 930px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 1110px;
    margin-bottom: 240px;
  }
`;

export const PageIntroductionWrapper = styled(Box)`
  margin-bottom: 17px;

  ${theme.breakpoints.up('md')} {
    width: 570px;
    margin-bottom: 34px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const AddMemberCardDetailsCardWrapper = styled(Box)`
  width: 100%;
  margin-bottom: 16px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
  }
`;
