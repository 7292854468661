import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { TStore } from '../../../redux/store';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import { PageIntroductionWrapper, DatePickerWrapper } from './styles';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import Routes from '../../../enums/routes';
import DatePickerField from '../../molecules/DatePicker/DatePicker';
import { updateQuoteCoverStartDate } from '../../../redux/slices/quote';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { updateHelperIsProgressShown } from '../../../redux/slices/helpers/helpers.slice';
import { CoverStartDateSchema } from '../../../validationSchema';

const NAME = 'coverStartDate';

export default function CoverStartDate(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const initialQuoteValues = useSelector((state: TStore) => state.quoteState.quote);
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);

  function Submitter() {
    const { values, submitForm, setFieldValue } = useFormikContext<never>();
    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    // Cover start date does not get set in 'values' object, when user deletes all characters in datepicker field. useEffect listens
    // for updates in 'values' object and if cover start date is undefined, then it is set to empty string. This is needed for validation upon clicking Next button.
    useEffect(() => {
      if (values[NAME] === undefined) {
        setFieldValue(NAME, '');
      }
    }, [values, setFieldValue]);

    useCustomEventListener('pageNavigationBack', () => {
      history(Routes.beforeYouStart, navigateOptions);
    });

    return null;
  }

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: true }));
  }, [dispatch]);

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <Formik
          validateOnBlur
          validateOnChange
          initialValues={{ coverStartDate: initialQuoteValues[NAME] }}
          onSubmit={(values) => {
            dispatch(updateQuoteCoverStartDate({ coverStartDate: values[NAME] }));
            history(Routes.yourName, navigateOptions);
          }}
          validationSchema={CoverStartDateSchema}
        >
          <Form>
            <PageIntroductionWrapper>
              <PageIntroduction
                title="When would you like your cover to start?"
                titleTestId="title"
                subtitle="Your start date needs to be within the next 45 days."
                subtitleTestId="subtitle"
              />
            </PageIntroductionWrapper>
            <DatePickerWrapper>
              <DatePickerField name="coverStartDate" testId="coverStartDate" />
              <PageNavigation />
            </DatePickerWrapper>
            <Submitter />
          </Form>
        </Formik>
      </Main>
    </>
  );
}
